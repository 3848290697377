/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import {
  TagButton,
  TagConfirm,
  TagEditField,
  TagForm,
  TagModal,
  TagText
} from "@tag/tag-components-react-v2";
import { TagTextarea } from "@tag/tag-components-react-v3";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { IvehicleData } from "../../core/view-Model/userdetails.model";
import { HttpHeaders } from "../../shared/HttpHeaders";
import "../../assets/css/common.scss";
import LoadingSpinner from "./../../components/LoadingSpinner";

function ManuallyAddVehicle({
  vehicleDetailsData,
  SetopenVehicleDetailScreen,
  makeforExport,
  taxDueDate,
  motExpiryDate,
  lastV5cIssuedDate,
  insStartDate,
  insTerm,
  insExpiryDate,
  SetopenaApprovalScreen,
  SetshowManuallyAddVehicle,
  showManuallyAddVehicle,
  onParentRowClick
}: any) {
  const [showPopup, setshowPopup] = useState(false);
  const [showRejectReasonModel, setshowRejectReasonModel] = useState(false);
  const [rejectVehicleReason, setrejectVehicleReason] = useState("");
  const [showRejectionSaveVehicleButton, setshowRejectionSaveVehicleButton] =
    useState(false);
  const [isapprove, setisapprove] = useState(false);
  const Headers = HttpHeaders();
  const [taxDocumentValue, SettaxDocumentValue] = useState("");
  const [motDocumentValue, SetmotDocumentValue] = useState("");
  const [insuranceDocumentValue, SetinsuranceDocumentValue] = useState("");
  const [showInsuranceImageModel, setshowInsuranceImageModel] = useState(false);
  const [showMOTImageModel, setshowMOTImageModel] = useState(false);
  const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
  const [insuranceImageSrc, setinsuranceImageSrc] = useState('');
  const [motImageSrc, setmotImageSrc] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const [vehicleData, SetvehicleData] = React.useState<IvehicleData>({
    manuaalyaddvehiclereason: "",
    doC_USER_ID: "",
    registertioN_NUMBER: "",
    cO2EMISSIONS: 0,
    enginE_CAPACITY: 0,
    markedforexport: false,
    fueL_TYPE: "",
    moT_STATUS: "",
    colour: "",
    make: "",
    typE_APPROVAL: "",
    yeaR_OF_MANUFACTURE: 0,
    taX_DUE_DATE: new Date(),
    taX_STATUS: "",
    datE_OF_LAST_V5C_ISSUED: new Date(),
    moT_EXPIRY_DATE: new Date(),
    wheeL_PLAN: "",
    montH_OF_REGISTRATION: "",
    revenuE_WEIGHT: 0,
    eurostatus: "",
    lasT_UPDATED_ON: new Date(),
    iscompanY_VEHICLE: false,
    vehicleFrequencyFrom: "",
    vehicleFrequencyType: "",
    isapproved: 1,
    insurancE_EXPIRY_DATE: new Date(),
    insurancE_START_DATE: new Date(),
    insurancE_STATUS: new Date(),
    ismanuallyaddvehicle: true,
    mode: "",
    rejecT_REASON: "",
    insurancedocument: "",
    motdocument: "",
    taxdocument: "",
    countrY_NAME: "",
    insurancE_POLICYNUMBER: "",
    vehicleImage: "",
    performing_userId: "",
  });

  React.useEffect(() => {
    if (
      vehicleDetailsData.isapprovedvalue === "Approved" ||
      vehicleDetailsData.isapprovedvalue === "Reject"
    ) {
      setisapprove(true);
    } else {
      setisapprove(false);
    }

    if (vehicleDetailsData != null && vehicleDetailsData != undefined) {
      if (vehicleDetailsData.taxdocument === null) {
        SettaxDocumentValue("");
      } else {
        SettaxDocumentValue(vehicleDetailsData.taxdocument.doC_D_FILE_NAME);
      }
      if (vehicleDetailsData.motdocument === null) {
        SetmotDocumentValue("");
      } else {
        SetmotDocumentValue(vehicleDetailsData.motdocument.doC_D_FILE_NAME);
      }
      if (vehicleDetailsData.insurancedocument === null) {
        SetinsuranceDocumentValue("");
      } else {
        SetinsuranceDocumentValue(
          vehicleDetailsData.insurancedocument.doC_D_FILE_NAME
        );
      }
    }
  }, [vehicleDetailsData.insurancedocument, vehicleDetailsData.isapprovedvalue, vehicleDetailsData.motdocument, vehicleDetailsData.taxdocument]);
  const onCancelButtonClick = (e: any) => {
    SetshowManuallyAddVehicle(false);
    setshowPopup(false);
  };

  const downloadFile = (e: any) => {
    var fileName = "";
    var fileType = "";
    var fileData = "";

    if (e.target.id === "insuranceDocument" && vehicleDetailsData.insurancedocument !== null) {
      fileName = vehicleDetailsData.insurancedocument.doC_D_FILE_NAME;
      fileType = vehicleDetailsData.insurancedocument.doC_D_FILE_CONTENT_TYPE;
      fileData = vehicleDetailsData.insurancedocument.doC_D_FILE_DATA;

      var fileBinaryString = atob(fileData);
      var dowloadFileBytes = new Uint8Array(fileBinaryString.length);
      for (var i = 0; i < fileBinaryString.length; i++) {
        var ascii = fileBinaryString.charCodeAt(i);
        dowloadFileBytes[i] = ascii;
      }

      var file = new Blob([dowloadFileBytes], {
        type: fileType,
      });

      var insuranceFileObjectURL = URL.createObjectURL(file);

      if (allowedFileTypes.includes(fileType)) {
        setinsuranceImageSrc(insuranceFileObjectURL);
        setshowInsuranceImageModel(true);
      } else {
        const link = document.createElement("a");
        link.href = insuranceFileObjectURL;
        link.download = fileName;
        link.click();
      }
    }

    if (e.target.id === "motDocument" && vehicleDetailsData.motdocument !== null) {
      fileName = vehicleDetailsData.motdocument.doC_D_FILE_NAME;
      fileType = vehicleDetailsData.motdocument.doC_D_FILE_CONTENT_TYPE;
      fileData = vehicleDetailsData.motdocument.doC_D_FILE_DATA;

      var fileBinaryString = atob(fileData);
      var dowloadFileBytes = new Uint8Array(fileBinaryString.length);
      for (var i = 0; i < fileBinaryString.length; i++) {
        var ascii = fileBinaryString.charCodeAt(i);
        dowloadFileBytes[i] = ascii;
      }

      var file = new Blob([dowloadFileBytes], {
        type: fileType,
      });

      var motFileObjectURL = URL.createObjectURL(file);

      if (allowedFileTypes.includes(fileType)) {
        setmotImageSrc(motFileObjectURL);
        setshowMOTImageModel(true);
      } else {
        const link = document.createElement("a");
        link.href = motFileObjectURL;
        link.download = fileName;
        link.click();
      }
    }
  };

  const onApproveButtonClick = (e: any) => {
    setshowPopup(true);
  };

  const onRejectButtonClick = (e: any) => {
    setshowPopup(false);
    setshowRejectReasonModel(true);
  };

  const onConfirmSaveButtonClick = (e: any) => {
    setshowPopup(false);
    SetRejectvehicleData("Approved");
  };

  const onConfirmCancelButtonClick = (e: any) => {
    setshowPopup(false);
    SetshowManuallyAddVehicle(false);
  };

  const onVehicleRejectionValueChange = (e: any) => {
    setrejectVehicleReason(e.detail.value);
    if (e.detail.value !== "") {
      setshowRejectionSaveVehicleButton(true);
    } else {
      setshowRejectionSaveVehicleButton(false);
    }
  };

  const onRejectReasonSaveButtonClick = (e: any) => {
    console.log(rejectVehicleReason);
    if (rejectVehicleReason === "") {
      setshowRejectionSaveVehicleButton(false);
    } else {
      setshowRejectionSaveVehicleButton(true);
      SetRejectvehicleData("Reject");
    }
  };

  function SetRejectvehicleData(mode: any) {
    if (vehicleDetailsData != null && vehicleDetailsData !== undefined) {
      vehicleData.manuaalyaddvehiclereason = vehicleDetailsData.manuaalyaddvehiclereason;
      vehicleData.doC_USER_ID = vehicleDetailsData.doC_USER_ID;
      vehicleData.registertioN_NUMBER = vehicleDetailsData.registertioN_NUMBER;
      vehicleData.cO2EMISSIONS = vehicleDetailsData.cO2EMISSIONS;
      vehicleData.enginE_CAPACITY = vehicleDetailsData.enginE_CAPACITY;
      vehicleData.markedforexport = vehicleDetailsData.markedforexport;
      vehicleData.fueL_TYPE = vehicleDetailsData.fueL_TYPE;
      vehicleData.moT_STATUS = vehicleDetailsData.moT_STATUS;
      vehicleData.colour = vehicleDetailsData.colour;
      vehicleData.make = vehicleDetailsData.make;
      vehicleData.typE_APPROVAL = vehicleDetailsData.typE_APPROVAL;
      vehicleData.yeaR_OF_MANUFACTURE = vehicleDetailsData.yeaR_OF_MANUFACTURE;
      vehicleData.taX_DUE_DATE = vehicleDetailsData.taX_DUE_DATE;
      vehicleData.taX_STATUS = vehicleDetailsData.taX_STATUS;
      vehicleData.datE_OF_LAST_V5C_ISSUED = vehicleDetailsData.datE_OF_LAST_V5C_ISSUED;
      vehicleData.moT_EXPIRY_DATE = vehicleDetailsData.moT_EXPIRY_DATE;
      vehicleData.wheeL_PLAN = vehicleDetailsData.wheeL_PLAN;
      vehicleData.montH_OF_REGISTRATION = vehicleDetailsData.montH_OF_REGISTRATION;
      vehicleData.revenuE_WEIGHT = vehicleDetailsData.revenuE_WEIGHT;
      vehicleData.eurostatus = vehicleDetailsData.eurostatus;
      vehicleData.lasT_UPDATED_ON = vehicleDetailsData.lasT_UPDATED_ON;
      vehicleData.iscompanY_VEHICLE = vehicleDetailsData.iscompanY_VEHICLE;
      vehicleData.vehicleFrequencyFrom = vehicleDetailsData.vehicleFrequencyFrom;
      vehicleData.vehicleFrequencyType = vehicleDetailsData.vehicleFrequencyType;
      vehicleData.isapproved = vehicleDetailsData.isapproved;
      vehicleData.insurancE_EXPIRY_DATE = vehicleDetailsData.insurancE_EXPIRY_DATE;
      vehicleData.insurancE_START_DATE = vehicleDetailsData.insurancE_START_DATE;
      vehicleData.insurancE_STATUS = vehicleDetailsData.insurancE_STATUS;
      vehicleData.ismanuallyaddvehicle = vehicleDetailsData.ismanuallyaddvehicle;
      vehicleData.rejecT_REASON = vehicleDetailsData.rejecT_REASON;
      vehicleData.insurancedocument = vehicleDetailsData.insurancedocument;
      vehicleData.motdocument = vehicleDetailsData.motdocument;
      vehicleData.taxdocument = vehicleDetailsData.taxdocument;
      vehicleData.insurancE_POLICYNUMBER = vehicleDetailsData.insurancE_POLICYNUMBER;

      if (mode === "Approved") {
        vehicleData.mode = "Approved";
      } else {
        vehicleData.mode = "Rejected";
        vehicleData.rejecT_REASON = rejectVehicleReason;
      }
    }
    approveRejectVehicle();
  }

  const approveRejectVehicle = async () => {
    setIsLoading(true);
    var formData = new FormData();
    formData.append("request", JSON.stringify(vehicleData));
    try {
      const response = axios.post(`vehicle/ApprovedRejectManuallyAddVehicle`, formData, Headers)
        .then((response) => {
          if (response !== null && response !== undefined) {
            if (response.data !== null && response.data !== undefined) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                setshowPopup(false);
                SetshowManuallyAddVehicle(false);
                onParentRowClick();
                setIsLoading(false);
              }
              setIsLoading(false);
            }
            setIsLoading(false);
          }
        })
        .catch((ex) => {
          console.log("Getting some API error when call ApprovedRejectManuallyAddVehicle API!");
        });
    } catch (error) {
      console.log("Getting some API error when call ApprovedRejectManuallyAddVehicle API!");
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <TagModal
          visible={showManuallyAddVehicle}
          heading="Vehicle Details"
          width="medium"
          borderAccent="viridiangreen"
          onClosed={() => {
            SetshowManuallyAddVehicle(false);
            setshowPopup(false);
          }}
        >
          <TagForm submitButtonHidden={true}>
            <div className="veh-Content">
              <table className="veh-Form-Table">
                <tr>
                  <td colSpan={2}>
                    <div>
                      <TagText
                        textStyle={{
                          fontSize: "16px",
                          marginBlock: "0",
                          fontWeight: "600",
                          textAlign: "center"
                        }}
                        text="Vehicle"
                      />
                    </div>
                  </td>
                  <td className="w_6"></td>
                  <td colSpan={2}>
                    <div>
                      <TagText
                        textStyle={{
                          fontSize: "16px",
                          marginBlock: "0",
                          fontWeight: "600",
                          textAlign: "center"
                        }}
                        text="Insurance"
                      />
                    </div>
                  </td>
                  <td className="w_6"></td>
                  <td colSpan={2}>
                    <div>
                      <TagText
                        textStyle={{
                          fontSize: "16px",
                          marginBlock: "0",
                          fontWeight: "600",
                          textAlign: "center"
                        }}
                        text="Tax & MOT"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="w_15">
                    <div>
                      <tag-text>Registration Number</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-Vehicle-Number"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.registertioN_NUMBER}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textmotexpirydate">
                        Insurance Term
                      </tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-ins-start-date"
                        id="veh-ins-start-date"
                        editor="Textbox"
                        value={insTerm}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="texttaxduedate">Tax Due Date</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-tax-due-date"
                        id="veh-tax-due-date"
                        editor="Textbox"
                        value={taxDueDate}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="w_15">
                    <div>
                      <tag-text>Registered</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-Registration-Month"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.montH_OF_REGISTRATION}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textmotstatus">
                        Insurance End Date
                      </tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-ins-expiry"
                        id="veh-ins-expiry"
                        editor="Textbox"
                        data-type="string"
                        value={insExpiryDate}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="texttaxstatus">Tax Status</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-Status"
                        id="veh-Status"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.taX_STATUS}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="w_15">
                    <div>
                      <tag-text>Fuel Type</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-Fuel-Type"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.fueL_TYPE}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text>Insurance Status</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-ins-status"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.insurancE_STATUS}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textmotexpirydate">
                        MOT Expiry Date
                      </tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-mot-expiry-date"
                        id="veh-mot-expiry-date"
                        editor="Textbox"
                        value={motExpiryDate}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="w_15">
                    <div>
                      <tag-text>CO2 Emissions</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-emisson"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.cO2EMISSIONS}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textinsurancepolicynumber">
                        Insurance Policy No.
                      </tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-ins-start-date"
                        id="veh-ins-start-date"
                        editor="Textbox"
                        value={vehicleDetailsData.insurancE_POLICYNUMBER}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textmotstatus">MOT Status</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-mot-status"
                        id="veh-mot-Status"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.moT_STATUS}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="w_15">
                    <div>
                      <tag-text>Engine Capacity</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-Engine-Capacity"
                        editor="Textbox"
                        value={vehicleDetailsData.enginE_CAPACITY}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textinsureneceFiles">
                        Insurance Document
                      </tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <a onClick={downloadFile} id="insuranceDocument">
                        {insuranceDocumentValue}
                      </a>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="motdocument">MOT Document</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <a onClick={downloadFile} id="motDocument">
                      {motDocumentValue}
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </TagForm>
          <div className="footer">
            <div className="bLeft">
              <TagButton
                size="large"
                text="Cancel"
                className="cancel"
                onButtonClick={onCancelButtonClick}
              />
            </div>
            {isapprove ? null : (
              <>
                <div className="bRight">
                  <div>
                    <TagButton
                      size="large"
                      text="Reject"
                      className="reject"
                      onButtonClick={onRejectButtonClick}
                    />
                  </div>
                </div>
                <div className="bRight">
                  <div>
                    <TagButton
                      size="large"
                      text="Verify"
                      className="approve"
                      accent="viridiangreen"
                      onButtonClick={onApproveButtonClick}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </TagModal>
      )}
      <div>
        {showPopup ? (
          <TagConfirm
            visible
            width="medium"
            heading="Confirm"
            primaryButton="Yes"
            cancelButton="No"
            text="Are you sure you want to approve vehicle?"
            onPrimaryButtonClick={onConfirmSaveButtonClick}
            onCancelButtonClick={onConfirmCancelButtonClick}
          />
        ) : null}

        {showInsuranceImageModel ? (
          <>
            <div>
              <TagModal
                visible
                heading="Insurance Document"
                borderAccent='viridiangreen'
                width='small'
                containerStyle={{ backgroundColor: "rgb(249,249,249)" }}
                contentStyle={{ backgroundColor: "white", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}
                headingContainerStyle={{ backgroundColor: "rgb(249,249,249)" }}
                onClosed={() => {
                  setshowInsuranceImageModel(false);
                }}>
                <div style={{ textAlign: "center", maxHeight: "500px", overflowY: "scroll" }}>
                  <img id="insuranceDocumentFileImage" src={insuranceImageSrc} />
                </div>
              </TagModal>
            </div>
          </>
        ) : null}

        {showMOTImageModel ? (
          <>
            <div>
              <TagModal
                visible
                heading="MOT Document"
                borderAccent='viridiangreen'
                width='small'
                containerStyle={{ backgroundColor: "rgb(249,249,249)" }}
                contentStyle={{ backgroundColor: "white", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}
                headingContainerStyle={{ backgroundColor: "rgb(249,249,249)" }}
                onClosed={() => {
                  setshowMOTImageModel(false);
                }}>
                <div style={{ textAlign: "center", maxHeight: "500px", overflowY: "scroll" }}>
                  <img id="motDocumentFileImage" src={motImageSrc} />
                </div>
              </TagModal>
            </div>
          </>
        ) : null}
      </div>

      {showRejectReasonModel ? (
        <>
          <div>
            {showRejectionSaveVehicleButton ? (
              <TagModal
                visible
                heading="Are you sure you want to Reject vehicle?"
                borderAccent="viridiangreen"
                width="small"
                primaryButton="Yes"
                primaryButtonAccent="viridiangreen"
                cancelButton="Cancel"
                cancelButtonIconAccent="title"
                onPrimaryButtonClick={onRejectReasonSaveButtonClick}
                onCancelButtonClick={onConfirmCancelButtonClick}
              >
                <TagTextarea
                  textareaId="textAreaId"
                  rows={1}
                  name="exampleName"
                  placeholder="Please enter your reason for rejection"
                  value={rejectVehicleReason}
                  onTextareaValueChange={onVehicleRejectionValueChange}
                  textareaStyle={{ width: "500px", backgroundColor: "white", minHeight: "110px" }}
                />
              </TagModal>
            ) : (
              <TagModal
                visible
                heading="Are you sure you want to reject vehicle?"
                borderAccent="viridiangreen"
                width="small"
                primaryButton=""
                primaryButtonAccent="viridiangreen"
                cancelButton="Cancel"
                cancelButtonIconAccent="title"
                onPrimaryButtonClick={onRejectReasonSaveButtonClick}
                onCancelButtonClick={onConfirmCancelButtonClick}
              >
                <TagTextarea
                  textareaId="textAreaId"
                  name="exampleName"
                  placeholder="Please enter your reason for rejection"
                  value={rejectVehicleReason}
                  onTextareaValueChange={onVehicleRejectionValueChange}
                  textareaStyle={{
                    width: "500px",
                    backgroundColor: "white",
                    color: "black",
                    border: "none",
                    minHeight: "110px"
                  }}
                />
              </TagModal>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
}
export default ManuallyAddVehicle;
