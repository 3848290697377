/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-dupe-else-if */

import { TagButton } from "@tag/tag-components-react-v3";
import { TagEditField, TagForm, TagModal, TagTable } from "@tag/tag-components-react-v2";
import { useIframePidgetSettings } from "@workspace/utils-react";
import axios from "axios";
import React, { useState, useRef } from "react";
// import ManuallyAddLicence from "../components/ManuallyAdd/ManuallyAddLicence";
import ManuallyAddVehicle from "../components/ManuallyAdd/ManuallyAddVehicle";
import { IUsers } from "../core/view-Model/users.model";
import { PidgetSettings } from "../PidgetSettings";
import { HttpHeaders } from "../shared/HttpHeaders";
import LoadingSpinner from "./../components/LoadingSpinner";
import ManuallyAddLicence from "./ManuallyAdd/ManuallyAddLicence";
import { IApprovalFilter } from "../core/view-Model/approval.model";
import ManualLicenceVehicleHistory from "./History/ManualLicenceVehicleHistory";

function Approval({ openaApprovalScreen, SetopenaApprovalScreen }: any) {
  const approvedColor =
    '{"margin":"0 auto", "background-color": "#68B590","color": "white","padding": "3px","border-radius": "7px", "display": "block", "width": "80px","text-align":"center"}';
  const pendingColor =
    '{"margin":"0 auto", "background-color": "#eef0f2","color": "black","padding": "3px","border-radius": "7px", "display": "block", "width": "80px","text-align":"center"}';
  const rejectcolor =
    '{"margin":"0 auto", "background-color": "#e5173f","color": "white","padding": "3px","border-radius": "7px", "display": "block", "width": "80px","text-align":"center"}';
  const [openLicenceScreen, SetopenLicenceScreen] = useState(false);
  const [openLicenceHistoryScreen, SetopenLicenceHistoryScreen] = useState(false);
  const [fromLicenceHistoryScreen, SetFromLicenceHistoryScreen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openVehicleScreen, SetopenVehicleScreen] = useState(false);
  const [licencevehicleData, setLicencevehicleData] = useState([]);
  const pageSize = 10;
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [allVehicleApproved, setallVehicleApproved] = useState<IUsers[]>([]);
  const Headers = HttpHeaders();

  var registrationSearchNumber = "";
  var userId = "";
  var LicenceId = "";
  var VehicleId = "";
  var noHistoryPopup;
  const [taxDueDate, settaxDueDate] = React.useState("");
  const [motExpiryDate, setmotExpiryDate] = React.useState("");
  const [lastV5cIssuedDate, setlastV5cIssuedDate] = React.useState("");
  const [vehicleDetailsData, setvehicleDetailsData] = useState([]);
  const [licenceDetailsData, setlicenceDetailsData] = useState([]);
  const [licenceHistoryDetailsData, setlicenceHistoryDetailsData] = useState([]);
  const [makeforExport, setmakeforExport] = React.useState("");
  const [insStartDate, setinsStartDate] = React.useState("");
  const [insTerm, setinsTerm] = React.useState("");
  const [insExpiryDate, SetinsExpiryDate] = React.useState("");
  const [insPolicyNumber, SetinsPolicyNumber] = React.useState("");
  const [showManuallyAddVehicle, SetshowManuallyAddVehicle] = useState(false);
  const [showManuallyAddLicence, SetshowManuallyAddLicence] = useState(false);
  const [showManualLicenceHistory, SetshowManualLicenceHistory] = useState(false);
  const [statusValue, setStatusValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [datecometoUK, setdatecometoUK] = React.useState("");
  const [licenceno, setlicenceno] = React.useState("");
  const [issuedate, setissuedate] = React.useState("");
  const [expirydate, setexpirydate] = React.useState("");
  const [fileName, setFilename] = React.useState("");
  const [historyPerformingDate, setHistoryPerformingDate] = React.useState("");
  const [historyRejectionReason, setHistoryRejectionReason] = React.useState("");
  const [historyPerformingUsername, setHistoryPerformingUsername] = React.useState("");
  const previousInputValue = useRef("");
  const lazyState = useRef({ LicenceVehicleHistoryPopUp: "" });

  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  const userAvtar = url + "Content/Images/useravtar.png";
  const UpdateAllUsers = () => {
    setallVehicleApproved((x) =>
      [...x].map((i) =>
        i.isapprovedValue === "Verified"
          ? { ...i, highlightText: approvedColor }
          : i.isapprovedValue === "Reject"
            ? { ...i, highlightText: rejectcolor }
            : i.isapprovedValue === "Unverified"
              ? { ...i, highlightText: pendingColor }
              : i
      )
    );
    setIsLoading(false);
  };
  const [filterCount, setfilterCount] = React.useState('0');

  const [approvalFilterData, setApprovalFilterData] =
    React.useState<IApprovalFilter>({
      Name: "",
      VehicleRegistrationNumber: "",
      LicenceNo: "",
      Type: "",
      Status: "",
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    }); // DOC approval filter data

  const [openApprovalFilterScreen, SetOpenApprovalFilterScreen] = useState(false);
  const OnApprovalFilterOpened = (e: any) => { };

  const onRowClick = (e: any) => {
    if (e.detail.row.licenceOrVehicleType === 'Licence') {
      registrationSearchNumber = e.detail.row.licenceOrVehicleNumber;
      userId = e.detail.row.userId;
      showLicenceDetails();
    }
    else {
      registrationSearchNumber = e.detail.row.licenceOrVehicleNumber;
      userId = e.detail.row.userId;
      showvehicleDetails();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  };

  const onHistoryRowClick = (e: any) => {
    if (e.detail.row.licenceOrVehicleType === 'Licence') {
      registrationSearchNumber = e.detail.row.licenceOrVehicleNumber;
      userId = e.detail.row.userId;
      LicenceId = e.detail.row.licenceId;
      showlicnecehistoryDetails();
    }
    else {
      registrationSearchNumber = e.detail.row.licenceOrVehicleNumber;
      userId = e.detail.row.userId;
      VehicleId = e.detail.row.vehicleId;
      showlicnecehistoryDetails();
    }
  };

  React.useEffect(() => {
    LoadAllApproveVehicleList(1);
  }, []);

  const handleApprovalUserPageChangeRequest = (e: any) => {
    LoadAllApproveVehicleList(e.detail.page);
  };

  const showlicnecehistoryDetails = () => {
    setIsLoading(true);
    if (LicenceId != "") {
      axios
        .get("approval/GetAllLicenceHistory?DOC_DVLA_MAH_LICENCE_PRIMARY=" + LicenceId + "&DOC_DVLA_MAH_TYPE= Licence", Headers)
        .then((response) => {
          if (response !== null && response !== undefined) {
            if (response.data !== null && response.data !== undefined) {
              if (response.data.data !== null && response.data.data !== undefined) {
                if (response.data.data.length > 0) {
                  const data = response.data.data;
                  noHistoryPopup = false;
                  previousInputValue.current = 'false';
                  lazyState.current.LicenceVehicleHistoryPopUp = "false";
                  setlicenceHistoryDetailsData([]);
                  setlicenceHistoryDetailsData(data);
                  setHistoryPerformingDate(data.doC_DVLA_MAH_PERFORMING_DATE);
                  setHistoryRejectionReason(data.doC_DVLA_MAH_REJECTION_REASON);
                  setHistoryPerformingUsername(data.doC_DVLA_MAH_PERFORMING_USER_NAME);
                  SetshowManuallyAddLicence(true);
                  SetopenLicenceHistoryScreen(true);
                  SetFromLicenceHistoryScreen(true);
                  SetshowManualLicenceHistory(true);
                  setIsLoading(false);
                }
                else {
                  noHistoryPopup = true;
                  previousInputValue.current = 'true';
                  lazyState.current.LicenceVehicleHistoryPopUp = "true";
                  setlicenceHistoryDetailsData([]);
                  SetshowManuallyAddLicence(true);
                  SetFromLicenceHistoryScreen(true);
                  SetshowManualLicenceHistory(true);
                  setIsLoading(false);
                }
                setIsLoading(false);
              }
              setIsLoading(false);
            }
          }
        }
        )
    }
    else {
      axios
        .get("approval/GetAllLicenceHistory?DOC_DVLA_MAH_VEHICLE_PRIMARY=" + VehicleId + "&DOC_DVLA_MAH_TYPE= Vehicle", Headers)
        .then((response) => {
          if (response !== null && response !== undefined) {
            if (response.data !== null && response.data !== undefined) {
              if (response.data.data !== null && response.data.data !== undefined) {
                if (response.data.data.length > 0) {
                  const data = response.data.data;
                  lazyState.current.LicenceVehicleHistoryPopUp = "false";
                  noHistoryPopup = false;
                  setlicenceHistoryDetailsData([]);
                  setlicenceHistoryDetailsData(data);
                  setHistoryPerformingDate(data.doC_DVLA_MAH_PERFORMING_DATE);
                  setHistoryRejectionReason(data.doC_DVLA_MAH_REJECTION_REASON);
                  setHistoryPerformingUsername(data.doC_DVLA_MAH_PERFORMING_USER_NAME);
                  SetopenLicenceHistoryScreen(true);
                  SetshowManualLicenceHistory(true);
                  SetFromLicenceHistoryScreen(false);
                  setIsLoading(false);
                }
                else {
                  setlicenceHistoryDetailsData([]);
                  noHistoryPopup = true;
                  lazyState.current.LicenceVehicleHistoryPopUp = "true";
                  SetFromLicenceHistoryScreen(false);
                  SetshowManualLicenceHistory(true);
                  setIsLoading(false);
                }
                setIsLoading(false);
              }
              setIsLoading(false);
            }
          }
        }
        )
    }
  };

  const showvehicleDetails = () => {
    setIsLoading(true);
    axios
      .get("vehicle/GetAllVehicle?REGISTERTION_NUMBER=" + registrationSearchNumber + "&DOC_USER_ID=" + userId, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                const data = response.data.data[0];
                setvehicleDetailsData(data);

                // setLicencevehicleData(response.data.data[0]);

                if (response.data.data[0].markedforexport == "false") {
                  setmakeforExport("No");
                } else if (response.data.data[0].markedforexport == null) {
                  setmakeforExport("");
                } else {
                  setmakeforExport("Yes");
                }
                var taxDueDate = new Date(data.taX_DUE_DATE);
                if (data.taX_DUE_DATE != null) {
                  var ddmmyyytaxDueDate =
                    taxDueDate.getDate() +
                    "/" +
                    (taxDueDate.getMonth() + 1) +
                    "/" +
                    taxDueDate.getFullYear();
                  settaxDueDate(ddmmyyytaxDueDate);
                } else {
                  settaxDueDate("");
                }

                var motExpiryDate = new Date(data.moT_EXPIRY_DATE);
                if (data.moT_EXPIRY_DATE != null) {
                  var ddmmyyymotExpiryDate =
                    motExpiryDate.getDate() +
                    "/" +
                    (motExpiryDate.getMonth() + 1) +
                    "/" +
                    motExpiryDate.getFullYear();
                  setmotExpiryDate(ddmmyyymotExpiryDate);
                } else {
                  setmotExpiryDate("  ");
                }

                var insStartDate = new Date(data.insurancE_START_DATE);
                if (data.insurancE_START_DATE != null) {
                  var ddmmyyyinsStartDate =
                    insStartDate.getDate() +
                    "/" +
                    (insStartDate.getMonth() + 1) +
                    "/" +
                    insStartDate.getFullYear();
                  setinsStartDate(ddmmyyyinsStartDate);
                } else {
                  setinsStartDate("  ");
                }

                if (data.insurancE_START_DATE != null && data.insurancE_EXPIRY_DATE != null) {
                  var insuranceStart = new Date(data.insurancE_START_DATE);
                  var insuranceExpiry = new Date(data.insurancE_EXPIRY_DATE);
                  var insuranceDateDifference = (insuranceExpiry.getFullYear() - insuranceStart.getFullYear()) * 12;
                  insuranceDateDifference -= insuranceStart.getMonth();
                  insuranceDateDifference += insuranceExpiry.getMonth();
                  insuranceDateDifference = insuranceDateDifference <= 0 ? 0 : insuranceDateDifference;

                  if (insuranceDateDifference == 1) {
                    setinsTerm("1 month");
                  } else if (insuranceDateDifference == 2) {
                    setinsTerm("2 month");
                  } else if (insuranceDateDifference == 3) {
                    setinsTerm("3 month");
                  } else if (insuranceDateDifference == 6) {
                    setinsTerm("6 month");
                  } else if (insuranceDateDifference == 12) {
                    setinsTerm("Annual");
                  }
                }

                var insExpiryDate = new Date(data.insurancE_EXPIRY_DATE);
                if (data.insurancE_EXPIRY_DATE != null) {
                  var ddmmyyyinsExpiryDate =
                    insExpiryDate.getDate() +
                    "/" +
                    (insExpiryDate.getMonth() + 1) +
                    "/" +
                    insExpiryDate.getFullYear();
                  SetinsExpiryDate(ddmmyyyinsExpiryDate);
                } else {
                  SetinsExpiryDate("  ");
                }

                var v5cissueddate = new Date(data.datE_OF_LAST_V5C_ISSUED);
                if (data.datE_OF_LAST_V5C_ISSUED != null) {
                  var ddmmyyyisueDate =
                    v5cissueddate.getDate() +
                    "/" +
                    (v5cissueddate.getMonth() + 1) +
                    "/" +
                    v5cissueddate.getFullYear();
                  setlastV5cIssuedDate(ddmmyyyisueDate);
                } else {
                  setlastV5cIssuedDate("");
                }
              }
              SetshowManuallyAddVehicle(true);
              SetopenVehicleScreen(true);
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      });
  };

  const showLicenceDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios
        .get("licence/GetAllLicence?LICENCENO=" + registrationSearchNumber + "&DOC_USER_ID=" + userId, Headers)
        .then((response) => {
          if (response !== null && response !== undefined) {
            if (response.data !== null && response.data !== undefined) {
              if (response.data.data !== null && response.data.data !== undefined) {
                if (response.data.data != null) {

                  const data = response.data.data;
                  setlicenceDetailsData(data);
                  setlicenceno(response.data["data"].licenceno);
                  setLicencevehicleData(response.data["data"].licencedocument);

                  if (response.data["data"].licencedocument != null) {
                    setFilename(response.data["data"].licencedocument.doC_D_FILE_NAME);
                  }

                  if (response.data.data.ismanuallyaddlicence == true) {
                    var datecometoUKDt = new Date(response.data.data.datE_WHEN_YOUCOME_UK);
                    var ddmmyyydatecometoUKDate = datecometoUKDt.getDate() + "/" + (datecometoUKDt.getMonth() + 1) + "/" + datecometoUKDt.getFullYear();
                    setdatecometoUK(ddmmyyydatecometoUKDate);
                  }

                  var exdate = new Date(response.data["data"].expirydate);
                  if (response.data["data"].expirydate != null) {
                    var ddmmyyExpiryDate =
                      exdate.getDate() +
                      "/" +
                      (exdate.getMonth() + 1) +
                      "/" +
                      exdate.getFullYear();
                    setexpirydate(ddmmyyExpiryDate);
                  } else {
                    setexpirydate("  ");
                  }

                  var issueDate = new Date(response.data["data"].issuedate);
                  if (response.data["data"].issuedate != null) {
                    var ddmmyyyissueExpiryDate =
                      issueDate.getDate() +
                      "/" +
                      (issueDate.getMonth() + 1) +
                      "/" +
                      issueDate.getFullYear();
                    setissuedate(ddmmyyyissueExpiryDate);
                  } else {
                    setissuedate("  ");
                  }
                  // const data= data["data"];
                }
                SetshowManuallyAddLicence(true);
                SetopenLicenceScreen(true);
                setIsLoading(false);
              }
              setIsLoading(false);
            }
            setIsLoading(false);
          }
        }).catch((ex) => {
          console.log("Getting some API error when call ApprovedRejectManuallyAddVehicle API!");
        });
    } catch (error) {
      console.log("Getting some API error when call ApprovedRejectManuallyAddVehicle API!");
    }
  };

  const ExportAllApproveVehicleList = () => {
    setIsLoading(true);
    approvalFilterData.GetAll = true;
    approvalFilterData.CurrentPageNumber = 1;
    axios.post(`vehicle/GetAllApprovedVehicleListExport/`, approvalFilterData, { responseType: 'blob' })
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            var fileName = "ApproveLicenceVehicle";
            var fileType = response.data.type;
            var file = new Blob([response.data], { type: fileType });
            var approvalURL = URL.createObjectURL(file);
            const link = document.createElement("a");

            link.href = approvalURL;
            link.download = fileName;
            link.click();

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind archived users details!");
      });
  };

  const LoadAllApproveVehicleList = (currentPage: number) => {
    setIsLoading(true);
    setallVehicleApproved([]);
    approvalFilterData.GetAll = false;
    approvalFilterData.CurrentPageNumber = currentPage;
    axios.post(`vehicle/GetAllApprovedVehicleList/`, approvalFilterData, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setallVehicleApproved(response.data.data);
                UpdateUserAvtar();
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                UpdateAllUsers();
                setIsLoading(false);
                SetOpenApprovalFilterScreen(false);
              }
              else {
                setIsLoading(false);
                SetOpenApprovalFilterScreen(false);
              }
            } else {
              setIsLoading(false);
              SetOpenApprovalFilterScreen(false);
            }
          } else {
            setIsLoading(false);
            SetOpenApprovalFilterScreen(false);
          }
        } else {
          setIsLoading(false);
          SetOpenApprovalFilterScreen(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind archived users details!");
      });
  };

  const UpdateUserAvtar = () => {
    setallVehicleApproved((x) =>
      [...x].map((i) =>
        i.avatarURL320 == null
          ? { ...i, avatarURL320: userAvtar }
          : i
      )
    );
  };

  const onParentRowClick = () => {
    LoadAllApproveVehicleList(1);
  };

  const OnApprovalFilterApplyClick = (e: any) => {
    let countOfFilter = 0;

    if (approvalFilterData.Name != "" && approvalFilterData.Name != null && approvalFilterData.Name != undefined) {
      countOfFilter++;
    }

    if (approvalFilterData.VehicleRegistrationNumber != "" && approvalFilterData.VehicleRegistrationNumber != null && approvalFilterData.VehicleRegistrationNumber != undefined) {
      countOfFilter++;
    }

    if (approvalFilterData.LicenceNo != "" && approvalFilterData.LicenceNo != null && approvalFilterData.LicenceNo != undefined) {
      countOfFilter++;
    }

    if (approvalFilterData.Type != "" && approvalFilterData.Type != null && approvalFilterData.Type != undefined) {
      countOfFilter++;
    }

    if (approvalFilterData.Status != "" && approvalFilterData.Status != null && approvalFilterData.Status != undefined) {
      countOfFilter++;
    }

    setfilterCount(String(countOfFilter));
    LoadAllApproveVehicleList(1);
  };

  const OnApprovalFilterClick = (e: any) => {
    SetOpenApprovalFilterScreen(true);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {" "}
          {/*filter_Btn" */}
          <div className="fr pt_5 pb_5 mt_15 mb_15">
            {" "}
            <div>
              <TagButton
                icon='DocExcel'
                iconAccent='keppel'
                squared id="1"
                onButtonClick={ExportAllApproveVehicleList}
                buttonStyle={{ "height": "36px", "width": "36px", "marginRight": "5px" }} />
              <TagButton
                text="Filter"
                size="large"
                icon="Switches"
                onButtonClick={OnApprovalFilterClick}
                minWidth="100px"
                badge={filterCount}
                badgeAccent='access'
              />
            </div>
          </div>
          <div>
            <TagTable
              pagerShowFirstLast
              page={page}
              pageSize={pageSize}
              pageCount={pageCount}
              onPageRequest={handleApprovalUserPageChangeRequest}
              buttonIcon='View'
              buttonRounded
              deleteButton={{
                icon: 'History',
                rounded: true,
                style: { "height": "31px", "width": "31px" }
              }}
              onRowDelete={onHistoryRowClick}
              buttonStyle={{ "height": "30px", "width": "30px" }}
              onRowButtonClick={onRowClick}
              columns={[
                {
                  name: "avatarURL320",
                  caption: "User",
                  dataType: "image",
                  cellInnerStyle: {
                    height: "25px",
                    borderRadius: "12px",
                  },
                },
                { name: "fullName", dataType: 'string', caption: "Name" },
                { name: "licenceNo", dataType: 'string', caption: "Licence No." },
                { name: "vehicleRegistrationNumber", dataType: 'string', caption: "Vehicle No." },
                { name: "licenceOrVehicleType", dataType: 'string', caption: "Type" },
                {
                  name: "isapprovedValue",
                  dataType: 'string',
                  caption: "Status",
                  cellInnerStyleField: "highlightText",
                  headerColumnAlignment: "center",
                },
              ]}
              data={allVehicleApproved}
            />
          </div>
          <div>
            <TagModal
              visible={openApprovalFilterScreen}
              heading="User Filter"
              width="small"
              borderAccent="viridiangreen"
              onOpened={OnApprovalFilterOpened}
              onClosed={() => {
                SetOpenApprovalFilterScreen(false);
              }}
              primaryButton="Apply"
              primaryButtonAccent="viridiangreen"
              onPrimaryButtonClick={
                OnApprovalFilterApplyClick
              }
              cancelButton="Clear"
              cancelButtonIconAccent="title"
              onCancelButtonClick={() => {
                approvalFilterData.Name = "";
                approvalFilterData.VehicleRegistrationNumber = "";
                approvalFilterData.LicenceNo = "";
                approvalFilterData.Type = "";
                approvalFilterData.Status = "";
                setfilterCount('0');
                LoadAllApproveVehicleList(1);
              }}
            >
              <TagForm submitButtonHidden={true}>
                <div className="lic-Content">
                  <table className="lic-Form-Table">
                    <tbody>
                      <tr>
                        <td className="w_22">
                          <div>
                            <tag-text>User Name</tag-text>
                          </div>
                        </td>
                        <td className="w_27">
                          <div>
                            <TagEditField
                              name="usr-name"
                              editor="textbox"
                              data-type="string"
                              value={approvalFilterData.Name}
                              onValueChange={(e) => {
                                approvalFilterData.Name =
                                  e.detail.value;
                              }}
                            ></TagEditField>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="w_22">
                          <div>
                            <tag-text>Driving Licence</tag-text>
                          </div>
                        </td>
                        <td className="w_27">
                          <div>
                            <TagEditField
                              name="un-usr-drivingLicence"
                              editor="textbox"
                              data-type="string"
                              value={approvalFilterData.LicenceNo}
                              onValueChange={(e) => {
                                approvalFilterData.LicenceNo = e.detail.value;
                              }}
                            ></TagEditField>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="w_22">
                          <div>
                            <tag-text>Registration No.</tag-text>
                          </div>
                        </td>
                        <td className="w_27">
                          <div>
                            <TagEditField
                              name="un-usr-registrationNo"
                              editor="textbox"
                              data-type="string"
                              value={approvalFilterData.VehicleRegistrationNumber}
                              onValueChange={(e) => {
                                approvalFilterData.VehicleRegistrationNumber = e.detail.value;
                              }}
                            ></TagEditField>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="w_22">
                          <div>
                            <tag-text>Type</tag-text>
                          </div>
                        </td>
                        <td className="w_27">
                          <div>
                            <TagEditField
                              name="un-usr-Type"
                              editor="dropdown"
                              data-type="string"
                              options={[
                                { label: "", value: "" },
                                { label: "Licence", value: "Licence" },
                                { label: "Vehicle", value: "Vehicle" },
                              ]}
                              value={
                                approvalFilterData.Type
                              }
                              onValueChange={(e) => {
                                approvalFilterData.Type = e.detail.value;
                              }}
                            ></TagEditField>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="w_22">
                          <div>
                            <tag-text>Status</tag-text>
                          </div>
                        </td>
                        <td className="w_27">
                          <div>
                            <TagEditField
                              name="un-usr-Status"
                              editor="dropdown"
                              data-type="string"
                              options={[
                                { label: "", value: "" },
                                { label: "Verified", value: "Verified" },
                                { label: "Unverified", value: "Unverified" },
                                { label: "Reject", value: "Reject" },
                              ]}
                              value={approvalFilterData.Status}
                              onValueChange={(e) => {
                                approvalFilterData.Status = e.detail.value;
                              }}
                            ></TagEditField>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TagForm>
            </TagModal>
          </div>
        </div>
      )}

      {showManuallyAddLicence ? (
        <ManuallyAddLicence
          openLicenceScreen={openLicenceScreen}
          datecometoUK={datecometoUK}
          licenceNo={licenceno}
          issuedate={issuedate}
          expirydate={expirydate}
          fileName={fileName}
          licencevehicleData={licencevehicleData}
          licenceDetailsData={licenceDetailsData}
          SetshowManuallyAddLicence={SetshowManuallyAddLicence}
          SetopenLicenceScreen={SetopenLicenceScreen}
          onParentRowClick={onParentRowClick}
        />
      ) : null}

      {showManuallyAddVehicle ? (
        <ManuallyAddVehicle
          vehicleDetailsData={vehicleDetailsData}
          makeforExport={makeforExport}
          taxDueDate={taxDueDate}
          motExpiryDate={motExpiryDate}
          insStartDate={insStartDate}
          insTerm={insTerm}
          insExpiryDate={insExpiryDate}
          lastV5cIssuedDate={lastV5cIssuedDate}
          showManuallyAddVehicle={showManuallyAddVehicle}
          SetshowManuallyAddVehicle={SetshowManuallyAddVehicle}
          onParentRowClick={onParentRowClick}
          statusValue={statusValue}
        />
      ) : null}

      {showManualLicenceHistory ? (
        <ManualLicenceVehicleHistory
          openLicenceHistoryScreen={openLicenceHistoryScreen}
          showManualLicenceHistory={showManualLicenceHistory}
          licencevehicleData={licencevehicleData}
          licenceHistoryDetailsData={licenceHistoryDetailsData}
          SetshowManualLicenceHistory={SetshowManualLicenceHistory}
          SetopenLicenceHistoryScreen={SetopenLicenceHistoryScreen}
          fromLicenceHistoryScreen={fromLicenceHistoryScreen}
          previousInputValue={previousInputValue.current}
          LicenceVehicleHistoryPopUp={lazyState.current.LicenceVehicleHistoryPopUp}
          noHistoryPopup={noHistoryPopup}
          onParentRowClick={onParentRowClick}
        />
      ) : null}
    </div>
  );
}
export default Approval;
