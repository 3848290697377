import {
  parseDateTime,
  TagButton,
  TagConfirm,
  TagForm,
  TagModal,
} from "@tag/tag-components-react-v2";
import { TagTextarea } from "@tag/tag-components-react-v3";
import React from "react";
import { useState } from "react";
import { ILicenceData } from "../../core/view-Model/userdetails.model";
import axios from "axios";
import { HttpHeaders } from "../../shared/HttpHeaders";
import LoadingSpinner from "./../../components/LoadingSpinner";

function ManuallyAddLicence({
  openLicenceScreen,
  licencevehicleData,
  licenceDetailsData,
  SetopenLicenceScreen,
  datecometoUK,
  licenceNo,
  issuedate,
  expirydate,
  fileName,
  onParentRowClick
}: any) {
  const [showPopup, setshowPopup] = useState(false);
  const [showRejectReasonModel, setshowRejectReasonModel] = useState(false);
  const [showLicenceImageModel, setshowLicenceImageModel] = useState(false);
  const [rejectLicenceeason, setrejectLicenceeason] = useState("");
  const Headers = HttpHeaders();
  const [showRejectionSaveVehicleButton, setshowRejectionSaveVehicleButton] = useState(false);
  const [isApprove, setisApprove] = useState(false);
  const OnOpenedPopup = (e: any) => { setshowPopup(false); };
  const [isLoading, setIsLoading] = React.useState(false);

  const [licenceData, SetLicenceData] = React.useState<ILicenceData>({
    address: "",
    //authority: "",
    categories: "",
    doC_USER_ID: "",
    dob: new Date(),
    expirydate: "",
    issuedate: "",
    lasT_UPDATED_ON: new Date(),
    licenceno: "",
    name: "",
    //placeofbirth: "",
    primary: 0,
    surname: "",
    vehiclE_YOUPROVISONALLY_DRIVE: "",
    assessinG_FITNESS_DRIVE: false,
    roadworthy: false,
    drivingwitH_MEDICAL_CONDITION: false,
    drivingeyE_SIGHT: false,
    bluebadge: false,
    qualifieD_FOR_LORRY_BUS_COACH: false,
    doC_DVLA_L_O_EXPIRYDATE: "",
    doC_DVLA_L_O_NAME: "",
    doC_DVLA_L_O_POINTS: 0,
    doC_DVLA_L_O_STARTDATE: "",
    doC_DVLA_L_O_LEGALLITERAL: "",
    mode: "",
    rejectReason: "",
    licenceStatus: "",
    licenceType: "",
    performing_userId: ""
  });

  const [imageSrc, setImageSrc] = useState('');
  const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];

  React.useEffect(() => {
    if (licenceDetailsData.isapprovedvalue === "Approved" || licenceDetailsData.isapprovedvalue === "Reject") {
      setisApprove(true);
    } else {
      setisApprove(false);
    }
  });

  const onCancelButtonClick = (e: any) => {
    SetopenLicenceScreen(false);
    setshowPopup(false);
  };

  const onApproveButtonClick = (e: any) => {
    setshowPopup(true);
  };

  const onRejectButtonClick = (e: any) => {
    setshowPopup(false);
    setshowRejectReasonModel(true);
  };

  const onConfirmSaveButtonClick = (e: any) => {
    setshowPopup(false);
    SetApproveRejectLicenceData("Approved");
    SetopenLicenceScreen(false);
  };
  const onConfirmCancelButtonClick = (e: any) => {
    setshowPopup(false);
    SetopenLicenceScreen(false);
    setshowRejectReasonModel(false);
  };

  var expiryDateData = parseDateTime(expirydate);
  var issueDateData = parseDateTime(issuedate);

  function SetApproveRejectLicenceData(mode: any) {
    licenceData.address = "";
    //licenceData.authority = "";
    licenceData.categories = "";
    licenceData.doC_USER_ID = licenceDetailsData.doC_USER_ID;
    licenceData.dob = new Date();
    licenceData.expirydate = expiryDateData.getFullYear() + "-" + (expiryDateData.getMonth() + 1) + '-' + expiryDateData.getDate();
    licenceData.issuedate = issueDateData.getFullYear() + "-" + (issueDateData.getMonth() + 1) + '-' + issueDateData.getDate();
    licenceData.lasT_UPDATED_ON = new Date();
    licenceData.licenceno = licenceNo;
    licenceData.name = "";
    //licenceData.placeofbirth = "";
    licenceData.primary = 0;
    licenceData.surname = "";
    licenceData.vehiclE_YOUPROVISONALLY_DRIVE = "";
    licenceData.assessinG_FITNESS_DRIVE = false;
    licenceData.roadworthy = false;
    licenceData.drivingwitH_MEDICAL_CONDITION = false;
    licenceData.drivingeyE_SIGHT = false;
    licenceData.bluebadge = false;
    licenceData.qualifieD_FOR_LORRY_BUS_COACH = false;
    licenceData.doC_DVLA_L_O_EXPIRYDATE = "";
    licenceData.doC_DVLA_L_O_NAME = "";
    licenceData.doC_DVLA_L_O_POINTS = 0;
    licenceData.doC_DVLA_L_O_STARTDATE = "";

    if (mode === "Approved") {
      licenceData.mode = "Approved";
    } else {
      licenceData.mode = "Rejected";
      licenceData.rejectReason = rejectLicenceeason;
    }
    approveRejectLicence();
  }

  function approveRejectLicence(): void {
    setIsLoading(true);
    var formData = new FormData();
    formData.append("request", JSON.stringify(licenceData));
    axios
      .post(
        `licence/ApprovedRejectManuallyAddLicence`,
        formData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              setshowPopup(false);
              SetopenLicenceScreen(false);
              onParentRowClick();
              setIsLoading(false);
            }
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind archived users details!");
      });
  }

  const downloadFile = (e: any) => {
    var fileName = "";
    var fileType = "";
    var fileData = "";

    fileName = licencevehicleData.doC_D_FILE_NAME;
    fileType = licencevehicleData.doC_D_FILE_CONTENT_TYPE;
    fileData = licencevehicleData.doC_D_FILE_DATA;

    var fileBinaryString = atob(fileData);
    var dowloadFileBytes = new Uint8Array(fileBinaryString.length);
    for (var i = 0; i < fileBinaryString.length; i++) {
      var ascii = fileBinaryString.charCodeAt(i);
      dowloadFileBytes[i] = ascii;
    }

    var file = new Blob([dowloadFileBytes], {
      type: fileType,
    });

    if (allowedFileTypes.includes(fileType)) {
      var motFileObjectURL = URL.createObjectURL(file);
      setImageSrc(motFileObjectURL);
      setshowLicenceImageModel(true);
    } else {
      var motFileObjectURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = motFileObjectURL;
      link.download = fileName;
      link.click();
    }
  }


  const onLicenceRejectionValueChange = (e: any) => {
    setrejectLicenceeason(e.detail.value);
    if (e.detail.value !== "") {
      setshowRejectionSaveVehicleButton(true);
    } else {
      setshowRejectionSaveVehicleButton(false);
    }
  };

  const onRejectReasonSaveButtonClick = (e: any) => {
    if (rejectLicenceeason === "") {
      setshowRejectionSaveVehicleButton(false);
    } else {
      setshowRejectionSaveVehicleButton(true);
      SetApproveRejectLicenceData("Reject");
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <TagModal
          visible={openLicenceScreen}
          heading="Licence Details"
          width="small"
          borderAccent="viridiangreen"
          onOpened={OnOpenedPopup}
          onClosed={() => {
            SetopenLicenceScreen(false);
            setshowPopup(false);
          }}
        >
          <TagForm submitButtonHidden={true}>
            <div className="lic-Content">
              <table className="lic-Form-Table">
                <tr>
                  <td className="w_22">
                    <div>
                      <tag-text>Country</tag-text>
                    </div>
                  </td>
                  <td className="w_27">
                    <div>
                      <tag-edit-field
                        name="lic-no"
                        editor="Textbox"
                        data-type="string"
                        value={licenceDetailsData.countrY_NAME}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="w_22">
                    <div>
                      <tag-text>Date when you come to UK</tag-text>
                    </div>
                  </td>
                  <td className="w_27">
                    <div>
                      <tag-edit-field
                        name="lic-IssueDate"
                        editor="Textbox"
                        data-type="date"
                        value={datecometoUK}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="w_22">
                    <div>
                      <tag-text>Licence No.</tag-text>
                    </div>
                  </td>
                  <td className="w_27">
                    <div>
                      <tag-edit-field
                        name="lic-no"
                        editor="Textbox"
                        data-type="string"
                        value={licenceNo}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="w_22">
                    <div>
                      <tag-text>Start Date</tag-text>
                    </div>
                  </td>
                  <td className="w_27">
                    <div>
                      <tag-edit-field
                        name="lic-DOB"
                        editor="Textbox"
                        data-type="date"
                        value={issuedate}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="w_22">
                    <div>
                      <tag-text>Expiry Date</tag-text>
                    </div>
                  </td>
                  <td className="w_27">
                    <div>
                      <tag-edit-field
                        name="lic-DOB"
                        editor="Textbox"
                        data-type="date"
                        value={expirydate}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="w_22">
                    <div>
                      <tag-text>Driving Licence Copy</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <a onClick={downloadFile} id="insuranceDocument">
                        {fileName}
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </TagForm>
          <div className="footer">
            <div className="bLeft">
              <TagButton
                size="large"
                text="Cancel"
                className="cancel"
                onButtonClick={onCancelButtonClick}
              />
            </div>
            {isApprove ? null : (
              <>
                <div className="bRight">
                  <div>
                    <TagButton
                      size="large"
                      text="Reject"
                      className="reject"
                      onButtonClick={onRejectButtonClick}
                    />
                  </div>
                </div>
                <div className="bRight">
                  <div>
                    <TagButton
                      size="large"
                      text="Verify"
                      className="approve"
                      accent="viridiangreen"
                      onButtonClick={onApproveButtonClick}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </TagModal>
      )}
      <div>
        {showPopup ? (
          <TagConfirm
            visible
            width="small"
            heading="Confirm"
            primaryButton="Yes"
            cancelButton="No"
            text="Are you sure you want to approve Licence?"
            onPrimaryButtonClick={onConfirmSaveButtonClick}
            onCancelButtonClick={onConfirmCancelButtonClick}
          />
        ) : null}

        {showRejectReasonModel ? (
          <>
            <div>
              {showRejectionSaveVehicleButton ? (
                <TagModal
                  visible
                  heading="Are you sure you want to Reject Licence?"
                  borderAccent="viridiangreen"
                  width="small"
                  primaryButton="Yes"
                  primaryButtonAccent="viridiangreen"
                  cancelButton="Cancel"
                  cancelButtonIconAccent="title"
                  onPrimaryButtonClick={onRejectReasonSaveButtonClick}
                  onCancelButtonClick={onConfirmCancelButtonClick}
                >
                  <TagTextarea
                    textareaId="textAreaId"
                    rows={1}
                    name="exampleName"
                    placeholder="Please enter your reason for rejection"
                    value={rejectLicenceeason}
                    onTextareaValueChange={onLicenceRejectionValueChange}
                    textareaStyle={{ width: "500px", backgroundColor: "white", minHeight: "110px" }}
                  />
                </TagModal>
              ) : (
                <TagModal
                  visible
                  heading="Are you sure you want to Reject Licence?"
                  borderAccent="viridiangreen"
                  width="small"
                  primaryButton=""
                  primaryButtonAccent="viridiangreen"
                  cancelButton="Cancel"
                  cancelButtonIconAccent="title"
                  onPrimaryButtonClick={onRejectReasonSaveButtonClick}
                  onCancelButtonClick={onConfirmCancelButtonClick}
                >
                  <TagTextarea
                    textareaId="textAreaId"
                    name="exampleName"
                    placeholder="Please enter your reason for rejection"
                    value={rejectLicenceeason}
                    onTextareaValueChange={onLicenceRejectionValueChange}
                    textareaStyle={{
                      width: "500px",
                      backgroundColor: "white",
                      color: "black",
                      border: "none",
                      minHeight: "110px"
                    }}
                  />
                </TagModal>
              )}
            </div>
          </>
        ) : null}

        {showLicenceImageModel ? (
          <>
            <div>
              <TagModal
                visible
                heading="Licence Document"
                borderAccent='viridiangreen'
                width='small'
                containerStyle={{ backgroundColor: "rgb(249,249,249)" }}
                contentStyle={{ backgroundColor: "white", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}
                headingContainerStyle={{ backgroundColor: "rgb(249,249,249)" }}
                onClosed={() => {
                  setshowLicenceImageModel(false);
                }}>
                <div style={{ textAlign: "center", maxHeight: "500px", overflowY: "scroll" }}>
                  <img id="licenceDocumentFileImage" src={imageSrc} />
                </div>
              </TagModal>
            </div>
          </>
        ) : null
        }
      </div>
    </div>
  );
}
export default ManuallyAddLicence;