/* eslint-disable no-extend-native */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import axios from "axios";
import React, { useRef, useState } from "react";
import { HttpHeaders } from "../../shared/HttpHeaders";
import "../../assets/css/userDetail.scss";
import User from "../User";
import Offences from "../../reusable-Components/Offences";
import {
  TagCard,
  TagEditField,
  TagField,
  TagIcon,
  TagText,
  TagTooltip,
} from "@tag/tag-components-react-v2";
import { Col, Row } from "react-bootstrap";
import {
  TagAvatar,
  TagButton,
  TagH5,
  TagIconSvg,
  TagSwitch,
} from "@tag/tag-components-react-v3";
import LicenceDetails from "./Licence/LicenceDetails";
import VehiclesDriveorNot from "../../reusable-Components/VehiclesDriveorNot";
import {
  ILicenceData,
  IvehicleData,
  ILicenceAdditionalDetails,
} from "../../core/view-Model/userdetails.model";
import { IVehicleDriveorNotData } from "../../core/view-Model/vehicledrive.model";
import VehicleDetails from "./vehicle/VehicleDetails";
import Vehicles from "../../reusable-Components/Vehicles";
import { IUsersDetails } from "../../core/view-Model/users.model";
import { useIframePidgetSettings } from "@workspace/utils-react/dist/iframePidget/useIframePidgetSettings";
import { PidgetSettings } from "../../PidgetSettings";
import { NotifyPublisher } from "../Notify/NotifyPublisher";
import LoadingSpinner from "./../../components/LoadingSpinner";

function UserDetails(props: any) {
  const [licenceData, setlicenceData] = React.useState<ILicenceData>();
  const [vehicleData, setvehicleData] = React.useState<IvehicleData>();
  const [userVehicleData, setUservehicleData] = React.useState<IvehicleData[]>([]);
  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  const [licenceAdditionalDetails, SetlicenceAdditionalDetails] =
    React.useState<ILicenceAdditionalDetails>({
      DOC_DVLA_L_LICENCENO: "",
      DOC_DVLA_L_PRIMARY: 0,
      DOC_DVLA_L_DOC_USER_ID: "",
      DOC_DVLA_L_SURNAME: "",
      DOC_DVLA_L_NAME: "",
      DOC_DVLA_L_CATEGORIES: "",
      DOC_DVLA_L_ADDRESS: "",
      DOC_DVLA_L_DOB: new Date(),
      DOC_DVLA_L_ISSUEDATE: "",
      DOC_DVLA_L_EXPIRYDATE: "",
      DOC_DVLA_L_ASSESSING_FITNESS_DRIVE: true,
      DOC_DVLA_L_ROADWORTHY: true,
      DOC_DVLA_L_DRIVINGWITH_MEDICAL_CONDITION: false,
      DOC_DVLA_L_DRIVINGEYE_SIGHT: true,
      DOC_DVLA_L_BLUEBADGE: false,
      DOC_DVLA_L_QUALIFIED_FOR_LORRY_BUS_COACH: false,
      DOC_DVLA_L_VEHICLES_PROVISONALLY_DRIVE: "",
      ISMANUALLYADDLICENCE: 0,
      DOC_DVLA_L_STATUS: "",
      DOC_DVLA_L_TYPE: ""
    });
  const [checkedData, UserCheckedData] = React.useState<IUsersDetails>({
    id: 1,
    licenceId: 1,
    vehicleId: 1,
    userId: "",
    organizationId: "",
    fullName: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    jobTitle: "",
    office: "",
    department: "",
    avatarURL320: "",
    avatarURL160: "",
    avatarURL80: "",
    licenceNo: "",
    vehicleRegistrationNumber: "",
    licenceExpiryDate: "",
    vehicleTaxDueDate: "",
    vehicleMotExpiryDate: "",
    status: "",
    isapprovedValue: "",
    allowAutoCheck: false,
  });
  const [userCheckedDatapass, setuserCheckedDatapass] = React.useState<
    IUsersDetails[]
  >([]);
  const [categoryData, setcategoryData] = React.useState<string[]>([]);
  const [showcategoryData, setShowcategoryData] = React.useState(false);
  const [showcategorydetail, setShowCategoryDetail] = React.useState(false);
  const [showoffencedetail, setShowOffenceDetail] = React.useState(false);
  const [fileName, setFilename] = React.useState("");

  const [vehicleProvisonallyDrive, setvehicleProvisonallyDrive] =
    React.useState<string[]>([]);
  // const [offences, setoffences] =
  // React.useState<string[]>([]);
  const [offences, setoffences] = React.useState<ILicenceData[]>([]);
  const [showoffences, setShowoffences] = React.useState(false);
  const [vehicleDetailsData, setvehicleDetailsData] = useState([]);
  const Headers = HttpHeaders();
  const [vehicleDriveOrnotData, setvehicleDriveOrnotData] =
    React.useState<IVehicleDriveorNotData>({
      icon: "Truck",
      StartDate: "01/01/2021",
      EndDate: "01/01/2021",
      categoryType: "a",
    });
  const [licencedob, setlicencedob] = React.useState("");
  const [licencecurrentstatus, setlicencecurrentstatus] = React.useState("");
  const [licencestatusstyle, setlicencestatusstyle] = React.useState("");
  const [issuedate, setissuedate] = React.useState("");
  const [datecometoUK, setdatecometoUK] = React.useState("");
  const [expirydate, setexpirydate] = React.useState("");
  const [lastupdateon, setlastupdateon] = React.useState("");
  const [makeforExport, setmakeforExport] = React.useState("");
  const [taxDueDate, settaxDueDate] = React.useState("");
  const [motExpiryDate, setmotExpiryDate] = React.useState("");
  const [lastV5cIssuedDate, setlastV5cIssuedDate] = React.useState("");
  const [displayValidLicence, SetdisplayValidLicence] = React.useState(false);
  const [displayInValidLicence, SetdisplayInValidLicence] = React.useState(false);
  const [openLicenceScreen, SetopenLicenceScreen] = useState(false);
  const [licencevehicleData, setLicencevehicleData] = useState([]);
  const [refreshbuttonDisbled, setrefreshButtonDisbled] = useState(false);
  const [showrefreshbutton, setshowrefreshbutton] = useState(false);
  const [editButtonsShow, setEditButtonShow] = useState(true);
  const [userImage, setuserImage] = useState("");
  const [vehicleDriveOrnotIcon, SetvehicleDriveOrnotIcon] = useState();
  const [vehicleDriveOrnotCategory, SetvehicleDriveOrnotCategory] = useState();
  const [vehicleDriveOrnotStartDate, SetvehicleDriveOrnotStartDate] = useState();
  const [vehicleDriveOrnotEndDate, SetvehicleDriveOrnotEndDate] = useState();
  const [openVehicleDetailScreen, SetopenVehicleDetailScreen] = useState(false);
  const [disableSwitch, SetdisableSwitch] = useState(true);
  const [roadWorthy, SetRoadWorthyValue] = useState(true);
  const [fitnessToDrive, SetFitnessToDrive] = useState(true);
  const [drivingWithMedical, SetdrivingWithMedical] = useState(true);
  const [eyeSight, SeteyeSightValue] = useState(true);
  const [blueBadge, SetblueBadgeValue] = useState(true);
  const [lorryBusandCoach, SetLorrybusandCoach] = useState(true);
  const [backToUserScreen, SetbackToUserScreen] = useState(false);
  const [categorys, Setcategorys] = useState(false);
  const [openVehicleScreen, setopenVehicleScreen] = useState(false);
  const [vehicleConfigrationDurationType, setvehicleConfigrationDurationType] = useState();
  const [vehicleConfigrationFreqFrom, setvehicleConfigrationFreqFrom] = useState(0);
  const [licenceConfigrationDurationType, setlicenceConfigrationDurationType] = useState();
  const [licenceConfigrationFreqFrom, setlicenceConfigrationFreqFrom] = useState(0);
  const [LicenceDetailsLastUpdate, setLicenceDetailsLastUpdate] = useState("");
  const [LicenceDetailsLastCheckDate, setLicenceDetailsLastCheckDate] = useState("");
  const [diffrenceNextCheck, setdiffrenceNextCheck] = useState("");
  const [diffrenceNextCheckPhotocard, setdiffrenceNextCheckPhotocard] = useState("");
  const [showValidLicenceFont, setshowValidLicenceFont] = useState(true);
  const [showValidPhotoCardFont, setshowValidPhotoCardFont] = useState(true);
  const [archiveButtonVisible, setArchiveButtonVisible] = React.useState(true);
  const [disabledSave, setDisabledSave] = React.useState(false);
  const [disabledStartAutoCheck, setDisabledStartAutoCheck] = React.useState(false);
  const [disabledStopAutoCheck, setDisabledStopAutoCheck] = React.useState(false);
  const [totalOffences, settotalOffence] = useState(0);
  const [totalOffencePoints, settotalOffencePoints] = useState(0);
  const [showNoOffences, setNoOffences] = useState(false);
  const [showHighOffences, setHighOffences] = useState(false);
  const [showLowOffences, setLowOffences] = useState(false);
  const [showlicenceStatusDaysExpiry, setlicenceStatusDaysExpiry] = useState(false);
  const [licencestatus, setlicencestatus] = React.useState("");
  const [ismanuallyaddlicence, setismanuallyaddlicence] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isManualLicence, setIsManualLicence] = React.useState(false);
  var licenceConfigrationFreqFromvalue = 0;
  const icontextstyle = {
    "font-size": "17px",
    "margin-block": "0",
    "padding-left": "10px",
    "padding-top": "4px",
  };

  const validinvalidiconstyle = '{ "height": "20px", "width": "20px"}';
  const size = 90;
  const containerstyle = '{"padding": "5px 10px"}';
  const labelstyle = '{"font-size": "12px", "width": "95px"}';
  const valuestyle = '{"font-size": "12px"}';
  var unverified_Style = '{"font-size": "12px","color" :"#E4A920 "}';
  var verified_Style = '{"font-size": "12px","color" :"#5FA718"}';
  var rejected_Style = '{"font-size": "12px","color" :"#E42A30"}';
  const [flagData, setFlagData] = useState('Flag-of-United-Kingdom');
  const [flagName, setFlagName] = useState('United Kingdom');
  const onLicenceNoClick = (e: any) => {
    SetopenLicenceScreen(true);
  };
  const onRefreshButtonClick = (e: any) => {
    refreshLicenceDetails();
  };
  const onAdditionalDetailEditClick = (e: any) => {
    //SetdisableSwitch(false);
    setEditButtonShow(false);
  };
  const onCancelButtonClick = (e: any) => {
    if (licenceData != null && licenceData !== undefined) {
      SetFitnessToDrive(licenceData.assessinG_FITNESS_DRIVE);
      SetRoadWorthyValue(licenceData.roadworthy);
      SetdrivingWithMedical(licenceData.drivingwitH_MEDICAL_CONDITION);
      SeteyeSightValue(licenceData.drivingeyE_SIGHT);
      SetblueBadgeValue(licenceData.bluebadge);
      SetLorrybusandCoach(licenceData.qualifieD_FOR_LORRY_BUS_COACH);
      bindLicenceCard();
    }
    SetdisableSwitch(true);
    setEditButtonShow(true);
  };
  // const onAdditionalDetailSaveClick = (e: any) => {
  //   setAdditionalModelData();
  // };
  const onFitnessValueChange = (e: any) => {
    SetFitnessToDrive(e.detail.checked);
  };
  const onRoadworthyValueChange = (e: any) => {
    SetRoadWorthyValue(e.detail.checked);
  };
  const onDrivingwithMedicalValueChange = (e: any) => {
    SetdrivingWithMedical(e.detail.checked);
  };
  const onDrivingEyeSightValueChange = (e: any) => {
    SeteyeSightValue(e.detail.checked);
  };
  const onBlueBadgeValueChange = (e: any) => {
    SetblueBadgeValue(e.detail.checked);
  };
  const onLorryBusandCoachValueChange = (e: any) => {
    SetLorrybusandCoach(e.detail.checked);
  };

  function onBackClick(event: any) {
    props.onParentRowClick(false);
  }

  function onStopRecheckButtonClick(event: any) {
    checkedData.userId = props.openuserScreen.detail.row.userId;
    SetStopAutocheckData();
  }

  function onStartRecheckButtonClick(event: any) {
    checkedData.userId = props.openuserScreen.detail.row.userId;
    SetStartAutocheckData();
  }

  const SetStopAutocheckData = () => {
    setIsLoading(true);
    userCheckedDatapass.push(checkedData);
    axios
      .post(
        `User/SetStopAutocheckData`,
        userCheckedDatapass,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              NotifyPublisher.message(('AutoCheck successfully saved'), false, 'successfullyAutoCheckAdd', 'information', 'information', '', '');
              setDisabledStartAutoCheck(false);
              setDisabledStopAutoCheck(true);
              setIsLoading(false);
            }
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      });
  };

  const SetStartAutocheckData = () => {
    setIsLoading(true);
    userCheckedDatapass.push(checkedData);
    axios
      .post(
        `User/SetStartAutocheckData`,
        userCheckedDatapass,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              NotifyPublisher.message(('AutoCheck successfully saved'), false, 'successfullyAutoCheckAdd', 'information', 'information', '', '');
              setDisabledStopAutoCheck(false);
              setDisabledStartAutoCheck(true);
              setIsLoading(false);
            }
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      });
  };

  // const setAdditionalModelData = () => {
  //   if (licenceData != null && licenceData !== undefined) {
  //     licenceAdditionalDetails.DOC_DVLA_L_LICENCENO = licenceData.licenceno;
  //     licenceAdditionalDetails.DOC_DVLA_L_PRIMARY = licenceData.primary;
  //     licenceAdditionalDetails.DOC_DVLA_L_DOB = licenceData.dob;
  //     licenceAdditionalDetails.DOC_DVLA_L_DOC_USER_ID = licenceData.doC_USER_ID;
  //     licenceAdditionalDetails.DOC_DVLA_L_ISSUEDATE = licenceData.issuedate;
  //     licenceAdditionalDetails.DOC_DVLA_L_EXPIRYDATE = licenceData.expirydate;
  //     licenceAdditionalDetails.DOC_DVLA_L_SURNAME = licenceData.surname;
  //     licenceAdditionalDetails.DOC_DVLA_L_NAME = licenceData.name;
  //     //licenceAdditionalDetails.DOC_DVLA_L_PLACEOFBIRTH = licenceData.placeofbirth;
  //     //licenceAdditionalDetails.DOC_DVLA_L_AUTHORITY = licenceData.authority;
  //     licenceAdditionalDetails.DOC_DVLA_L_CATEGORIES = licenceData.categories;
  //     licenceAdditionalDetails.DOC_DVLA_L_ADDRESS = licenceData.address;
  //     licenceAdditionalDetails.DOC_DVLA_L_ASSESSING_FITNESS_DRIVE = fitnessToDrive;
  //     licenceAdditionalDetails.DOC_DVLA_L_ROADWORTHY = roadWorthy;
  //     licenceAdditionalDetails.DOC_DVLA_L_DRIVINGWITH_MEDICAL_CONDITION =
  //       drivingWithMedical;
  //     licenceAdditionalDetails.DOC_DVLA_L_DRIVINGEYE_SIGHT = eyeSight;
  //     licenceAdditionalDetails.DOC_DVLA_L_BLUEBADGE = blueBadge;
  //     licenceAdditionalDetails.DOC_DVLA_L_QUALIFIED_FOR_LORRY_BUS_COACH =
  //       lorryBusandCoach;
  //     licenceAdditionalDetails.DOC_DVLA_L_VEHICLES_PROVISONALLY_DRIVE =
  //       licenceData.vehiclE_YOUPROVISONALLY_DRIVE;
  //     licenceAdditionalDetails.DOC_DVLA_L_TYPE = licenceData.licenceType;
  //     licenceAdditionalDetails.DOC_DVLA_L_STATUS = licenceData.licenceStatus;
  //     UpdatelicenceAdditionoalDetails();
  //   }
  // };

  const checkuserFrequency = (lastupdateon: Date, mode: any) => {
    var hours = 0;
    if (mode === "Licence") {
      hours = licenceConfigrationFreqFromvalue;
    } else {
      hours = vehicleConfigrationFreqFrom;
    }
    var today = new Date();
    var currentDateTimestamp = today.getTime();
    var lastUpdatedDateTimestamp = lastupdateon.getTime();

    if (
      Math.abs(currentDateTimestamp - lastUpdatedDateTimestamp) >
      60 * 60 * hours * 1000
    ) {
      //Within 24 hours
      return false;
    } else {
      return true;
    }
  };

  React.useEffect(() => {
    if (true) {
      if (props.openuserScreen.detail.row.avatarURL320 != null) {
        setuserImage(props.openuserScreen.detail.row.avatarURL320);
      } else {
        setuserImage(
          "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&h=350"
        );
      }

      if (props.openuserScreen.detail.row.isArchive) {
        setArchiveButtonVisible(false);
      } else {
        setArchiveButtonVisible(true);
      }
      setDisabledSave(props.disabledSave);

      if (props.openuserScreen.detail.row.allowAutoCheck == true) {
        setDisabledStartAutoCheck(true);
        setDisabledStopAutoCheck(false);
      } else {
        setDisabledStartAutoCheck(false);
        setDisabledStopAutoCheck(true);
      }

      // if(props.openuserScreen.detail.row.allowAutoCheck == true) {
      //   setDisabledStartAutoCheck(true);
      // } else {
      //   setDisabledStartAutoCheck(false);
      // }

      // if(props.openuserScreen.detail.row.allowAutoCheck == false) {
      //   setDisabledStopAutoCheck(false);
      // } else {
      //   setDisabledStopAutoCheck(true);
      // }

      //setDisabledStartAutoCheck(props.disabledStartAutoCheck);
      //setDisabledStopAutoCheck(props.disabledStopAutoCheck);

      bindLicenceCard();
    }
  }, []);

  const bindLicenceCard = () => {
    setIsLoading(true);
    axios
      .get(
        "licence/GetAllLicence?DOC_USER_ID=" +
        props.openuserScreen.detail.row.userId,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data != null) {
                const data = response.data.data;
                const flag = 'Flag-of-United-Kingdom';
                const flag_Name = 'United Kingdom';
                // const value = reference.current;
                // reference.current = data;
                setlicenceData(response.data.data);
                setLicencevehicleData(response.data.data);
                Setcategorys(response.data.data.categories);

                if (response.data.data.ismanuallyaddlicence === true) {
                  setFlagData(response.data.data.licenceImage.countrY_IMAGE_CODE);
                  setFlagName(response.data.data.licenceImage.countrY_NAME);
                }

                if (response.data["data"].licencedocument != null) {
                  setFilename(response.data["data"].licencedocument.doC_D_FILE_NAME);
                }

                if (response.data.data.ismanuallyaddlicence === false) {
                  setFlagData(flag);
                  setFlagName(flag_Name);
                }

                if (response.data.data.categoriedatalist !== null && response.data.data.categoriedatalist !== undefined && response.data.data.categoriedatalist.length > 0) {
                  const categorylist = response.data.data.categoriedatalist;
                  setShowcategoryData(true);
                  setcategoryData(categorylist);
                }

                if (response.data.data.ismanuallyaddlicence == true) {
                  setShowCategoryDetail(false);
                  setShowOffenceDetail(false);
                } else {
                  setShowCategoryDetail(true);
                  setShowOffenceDetail(true);
                }

                var licenceCurrentDate = new Date();
                var licenceDueDate = new Date(response.data.data.expirydate);

                if (licenceCurrentDate > licenceDueDate) {
                  setlicencestatus("Expired");
                } else {
                  setlicencestatus("VALID");
                }

                if (
                  response.data.data.vehiclE_YOUPROVISONALLY_DRIVE !==
                  null &&
                  response.data.data.vehiclE_YOUPROVISONALLY_DRIVE !==
                  undefined
                ) {
                  const vehicleProvisonallyDrive =
                    response.data.data.vehiclE_YOUPROVISONALLY_DRIVE.split(
                      "/"
                    );
                  setvehicleProvisonallyDrive(vehicleProvisonallyDrive);
                }

                SetRoadWorthyValue(response.data.data.roadworthy);
                SetFitnessToDrive(
                  response.data.data.assessinG_FITNESS_DRIVE
                );
                SetdrivingWithMedical(
                  response.data.data.drivingwitH_MEDICAL_CONDITION
                );
                SeteyeSightValue(response.data.data.drivingeyE_SIGHT);
                SetblueBadgeValue(response.data.data.bluebadge);
                SetLorrybusandCoach(
                  response.data.data.qualifieD_FOR_LORRY_BUS_COACH
                );
                var dateofbirth = new Date(response.data.data.dob);
                var dob =
                  dateofbirth.getDate() +
                  "/" +
                  (dateofbirth.getMonth() + 1) +
                  "/" +
                  dateofbirth.getFullYear();
                setlicencedob(dob);

                if (response.data.data.ismanuallyaddlicence == true && response.data.data.isApproved != null) {
                  var currentStatus = response.data.data.isApproved == 0 ? "Unverified" : response.data.data.isApproved == 1 ? "Verified" : "Rejected";
                  setIsManualLicence(true);
                  setlicencecurrentstatus(currentStatus);
                  var currentStatusStyle = currentStatus == "Unverified" ? unverified_Style : currentStatus == "Verified" ? verified_Style : rejected_Style;
                  setlicencestatusstyle(currentStatusStyle);
                } else {
                  setIsManualLicence(false);
                }

                if (response.data.data.ismanuallyaddlicence == true) {
                  setismanuallyaddlicence(true);
                  var datecometoUKDt = new Date(response.data.data.datE_WHEN_YOUCOME_UK);
                  var ddmmyyydatecometoUKDate = datecometoUKDt.getDate() + "/" + (datecometoUKDt.getMonth() + 1) + "/" + datecometoUKDt.getFullYear();
                  setdatecometoUK(ddmmyyydatecometoUKDate);
                }

                var issuedate = new Date(response.data.data.issuedate);
                var ddmmyyyissueDate =
                  issuedate.getDate() +
                  "/" +
                  (issuedate.getMonth() + 1) +
                  "/" +
                  issuedate.getFullYear();
                setissuedate(ddmmyyyissueDate);

                var expirydate = new Date(response.data.data.expirydate);
                var ddmmyyyCurrentDate =
                  expirydate.getDate() +
                  "/" +
                  (expirydate.getMonth() + 1) +
                  "/" +
                  expirydate.getFullYear();
                setexpirydate(ddmmyyyCurrentDate);

                var lastupdate = new Date(
                  response.data.data.lasT_UPDATED_ON
                );
                var lastupdateon =
                  lastupdate.getDate() +
                  "/" +
                  (lastupdate.getMonth() + 1) +
                  "/" +
                  lastupdate.getFullYear();
                +"/";
                var time =
                  lastupdate.getHours() +
                  ":" +
                  String(lastupdate.getMinutes()).padStart(2, "0") +
                  ":" +
                  String(lastupdate.getSeconds()).padStart(2, "0");

                //lastupdateon += " " + time;
                setlastupdateon(lastupdateon);

                var lastupdate = new Date(
                  response.data.data.lasT_UPDATED_ON
                );
                var lastUpdateValue =
                  lastupdate.getDate() +
                  "/" +
                  (lastupdate.getMonth() + 1) +
                  "/" +
                  lastupdate.getFullYear();

                setLicenceDetailsLastUpdate(lastUpdateValue);
                var licenceNextCheckDate = new Date(
                  response.data.data.lasT_UPDATED_ON
                );
                licenceNextCheckDate.setDate(
                  lastupdate.getDate() +
                  props.openuserScreen.detail.row.licenceNextCheckDays
                );

                const nextCheckDate = new Date(licenceNextCheckDate);
                var NextCheckDateValue =
                  nextCheckDate.getDate() +
                  "/" +
                  (nextCheckDate.getMonth() + 1) +
                  "/" +
                  nextCheckDate.getFullYear();
                setLicenceDetailsLastCheckDate(NextCheckDateValue);

                const date = new Date();
                date.setHours(0, 0, 0, 0);
                const expirydateCount = new Date(
                  response.data.data.expirydate
                );

                if (
                  props.openuserScreen.detail.row
                    .licenceConfigrationDurationType === "Days"
                ) {
                  var days = parseInt(
                    props.openuserScreen.detail.row.licenceConfigrationFreqFrom
                  );
                  var hours = days * 24;
                  setlicenceConfigrationFreqFrom(hours);
                  licenceConfigrationFreqFromvalue = hours;
                } else {
                  licenceConfigrationFreqFromvalue =
                    props.openuserScreen.detail.row.licenceConfigrationFreqFrom;
                  setlicenceConfigrationFreqFrom(
                    props.openuserScreen.detail.row.licenceConfigrationFreqFrom
                  );
                }
                if (
                  checkuserFrequency(
                    new Date(response.data.data.lasT_UPDATED_ON),
                    "Licence"
                  )
                ) {
                  setrefreshButtonDisbled(true);
                }

                if (response.data.data.ismanuallyaddlicence == false) {
                  setshowrefreshbutton(true)
                }

                var currenDate = new Date();
                var photocardcurrenDate = new Date(
                  response.data.data.photocardexpirydate
                );
                var licenceCurrentDate = new Date();
                var vehicallicenceDueDate = new Date(expirydate);

                if (response.data.data.licenceStatus == 'Valid') {
                  SetdisplayValidLicence(true);
                  SetdisplayInValidLicence(false);
                } else {
                  if (response.data.data.expirydate != null) {
                    var vehiclemotExpiryDate = new Date(response.data.data.expirydate);
                    if (currenDate > vehiclemotExpiryDate) {
                      SetdisplayValidLicence(false);
                      SetdisplayInValidLicence(true);
                    } else {
                      SetdisplayValidLicence(true);
                      SetdisplayInValidLicence(false);
                    }
                  } else {
                    SetdisplayValidLicence(true);
                    SetdisplayInValidLicence(false);
                  }
                }

                var totaloffences = response.data.data.offenceslist.length;
                settotalOffence(totaloffences);

                var totalPoints = response.data.data.offenceslist
                  .map(
                    (item: { doC_DVLA_L_O_POINTS: any }) =>
                      item.doC_DVLA_L_O_POINTS
                  )
                  .reduce((prev: any, curr: any) => prev + curr, 0);
                settotalOffencePoints(totalPoints);

                if (totaloffences === 0) {
                  setNoOffences(true);

                  //EndorsementCard.style.backgroundColor = '##eef0f2';
                  //statusCard.style.color = 'white';
                } else {
                  if (totalPoints > 11) {
                    setHighOffences(true);
                    //  EndorsementCard.style.backgroundColor = '#E3173E';
                    // EndorsementTagCard.style.border = '2px solid #ff00002b';
                    // EndorsementCard.style.color = 'white';
                  } else {
                    setLowOffences(true);
                    // EndorsementCard.style.backgroundColor = '#FFBF00';
                    // EndorsementCard.style.color = 'white';
                    // EndorsementTagCard.style.border = '2px solid #ffff00';
                  }
                }
                if (response.data.data.offenceslist.length > 0) {
                  const offences = response.data.data.offenceslist;
                  setShowoffences(true);
                  setoffences(offences);
                }

                var diffrenceNextCheck = timeDiffCalc(expirydateCount, date);
                // setdiffrenceNextCheck(diffrenceNextCheck);
                if (diffrenceNextCheck === "") {
                  setdiffrenceNextCheck("0");
                } else {
                  var minusDays = "-" + diffrenceNextCheck;
                  if (licenceCurrentDate > vehicallicenceDueDate) {
                    setdiffrenceNextCheck(minusDays);
                    setlicenceStatusDaysExpiry(true);
                  } else {
                    setdiffrenceNextCheck(diffrenceNextCheck);
                    setlicenceStatusDaysExpiry(false);
                  }
                }

                const photocardExpirydate = new Date(
                  response.data.data.photocardexpirydate
                );
                var diffrenceNextCheckPhotocard = timeDiffCalc(
                  photocardExpirydate,
                  date
                );

                if (diffrenceNextCheckPhotocard === "") {
                  setdiffrenceNextCheckPhotocard("0");
                } else {
                  var minusDays = "-" + diffrenceNextCheckPhotocard;
                  if (currenDate > photocardcurrenDate) {
                    setdiffrenceNextCheckPhotocard(minusDays);
                  } else {
                    setdiffrenceNextCheckPhotocard(diffrenceNextCheckPhotocard);
                  }
                }
                bindVehicleCard();
              }
            }
          }
        }
      });
  };

  function timeDiffCalc(dateFuture: any, dateNow: any) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    let difference = "";
    if (days > 0) {
      difference += days === 1 ? `${days} ` : `${days}`;
    }
    return difference;
  }

  const bindVehicleCard = () => {
    setIsLoading(true);
    axios
      .get(
        "vehicle/GetAllVehicle?DOC_USER_ID=" +
        props.openuserScreen.detail.row.userId,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data.data !== null && response.data.data !== undefined) {
            if (response.data.data.length > 0) {
              setUservehicleData(response.data.data);
              setopenVehicleScreen(true);
              setIsLoading(false);
            }
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      });
  };

  const refreshLicenceDetails = () => {
    setIsLoading(true);
    var userId = props.openuserScreen.detail.row.userId;
    axios
      .get(
        "licence/RefreshLicence?licenceNumber=" + licenceData?.licenceno + "&userId=" + userId,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          setlicenceData(response.data.data);
          const category = response.data.data.categories.split("/");
          const vehicleProvisonallyDrive =
            response.data.data.vehiclE_YOUPROVISONALLY_DRIVE.split("/");
          setShowcategoryData(true);
          setcategoryData(category);
          setvehicleProvisonallyDrive(vehicleProvisonallyDrive);
          var dateofbirth = new Date(response.data.data.dob);
          var dob =
            dateofbirth.getDate() +
            "/" +
            (dateofbirth.getMonth() + 1) +
            "/" +
            dateofbirth.getFullYear();
          setlicencedob(dob);

          if (response.data.data.ismanuallyaddlicence == true && response.data.data.isApproved != null) {
            var currentStatus = response.data.data.isApproved == 0 ? "Unverified" : response.data.data.isApproved == 1 ? "Verified" : "Rejected";
            setIsManualLicence(true);
            setlicencecurrentstatus(currentStatus);
            var currentStatusStyle = currentStatus == "Unverified" ? unverified_Style : currentStatus == "Verified" ? verified_Style : rejected_Style;
            setlicencestatusstyle(currentStatusStyle);
          } else {
            setIsManualLicence(false);
          }

          var issuedate = new Date(response.data.data.issuedate);
          var ddmmyyyissueDate =
            issuedate.getDate() +
            "/" +
            (dateofbirth.getMonth() + 1) +
            "/" +
            issuedate.getFullYear();
          setissuedate(ddmmyyyissueDate);

          var expirydate = new Date(response.data.data.expirydate);
          var ddmmyyyCurrentDate =
            expirydate.getDate() +
            "/" +
            (dateofbirth.getMonth() + 1) +
            "/" +
            expirydate.getFullYear();
          setexpirydate(ddmmyyyCurrentDate);

          var lastupdate = new Date(response.data.data.lasT_UPDATED_ON);
          var lastupdateon =
            lastupdate.getDate() +
            "/" +
            (dateofbirth.getMonth() + 1) +
            "/" +
            lastupdate.getFullYear();
          +"/";
          var time =
            lastupdate.getHours() +
            ":" +
            String(lastupdate.getMinutes()).padStart(2, "0") +
            ":" +
            String(lastupdate.getSeconds()).padStart(2, "0");

          //lastupdateon += " " + time;
          setlastupdateon(lastupdateon);

          var licenceCurrentDate = new Date();
          var vehicallicenceDueDate = new Date(expirydate);

          if (licenceCurrentDate > vehicallicenceDueDate) {
            SetdisplayValidLicence(false);
            SetdisplayInValidLicence(true);
          } else {
            SetdisplayValidLicence(true);
            SetdisplayInValidLicence(false);
          }

          var currenDate = new Date();
          var photocardcurrenDate = new Date(
            response.data.data.photocardexpirydate
          );

          var licenceNextCheckDate = new Date(
            response.data.data.lasT_UPDATED_ON
          );
          licenceNextCheckDate.setDate(
            lastupdate.getDate() +
            props.openuserScreen.detail.row.licenceNextCheckDays
          );
          const nextCheckDate = new Date(licenceNextCheckDate);
          var NextCheckDateValue =
            nextCheckDate.getDate() +
            "/" +
            (nextCheckDate.getMonth() + 1) +
            "/" +
            nextCheckDate.getFullYear();
          setLicenceDetailsLastCheckDate(NextCheckDateValue);
          const date = new Date();
          date.setHours(0, 0, 0, 0);
          const expirydateCount = new Date(response.data.data.expirydate);
          var diffrenceNextCheck = timeDiffCalc(expirydateCount, date);
          // setdiffrenceNextCheck(diffrenceNextCheck);
          if (diffrenceNextCheck === "") {
            setdiffrenceNextCheck("0");
          } else {
            var minusDays = "-" + diffrenceNextCheck;
            if (licenceCurrentDate > vehicallicenceDueDate) {
              setdiffrenceNextCheck(minusDays);
              setlicenceStatusDaysExpiry(true);
            } else {
              setdiffrenceNextCheck(diffrenceNextCheck);
              setlicenceStatusDaysExpiry(false);
            }
          }
          const photocardExpirydate = new Date(
            response.data.data.photocardexpirydate
          );
          var diffrenceNextCheckPhotocard = timeDiffCalc(
            photocardExpirydate,
            date
          );
          var diffrenceNextCheckPhotocard = timeDiffCalc(
            photocardExpirydate,
            date
          );
          if (diffrenceNextCheckPhotocard === "") {
            setdiffrenceNextCheckPhotocard("0");
          } else {
            var minusDays = "-" + diffrenceNextCheckPhotocard;
            if (currenDate > photocardcurrenDate) {
              setdiffrenceNextCheckPhotocard(minusDays);
            } else {
              setdiffrenceNextCheckPhotocard(diffrenceNextCheckPhotocard);
            }
          }
          if (
            props.openuserScreen.detail.row.licenceConfigrationDurationType ===
            "Days"
          ) {
            var days = parseInt(
              props.openuserScreen.detail.row.licenceConfigrationFreqFrom
            );
            var hours = days * 24;
            setlicenceConfigrationFreqFrom(hours);
            licenceConfigrationFreqFromvalue = hours;
          } else {
            licenceConfigrationFreqFromvalue =
              props.openuserScreen.detail.row.licenceConfigrationFreqFrom;
            setlicenceConfigrationFreqFrom(
              props.openuserScreen.detail.row.licenceConfigrationFreqFrom
            );
          }

          if (checkuserFrequency(new Date(lastupdateon), "Licence")) {
            setrefreshButtonDisbled(true);
          }
          setIsLoading(false);
        }
        var totaloffences = response.data.data.offenceslist.length;
        settotalOffence(totaloffences);
        var totalPoints = response.data.data.offenceslist
          .map(
            (item: { doC_DVLA_L_O_POINTS: any }) =>
              item.doC_DVLA_L_O_POINTS
          )
          .reduce((prev: any, curr: any) => prev + curr, 0);
        settotalOffencePoints(totalPoints);
        if (totaloffences === 0) {
          setNoOffences(true);
        } else {
          if (totalPoints > 11) {
            setHighOffences(true);
          } else {
            setLowOffences(true);
          }
        }
        if (response.data.data.offenceslist.length > 0) {
          const offences = response.data.data.offenceslist;
          setShowoffences(true);
          setoffences(offences);
        }
        const photocardExpirydate = new Date(
          response.data.data.photocardexpirydate
        );
        setIsLoading(false);
      });
  };

  const UpdatelicenceAdditionoalDetails = () => {
    SetdisableSwitch(true);
    axios
      .post(
        `licence/UpdateLIcenceOtherDetails`,
        licenceAdditionalDetails,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              setEditButtonShow(true);
            }
          }
        }
      });
  };

  function onSetArchiveButtonClick(event: any) {
    SetArchiveData(props.openuserScreen.detail.row.userId);
  }

  function onSetUnArchiveButtonClick(event: any) {
    SetUnArchiveData(props.openuserScreen.detail.row.userId);
  }

  const SetArchiveData = (userId: string) => {
    setIsLoading(true);
    axios
      .get("User/SetUserArchiveById?userId=" + userId, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              setArchiveButtonVisible(false);
              NotifyPublisher.message(('User has been archived.'), false, 'successfullyAutoCheckAdd', 'information', 'information', '', '');
              setIsLoading(false);
            }
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      });
  };

  const SetUnArchiveData = (userId: string) => {
    setIsLoading(true);
    axios
      .get("User/SetUserUnarchiveById?userId=" + userId, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              setArchiveButtonVisible(true);
              NotifyPublisher.message(('User has been un-archived.'), false, 'successfullyAutoCheckAdd', 'information', 'information', '', '');
              setIsLoading(false);
            }
            setIsLoading(false);
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="widget-card-inner pr_0 pb_0 p_10" id="doc_dashboard">
        <div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <div className="userDashboard pl_40">
                <table className="licence-Form-Table">
                  <tbody>
                    <tr>
                      <td>
                        <div className="userimage">
                          <TagAvatar
                            className="ml_10"
                            src={userImage}
                            placeholder="GS"
                            size="100px"
                          />
                          <div className="avtar pt_5 pl_5">
                            <TagH5 className="avtarName">
                              {props.openuserScreen.detail.row.fullName}
                            </TagH5>
                          </div>
                        </div>
                      </td>

                      <td className="pl_50">
                        <TagCard>
                          <div className="w_300">
                            <div className="Statusheader">
                              <div className="statusCard">
                                <span>Auto Update Date</span>
                              </div>
                            </div>
                            <div>
                              <Row>
                                <Col sm={6} md={6} lg={6}>
                                  <div className="licencestatusheader">
                                    <span className="f_17">
                                      {LicenceDetailsLastUpdate}
                                    </span>
                                  </div>
                                  <div className="licencestatuscarddetails">
                                    <span>Last Update</span>
                                  </div>
                                </Col>

                                <Col sm={6} md={6} lg={6}>
                                  <div className="licencestatusheader">
                                    <span className="f_17">
                                      {LicenceDetailsLastCheckDate}
                                    </span>
                                  </div>

                                  <div className="licencestatuscarddetails">
                                    <span>Next Check</span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </TagCard>
                      </td>

                      <td className="pl_50">
                        <TagCard>
                          <div className={showlicenceStatusDaysExpiry ? "highPointBorder" : "w_300"}>
                            <div className="Statusheader">
                              {showlicenceStatusDaysExpiry ? (
                                <div className="statusCard invalidLicenceStatusCard">
                                  <span>Licence Status</span>
                                </div>
                              ) : (
                                <div className="statusCard validLicenceStatusCard">
                                  <span>Licence Status</span>
                                </div>
                              )}
                            </div>
                            <div>
                              <Row>
                                <Col>
                                  <div className="licencestatusheader">
                                    <span className="f_17 ">
                                      {diffrenceNextCheck}
                                    </span>
                                    <span className="f_12 pl_3">Days</span>
                                  </div>

                                  <div className="licencestatuscarddetails">
                                    <span>Driving Licence</span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </TagCard>
                      </td>

                      {showcategorydetail ? (
                        <td className="pl_50">
                          <TagCard>
                            {showNoOffences ? (
                              <div className="w_300">
                                <div className="Statusheader">
                                  <div className="statusCard">
                                    <span className="cardHeading">Endorsement</span>
                                  </div>
                                </div>
                                <div>
                                  <Row>
                                    <Col sm={6} md={6} lg={6}>
                                      <div className="licencestatusheader">
                                        <span className="f_17">{totalOffences}</span>
                                      </div>

                                      <div className="licencestatuscarddetails">
                                        <span>Offences</span>
                                      </div>
                                    </Col>

                                    <Col sm={6} md={6} lg={6}>
                                      <div className="licencestatusheader">
                                        <span className="f_17">{totalOffencePoints}</span>
                                      </div>

                                      <div className="licencestatuscarddetails">
                                        <span>Points</span>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ) : <div
                              className={
                                showHighOffences
                                  ? "highPointBorder"
                                  : "lowPointBorder "
                              }
                            >
                              <div className="Statusheader">
                                {showNoOffences ? (
                                  <div className="noOffences licencestatusheader">
                                    <span>Endorsement</span>
                                  </div>
                                ) : null}

                                {showHighOffences ? (
                                  <div className="highOffences">
                                    <span>Endorsement</span>
                                  </div>
                                ) : null}

                                {showLowOffences ? (
                                  <div className="lowOffences">
                                    <span>Endorsement</span>
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <Row>
                                  <Col sm={6} md={6} lg={6}>
                                    <div className="licencestatusheader">
                                      <span className="f_17">{totalOffences}</span>
                                    </div>

                                    <div className="licencestatuscarddetails">
                                      <span>Offences</span>
                                    </div>
                                  </Col>

                                  <Col sm={6} md={6} lg={6}>
                                    <div className="licencestatusheader">
                                      <span className="f_17">
                                        {totalOffencePoints}
                                      </span>
                                    </div>

                                    <div className="licencestatuscarddetails">
                                      <span>Points</span>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>}
                          </TagCard>
                        </td>
                      ) : (<td className="pl_50">
                        <div className="w_300"> </div>
                      </td>)}
                    </tr>

                    {showcategorydetail ? (<tr>
                      <td colSpan={4} className="pb_20 pt_20">
                        <div>
                          <TagH5 className="dashboardheading">
                            {" "}
                            Vehicle categories you can drive
                          </TagH5>
                          {showcategoryData ? (
                            <div>
                              {categoryData.map((p: any, i: any) => (
                                <VehiclesDriveorNot data={p} key={i} />
                              ))}
                            </div>
                          ) : (
                            <TagH5 className="noOffences">No CategoryData</TagH5>
                          )}
                        </div>
                      </td>
                    </tr>
                    ) : ('')}

                    {showoffencedetail ? (<tr>
                      <td colSpan={4} className="pt_20">
                        <div>
                          <div>
                            <TagH5 className="dashboardheading"> Offences</TagH5>
                            {showoffences ? (
                              <div className="pb_20">
                                <table className="offencesTable" >
                                  <tr>
                                    <th className="w_20" style={{ padding: "5px 10px", width: "95px", fontSize: "12px" }}>Code</th>
                                    <th className="w_20" style={{ padding: "5px 10px", width: "95px", fontSize: "12px" }}>Points</th>
                                    <th className="w_20" style={{ padding: "5px 10px", width: "95px", fontSize: "12px" }}>Offence Date</th>
                                    <th className="w_20" style={{ padding: "5px 10px", width: "95px", fontSize: "12px" }}>Conviction Date</th>
                                    <th className="w_20" style={{ padding: "5px 10px", width: "95px", fontSize: "12px" }}>Offence Legal Literal</th>
                                  </tr>
                                  <tr className="offencesTable"></tr>

                                  {offences.map((p: ILicenceData, i: any) => (
                                    <Offences obj={p} key={i} />
                                  ))}
                                  {/* <tr className="offencesTable"></tr> */}

                                </table>
                              </div>
                            ) : (
                              <TagH5 className="noOffences">No Offences</TagH5>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                    ) : ('')}

                    <tr>
                      <td colSpan={4}>
                        <div className="pb_20">
                          <table>
                            <tbody>
                              <tr>
                                <td className="w_20">
                                  <div>
                                    <tag-text text="Assessing Fitness to Drive"></tag-text>
                                  </div>
                                </td>
                                <td className="w_20">
                                  <div>
                                    <TagSwitch
                                      size="normal"
                                      labelPlacement="right"
                                      name="Accessfitness"
                                      accent="viridiangreen"
                                      disabled={disableSwitch}
                                      onSwitchValueChange={onFitnessValueChange}
                                      checked={fitnessToDrive}
                                    >
                                      {" "}
                                    </TagSwitch>
                                  </div>
                                </td>
                                <td className="w_20">
                                  <div>
                                    <tag-text text="Roadworthy"></tag-text>
                                  </div>
                                </td>
                                <td className="w_20">
                                  <div>
                                    <TagSwitch
                                      size="normal"
                                      labelPlacement="right"
                                      name="Roadworthy"
                                      accent="viridiangreen"
                                      disabled={disableSwitch}
                                      onSwitchValueChange={onRoadworthyValueChange}
                                      checked={roadWorthy}
                                    >
                                      {" "}
                                    </TagSwitch>
                                  </div>
                                </td>
                                <td className="w_20">
                                  <div>
                                    <tag-text text="Driving with Medical Condition"></tag-text>
                                  </div>
                                </td>
                                <td className="w_20">
                                  <div>
                                    <TagSwitch
                                      size="normal"
                                      labelPlacement="right"
                                      name="MedicalCondition"
                                      accent="viridiangreen"
                                      disabled={disableSwitch}
                                      checked={drivingWithMedical}
                                      onSwitchValueChange={
                                        onDrivingwithMedicalValueChange
                                      }
                                    >
                                      {" "}
                                    </TagSwitch>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="w_20">
                                  <div>
                                    <tag-text text="Driving Eye Sight"></tag-text>
                                  </div>
                                </td>
                                <td className="w_20">
                                  <div>
                                    <TagSwitch
                                      size="normal"
                                      labelPlacement="right"
                                      name="EyeSight"
                                      accent="viridiangreen"
                                      disabled={disableSwitch}
                                      checked={eyeSight}
                                      onSwitchValueChange={
                                        onDrivingEyeSightValueChange
                                      }
                                    >
                                      {" "}
                                    </TagSwitch>
                                  </div>
                                </td>
                                <td className="w_20">
                                  <div>
                                    <tag-text text="Blue Badge"></tag-text>
                                  </div>
                                </td>
                                <td className="w_20">
                                  <div>
                                    <TagSwitch
                                      size="normal"
                                      labelPlacement="right"
                                      name="BlueBadge"
                                      accent="viridiangreen"
                                      disabled={disableSwitch}
                                      checked={blueBadge}
                                      onSwitchValueChange={onBlueBadgeValueChange}
                                    >
                                      {" "}
                                    </TagSwitch>
                                  </div>
                                </td>
                                <td className="w_20">
                                  <div>
                                    <tag-text text="Qualified for Lorry, Bus & Coach"></tag-text>
                                  </div>
                                </td>
                                <td className="w_20">
                                  <div>
                                    <TagSwitch
                                      size="normal"
                                      labelPlacement="right"
                                      name="LorryandBus"
                                      accent="viridiangreen"
                                      disabled={disableSwitch}
                                      checked={lorryBusandCoach}
                                      onSwitchValueChange={
                                        onLorryBusandCoachValueChange
                                      }
                                    >
                                      {" "}
                                    </TagSwitch>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        <div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="licenceandvehiclesheading">
              <TagH5> Licence & Vehicle Details</TagH5>
            </div>
          )}
        </div>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="maindiv pl_30" id="mainDiv">
            <div className="divFlexClass">
              <TagCard id="licenceCard" background-image="access">
                <div>
                  <div className="cod-header">
                    <div className={"flag-icon licenceFlage" + licenceData?.licenceno}>
                      <TagIcon
                        icon={`${url}DOC_Flags/${flagData}.png`}
                        iconStyle={{ height: "24px", width: "35px" }}
                        style={{ marginBlock: "0.5em", float: "left" }}
                      ></TagIcon>
                      <TagText
                        textStyle={{
                          fontSize: "17px",
                          marginBlock: "0",
                          paddingLeft: "40px",
                          paddingTop: "7px",
                          fontWeight: "600",
                        }}
                        text="Driving Licence"
                      />
                    </div>
                    <TagTooltip
                      tooltipFor={'.licenceFlage' + licenceData?.licenceno}
                      header={flagName}
                      background='light'
                      position='left-end'>
                    </TagTooltip>
                    {showrefreshbutton ? (
                      <div className="refresh-icon">
                        <TagButton
                          size="small"
                          id="refreshLicence"
                          accent="porcelain"
                          icon="Refresh"
                          icon-accent="black"
                          disabled={refreshbuttonDisbled}
                          button-style={{ height: "25px", width: "25px" }}
                          buttonIconStyle={{ height: "20px", width: "20px" }}
                          onButtonClick={onRefreshButtonClick}
                        />
                      </div>
                    ) : (null)
                    }
                  </div>
                </div>
                <div className="licencecardstyle">
                  <Row>
                    <Col sm={5} md={5} lg={5} className="licencecardsdetailstyle">
                      <div className="userIcon">
                        <TagAvatar
                          src={userImage}
                          placeholder="GS"
                          size="100px"
                        />
                      </div>
                      {displayValidLicence ? (
                        <div id="validateLicenceDetails" className="validLicence">
                          <div className="valid-licence-icon">
                            <tag-icon
                              icon="Tick"
                              accent="white"
                              size="default"
                              icon-style={validinvalidiconstyle}
                            />
                          </div>
                          <div className="licence_details_text">
                            <span className="licenceStatusStyle">{licencestatus}</span>
                          </div>
                        </div>
                      ) : null}
                      {displayInValidLicence ? (
                        <div
                          id="invalidateLicenceDetails"
                          className="invalidLicence"
                        >
                          <div className="valid-licence-icon">
                            <tag-icon
                              icon="Warning"
                              accent="sunflower"
                              size="default"
                              icon-style={validinvalidiconstyle}
                            />
                          </div>
                          <div className="licence_details_text">
                            <span className="licenceStatusStyle">{licencestatus}</span>
                          </div>
                        </div>
                      ) : null}
                    </Col>

                    <Col sm={7} md={7} lg={7} className="licencecardsdetailstyle">
                      <table>
                        <tbody>
                          <tr>
                            <td className="w_20">
                              <a
                                onClick={onLicenceNoClick}
                                style={{ cursor: "pointer" }}
                              >
                                <tag-field
                                  name="lic-m-licenceno"
                                  label="Licence No:"
                                  value={licenceData?.licenceno}
                                  container-style={containerstyle}
                                  label-style={labelstyle}
                                  value-style={valuestyle}
                                ></tag-field>
                              </a>
                            </td>
                          </tr>
                          {!ismanuallyaddlicence ? (
                            <tr>
                              <td className="w_20">
                                <tag-field
                                  name="lic-m-dob"
                                  data-type="string"
                                  label="Date of Birth:"
                                  value={licencedob}
                                  container-style={containerstyle}
                                  label-style={labelstyle}
                                  value-style={valuestyle}
                                ></tag-field>
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td className="w20">
                              <tag-field
                                name="lic-m-issueDate"
                                data-type="date"
                                label="Issue Date:"
                                value={issuedate}
                                container-style={containerstyle}
                                label-style={labelstyle}
                                value-style={valuestyle}
                              ></tag-field>
                            </td>
                          </tr>
                          <tr>
                            <td className="w_20">
                              <tag-field
                                name="lic-m-ExpiryDate"
                                data-type="date"
                                label="Expiry Date:"
                                value={expirydate}
                                container-style={containerstyle}
                                label-style={labelstyle}
                                value-style={valuestyle}
                              ></tag-field>
                            </td>
                          </tr>
                          {ismanuallyaddlicence ? (
                            <tr>
                              <td className="w_20">
                                <tag-field
                                  name="lic-m-currentStatus"
                                  data-type="string"
                                  label="Current Status:"
                                  value={licencecurrentstatus}
                                  container-style={containerstyle}
                                  label-style={labelstyle}
                                  value-style={licencestatusstyle}
                                ></tag-field>
                              </td>
                            </tr>) : null}
                          <tr>
                            <td className="w_20">
                              <tag-field
                                name="lic-m-lastUpdateOn"
                                data-type="date"
                                label="Last Updated:"
                                value={lastupdateon}
                                container-style={containerstyle}
                                label-style={labelstyle}
                                value-style={valuestyle}
                              ></tag-field>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
              </TagCard>
            </div>
            {/* )} */}

            {openVehicleScreen ? (
              <div>
                {userVehicleData.map(
                  (p: IvehicleData, i: any) => (
                    // (p.vehicleFrequencyFrom =
                    //   props.openuserScreen.detail.row.vehicleConfigrationFreqFrom)
                    // (p.vehicleFrequencyType =
                    //   props.openuserScreen.detail.row.vehicleConfigrationDurationType)
                    (<Vehicles obj={p} key={i} freqFrom={props.openuserScreen.detail.row.vehicleConfigrationFreqFrom} freqType={props.openuserScreen.detail.row.vehicleConfigrationDurationType} />)
                  )
                )}
              </div>
            ) : null}
          </div>
        )}

        <div className="clear fr mt_15">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="fr ml_37">
              <TagButton
                text="Back"
                minWidth="150px"
                size="large"
                onButtonClick={onBackClick}
              />
            </div>
          )}

          {editButtonsShow ? (
            <div className="fr pl_37">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <TagButton
                  text="Edit"
                  accent="viridiangreen"
                  minWidth="150px"
                  onButtonClick={onAdditionalDetailEditClick}
                  size="large"
                />
              )}
            </div>
          ) : (
            <div className="fr">
              {/* <div className="fl ml_37">
                <TagButton
                  text="Save"
                  minWidth="150px"
                  onButtonClick={onAdditionalDetailSaveClick}
                  size="large"
                  icon="Save"
                  iconAccent="porcelain"
                  accent="viridiangreen"
                  disabled={disabledSave}
                />
              </div> */}
              <div className=" fl ml_37">
                <TagButton
                  text="Cancel"
                  onButtonClick={onCancelButtonClick}
                  size="large"
                  minWidth="150px"
                />
              </div>
              {archiveButtonVisible ? (
                <div className=" fl ml_37">
                  <TagButton
                    text="Archive"
                    size="large"
                    minWidth="150px"
                    onButtonClick={onSetArchiveButtonClick}
                  />
                </div>
              ) : null}

              {!archiveButtonVisible ? (
                <div className=" fl ml_37">
                  <TagButton
                    text="Unarchive"
                    size="large"
                    minWidth="150px"
                    onButtonClick={onSetUnArchiveButtonClick}
                  />
                </div>
              ) : null}
              <div className=" fl ml_37">
                <TagButton
                  text="Start AutoCheck"
                  size="large"
                  minWidth="150px"
                  onButtonClick={onStartRecheckButtonClick}
                  disabled={disabledStartAutoCheck}
                />
              </div>
              <div className=" fl ml_37">
                <TagButton
                  text="Stop Autocheck"
                  size="large"
                  minWidth="150px"
                  onButtonClick={onStopRecheckButtonClick}
                  disabled={disabledStopAutoCheck}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <LicenceDetails
          expirydate={expirydate}
          licencedob={licencedob}
          issuedate={issuedate}
          datecometoUK={datecometoUK}
          fileName={fileName}
          ismanuallyaddlicence={ismanuallyaddlicence}
          openLicenceScreen={openLicenceScreen}
          licencevehicleData={licencevehicleData}
          SetopenLicenceScreen={SetopenLicenceScreen}
        />
        {backToUserScreen ? <User /> : null}
      </div>
    </>
  );
}
export default UserDetails;