/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-redeclare */
import {
  TagButton,
  TagCard,
  TagIcon,
  TagText
} from "@tag/tag-components-react-v3";
import { TagTooltip } from "@tag/tag-components-react-v2";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import "../assets/css/userDetail.scss";
import VehicleDetails from "../components/UserDetails/vehicle/VehicleDetails";
import { IvehicleData } from "../core/view-Model/userdetails.model";
import { PidgetSettings } from "../PidgetSettings";
import { useIframePidgetSettings } from "@workspace/utils-react/dist/iframePidget/useIframePidgetSettings";
import { HttpHeaders } from "../shared/HttpHeaders";
import LoadingSpinner from "./../components/LoadingSpinner";

const Vehicles: React.FC<{
  obj: IvehicleData;
  freqFrom: any;
  freqType: any;
}> = (props) => {
  const onRefreshButtonClick = (e: any) => {
    refreshVehicleDetails();
  };
  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  var imageSRC = url + 'Content/Images/';
  const containerstyle = '{"padding": "5px 10px 2px 0px"}';
  const container_style_last = '{"padding": "6px 10px 3px 0px"}';
  const labelstyle = '{"font-size": "12px", "width": "110px"}';
  const valuestyle = '{"font-size": "12px"}';
  var reason_value_style = '{"font-size": "12px", "overflow": "hidden", "white-space": "nowrap", "text-overflow": "ellipsis", "width": "105px"}';
  var pending_Style = '{"font-size": "12px","color" :"#E4A920 "}';
  var approved_Style = '{"font-size": "12px","color" :"#5FA718"}';
  var rejected_Style = '{"font-size": "12px","color" :"#E42A30"}';
  const [makeforExport, setmakeforExport] = React.useState("");
  const [taxDueDate, settaxDueDate] = React.useState("");
  const [motExpiryDate, setmotExpiryDate] = React.useState("");
  const [lastV5cIssuedDate, setlastV5cIssuedDate] = React.useState("");
  const [insStartDate, setinsStartDate] = React.useState("");
  const [insExpiryDate, SetinsExpiryDate] = React.useState("");
  const [vehicleDetailsData, setvehicleDetailsData] = useState([]);
  const [insTerm, setinsTerm] = React.useState("");

  const [openVehicleDetailScreen, SetopenVehicleDetailScreen] = useState(false);
  const [vehicleDueDateNotAvailable, setvehicleDueDateNotAvailable] = useState(false);
  const [motExpiryDateNotAvailable, setmotExpiryDateNotAvailable] = useState(false);
  const [insuranceExpiryDateNotAvailable, setinsuranceExpiryDateNotAvailable] = useState(false);

  const [showMotInvalidDIV, setshowMotInvalidDIV] = useState(false);
  const [showMotValidDiv, setshowMotValidDiv] = useState(false);

  const [showTaxInvalidDIV, setshowTaxInvalidDIV] = useState(false);
  const [showTaxValidDiv, setshowTaxValidDiv] = useState(false);
  const [showTaxSornValidDiv, setshowTaxSornValidDiv] = useState(false);
  const [showInsuranceInvalidDiv, setshowInsuranceInvalidDiv] = useState(false);
  const [showInsuranceValidDiv, setshowInsuranceValidDiv] = useState(false);

  const [taX_DUE_DATE, settaX_DUE_DATE] = React.useState("");
  const [moT_EXPIRY_DATE, setmoT_EXPIRY_DATE] = React.useState("");
  const [insurancE_EXPIRY_DATE, setinsurancE_EXPIRY_DATE] = React.useState("");
  const [lasT_UPDATED_ON, setlasT_UPDATED_ON] = React.useState("");
  const [taX_STATUS, settaX_STATUS] = React.useState("");
  const [moT_STATUS, setmoT_STATUS] = React.useState("");
  const [refreshbuttonDisbled, setrefreshButtonDisbled] = useState(false);
  const Headers = HttpHeaders();
  const [vehicleConfigrationFreqFrom, setvehicleConfigrationFreqFrom] =
    useState(0);
  const [vehicleType, setvehicleType] = useState("");
  const [showVehicleType, SetshowVehicleType] = useState(false);
  const [createVehicleCard, SetcreateVehicleCard] = useState(true);
  const [vehicleApproved, SetvehicleApproved] = useState("");
  const [manuallyAddVehicleReason, SetmanuallyAddVehicleReason] = useState("");
  const [isApproved, SetisApproved] = useState(false);
  const [isrejected, Setisisrejected] = useState(false);
  const [createManuallyVehicleCardForUk, SetcreateManuallyVehicleCardForUk] = useState(false);
  const [vehicletaxDueDateString, SetVehicletaxDueDateString] = useState("");
  const [vehiclemotExpiryDateString, SetVehiclemotExpiryDateString] = useState("");
  const [vehicleInsuranceEndDateString, SetVehicleInsuranceEndDateString] = useState("");
  const [flagData, setFlagData] = useState('Flag-of-United-Kingdom');
  const [flagName, setFlagName] = useState('United Kingdom');
  const [isLoading, setIsLoading] = React.useState(false);

  var vehicleConfigrationFreqFromvalue = 0;
  const OnVehicleNoClick = (e: any) => {
    showvehicleDetails();
  };

  React.useEffect(() => {
    VehicleValidation();
  }, [props]);

  const VehicleValidation = () => {
    const flag = 'Flag-of-United-Kingdom';
    setFlagName(props.obj.vehicleImage.countrY_NAME);

    if (props.obj.ismanuallyaddvehicle === true) {
      setFlagData(props.obj.vehicleImage.countrY_IMAGE_CODE);
    }

    if (props.obj.ismanuallyaddvehicle === false) {
      setFlagData(flag);
    }

    if (props.obj.ismanuallyaddvehicle === true) {
      if (
        props.obj.manuaalyaddvehiclereason != null ||
        props.obj.manuaalyaddvehiclereason != undefined
      ) {
        //var reason = props.obj.manuaalyaddvehiclereason.replace(/\s/g, "");
        SetmanuallyAddVehicleReason(props.obj.manuaalyaddvehiclereason.trim());
      } else {
        SetmanuallyAddVehicleReason("");
      }

      if (props.obj.countrY_NAME === "United Kingdom") {
        SetcreateManuallyVehicleCardForUk(true);
      } else {
        SetcreateManuallyVehicleCardForUk(false);
      }
      SetcreateVehicleCard(false);
    } else {
      SetcreateVehicleCard(true);
    }

    if (props.obj.isapproved === 0) {
      SetvehicleApproved("Pending");
      SetisApproved(false);
    } else if (props.obj.isapproved === 1) {
      SetvehicleApproved("Approved");
      SetisApproved(true);
    } else {
      SetvehicleApproved("Rejected");
      Setisisrejected(true);
    }

    settaX_STATUS(props.obj.taX_STATUS);
    setmoT_STATUS(props.obj.moT_STATUS);

    if (props.obj.iscompanY_VEHICLE === true) {
      if (props.obj.ismanuallyaddvehicle === true) {
        if (props.obj.isapproved == 0) {
          setvehicleType("(C) - Unverified");
        } else if (props.obj.isapproved == 1) {
          setvehicleType("(C) - Verified");
        }
        else {
          setvehicleType("(C) - Rejected");
        }
      } else {
        setvehicleType("(C)");
      }
      SetshowVehicleType(true);
    } else {
      if (props.obj.ismanuallyaddvehicle === true) {
        if (props.obj.isapproved == 0) {
          setvehicleType("- Unverified");
        } else if (props.obj.isapproved == 1) {
          setvehicleType("- Verified");
        }
        else {
          setvehicleType("- Rejected");
        }
        SetshowVehicleType(true);
      }
    }

    if (props.freqType === "Days") {
      var days = parseInt(props.freqFrom);
      var hours = days * 24;
      setvehicleConfigrationFreqFrom(hours);

      vehicleConfigrationFreqFromvalue = hours;
    } else {
      vehicleConfigrationFreqFromvalue = parseInt(props.freqFrom);
      setvehicleConfigrationFreqFrom(parseInt(props.freqFrom));
    }

    if (checkuserFrequency(new Date(props.obj.lasT_UPDATED_ON), "Vehicle")) {
      setrefreshButtonDisbled(true);
    }

    if (props.obj.taX_DUE_DATE != null) {
      var taxDueDate = new Date(props.obj.taX_DUE_DATE);
      var ddmmyyytaxDueDate =
        taxDueDate.getDate() +
        "/" +
        (taxDueDate.getMonth() + 1) +
        "/" +
        taxDueDate.getFullYear();
      settaX_DUE_DATE(ddmmyyytaxDueDate);
    } else {
      setvehicleDueDateNotAvailable(true);
    }

    if (props.obj.moT_EXPIRY_DATE != null) {
      var motExpiryDate = new Date(props.obj.moT_EXPIRY_DATE);
      var ddmmyyymotExpiryDate =
        motExpiryDate.getDate() +
        "/" +
        (motExpiryDate.getMonth() + 1) +
        "/" +
        motExpiryDate.getFullYear();
      setmoT_EXPIRY_DATE(ddmmyyymotExpiryDate);
    } else {
      setmotExpiryDateNotAvailable(true);
    }

    if (props.obj.insurancE_EXPIRY_DATE != null) {
      var insuranceExpiryDate = new Date(props.obj.insurancE_EXPIRY_DATE);
      var ddmmyyyinsuranceExpiryDate =
        insuranceExpiryDate.getDate() +
        "/" +
        (insuranceExpiryDate.getMonth() + 1) +
        "/" +
        insuranceExpiryDate.getFullYear();
      setinsurancE_EXPIRY_DATE(ddmmyyyinsuranceExpiryDate);
    } else {
      setinsuranceExpiryDateNotAvailable(true);
    }

    if (props.obj.lasT_UPDATED_ON != null) {
      var lastupdate = new Date(props.obj.lasT_UPDATED_ON);
      var lastupdateon =
        lastupdate.getDate() +
        "/" +
        (lastupdate.getMonth() + 1) +
        "/" +
        lastupdate.getFullYear();
      var time =
        lastupdate.getHours() +
        ":" +
        String(lastupdate.getMinutes()).padStart(2, "0") +
        ":" +
        String(lastupdate.getSeconds()).padStart(2, "0");

      //lastupdateon += " " + time;
      setlasT_UPDATED_ON(lastupdateon);

      setmotExpiryDate(lastupdateon);
    } else {
      setmotExpiryDateNotAvailable(true);
    }

    var motExpirycurrentDate = new Date();
    if (props.obj.moT_EXPIRY_DATE != null) {
      var vehiclemotExpiryDate = new Date(props.obj.moT_EXPIRY_DATE);
      if (motExpirycurrentDate > vehiclemotExpiryDate) {
        setshowMotInvalidDIV(true);
        setshowMotValidDiv(false);
      } else {
        setshowMotInvalidDIV(false);
        setshowMotValidDiv(true);
      }
    } else {
      setshowMotInvalidDIV(true);
      setshowMotValidDiv(false);
    }

    var insuranceExpirycurrentDate = new Date();
    if (props.obj.insurancE_EXPIRY_DATE != null) {
      var vehicleInsuranceExpiryDate = new Date(props.obj.insurancE_EXPIRY_DATE);
      if (insuranceExpirycurrentDate > vehicleInsuranceExpiryDate) {
        setshowInsuranceInvalidDiv(true);
        setshowInsuranceValidDiv(false);
      } else {
        setshowInsuranceInvalidDiv(false);
        setshowInsuranceValidDiv(true);
      }
    } else {
      setshowInsuranceInvalidDiv(true);
      setshowInsuranceValidDiv(false);
    }

    var taxCurrentDate = new Date();
    var vehicletaxDueDate = new Date(props.obj.taX_DUE_DATE);
    if (props.obj.taX_STATUS === "SORN") {
      setshowTaxSornValidDiv(true);
      setshowTaxValidDiv(false);
      setshowTaxInvalidDIV(false);
    } else {
      if (taxCurrentDate > vehicletaxDueDate) {
        setshowTaxInvalidDIV(true);
        setshowTaxSornValidDiv(false);
        setshowTaxValidDiv(false);
      } else {
        setshowTaxValidDiv(true);
        setshowTaxInvalidDIV(false);
        setshowTaxSornValidDiv(false);
      }
    }

    if (props.obj.taX_DUE_DATE != null) {
      var objtaxDueDate = new Date(props.obj.taX_DUE_DATE);
      SetVehicletaxDueDateString(String(objtaxDueDate.getDate() + "/" + (objtaxDueDate.getMonth() + 1) + "/" + objtaxDueDate.getFullYear()));
    } else {
      SetVehicletaxDueDateString("NotAvailable");
    }

    if (props.obj.moT_EXPIRY_DATE != null) {
      var objmotDueDate = new Date(props.obj.moT_EXPIRY_DATE);
      SetVehiclemotExpiryDateString(String(objmotDueDate.getDate() + "/" + (objmotDueDate.getMonth() + 1) + "/" + objmotDueDate.getFullYear()));
    } else {
      SetVehiclemotExpiryDateString("NotAvailable");
    }

    if (props.obj.insurancE_EXPIRY_DATE != null) {
      var objinsuranceDueDate = new Date(props.obj.insurancE_EXPIRY_DATE);
      SetVehicleInsuranceEndDateString(String(objinsuranceDueDate.getDate() + "/" + (objinsuranceDueDate.getMonth() + 1) + "/" + objinsuranceDueDate.getFullYear()));
    } else {
      SetVehicleInsuranceEndDateString("NotAvailable");
    }
  };
  const showvehicleDetails = () => {
    setIsLoading(true);
    var vehicleNo = props.obj.registertioN_NUMBER;
    var userId = props.obj.doC_USER_ID;
    axios
      .get("vehicle/GetAllVehicle?REGISTERTION_NUMBER=" + vehicleNo + "&DOC_USER_ID=" + userId, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                const data = response.data.data[0];
                setvehicleDetailsData(data);

                // setLicencevehicleData(response.data.data[0]);

                if (response.data.data[0].markedforexport === false) {
                  setmakeforExport("No");
                } else {
                  setmakeforExport("Yes");
                }
                var taxDueDate = new Date(data.taX_DUE_DATE);
                if (data.taX_DUE_DATE != null) {
                  var ddmmyyytaxDueDate =
                    taxDueDate.getDate() +
                    "/" +
                    (taxDueDate.getMonth() + 1) +
                    "/" +
                    taxDueDate.getFullYear();
                  settaxDueDate(ddmmyyytaxDueDate);
                } else {
                  settaxDueDate("");
                }

                var motExpiryDate = new Date(data.moT_EXPIRY_DATE);
                if (data.moT_EXPIRY_DATE != null) {
                  var ddmmyyymotExpiryDate =
                    motExpiryDate.getDate() +
                    "/" +
                    (motExpiryDate.getMonth() + 1) +
                    "/" +
                    motExpiryDate.getFullYear();
                  setmotExpiryDate(ddmmyyymotExpiryDate);
                } else {
                  setmotExpiryDate("  ");
                }

                var insStartDate = new Date(data.insurancE_START_DATE);
                if (data.insurancE_START_DATE != null) {
                  var ddmmyyyinsStartDate =
                    insStartDate.getDate() +
                    "/" +
                    (insStartDate.getMonth() + 1) +
                    "/" +
                    insStartDate.getFullYear();
                  setinsStartDate(ddmmyyyinsStartDate);
                } else {
                  setinsStartDate("  ");
                }

                if (data.insurancE_START_DATE != null && data.insurancE_EXPIRY_DATE != null) {
                  var insuranceStart = new Date(data.insurancE_START_DATE);
                  var insuranceExpiry = new Date(data.insurancE_EXPIRY_DATE);
                  var insuranceDateDifference = (insuranceExpiry.getFullYear() - insuranceStart.getFullYear()) * 12;
                  insuranceDateDifference -= insuranceStart.getMonth();
                  insuranceDateDifference += insuranceExpiry.getMonth();
                  insuranceDateDifference = insuranceDateDifference <= 0 ? 0 : insuranceDateDifference;

                  if (insuranceDateDifference == 1) {
                    setinsTerm("1 month");
                  } else if (insuranceDateDifference == 2) {
                    setinsTerm("2 month");
                  } else if (insuranceDateDifference == 3) {
                    setinsTerm("3 month");
                  } else if (insuranceDateDifference == 6) {
                    setinsTerm("6 month");
                  } else if (insuranceDateDifference == 12) {
                    setinsTerm("Annual");
                  }
                }

                var insExpiryDate = new Date(data.insurancE_EXPIRY_DATE);
                if (data.insurancE_EXPIRY_DATE != null) {
                  var ddmmyyyinsExpiryDate =
                    insExpiryDate.getDate() +
                    "/" +
                    (insExpiryDate.getMonth() + 1) +
                    "/" +
                    insExpiryDate.getFullYear();
                  SetinsExpiryDate(ddmmyyyinsExpiryDate);
                } else {
                  SetinsExpiryDate("  ");
                }

                var v5cissueddate = new Date(data.datE_OF_LAST_V5C_ISSUED);
                if (data.datE_OF_LAST_V5C_ISSUED != null) {
                  var ddmmyyyisueDate =
                    v5cissueddate.getDate() +
                    "/" +
                    (v5cissueddate.getMonth() + 1) +
                    "/" +
                    v5cissueddate.getFullYear();
                  setlastV5cIssuedDate(ddmmyyyisueDate);
                } else {
                  setlastV5cIssuedDate("");
                }
                setIsLoading(false);
              }
              setIsLoading(false);
              SetopenVehicleDetailScreen(true);
            }
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      });
  };

  const refreshVehicleDetails = () => {
    var vehicleNo = props.obj.registertioN_NUMBER;
    var userId = props.obj.doC_USER_ID;
    axios
      .get("Vehicle/RefreshVehicle?registrationNumber=" + vehicleNo + "&userId=" + userId, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            const data = response.data.data;
            settaX_STATUS(data.taX_STATUS);
            setmoT_STATUS(data.moT_STATUS);

            if (props.freqType === "Days") {
              var days = parseInt(props.freqFrom);
              var hours = days * 24;
              setvehicleConfigrationFreqFrom(hours);
              vehicleConfigrationFreqFromvalue = hours;
            } else {
              vehicleConfigrationFreqFromvalue = parseInt(props.freqFrom);
              setvehicleConfigrationFreqFrom(parseInt(props.freqFrom));
            }
            if (
              checkuserFrequency(new Date(props.obj.lasT_UPDATED_ON), "Vehicle")
            ) {
              setrefreshButtonDisbled(true);
            }

            if (data.taX_DUE_DATE != null) {
              var taxDueDate = new Date(data.taX_DUE_DATE);
              var ddmmyyytaxDueDate =
                taxDueDate.getDate() +
                "/" +
                (taxDueDate.getMonth() + 1) +
                "/" +
                taxDueDate.getFullYear();
              settaX_DUE_DATE(ddmmyyytaxDueDate);
            } else {
              setvehicleDueDateNotAvailable(true);
            }

            if (data.moT_EXPIRY_DATE != null) {
              var motExpiryDate = new Date(data.moT_EXPIRY_DATE);
              var ddmmyyymotExpiryDate =
                motExpiryDate.getDate() +
                "/" +
                (motExpiryDate.getMonth() + 1) +
                "/" +
                motExpiryDate.getFullYear();
              setmoT_EXPIRY_DATE(ddmmyyymotExpiryDate);
            } else {
              setmotExpiryDateNotAvailable(true);
            }

            if (data.lasT_UPDATED_ON != null) {
              var lastupdate = new Date(data.lasT_UPDATED_ON);
              var lastupdateon =
                lastupdate.getDate() +
                "/" +
                (lastupdate.getMonth() + 1) +
                "/" +
                lastupdate.getFullYear();
              var time =
                lastupdate.getHours() +
                ":" +
                String(lastupdate.getMinutes()).padStart(2, "0") +
                ":" +
                String(lastupdate.getSeconds()).padStart(2, "0");

              //lastupdateon += " " + time;
              setlasT_UPDATED_ON(lastupdateon);

              setmotExpiryDate(lastupdateon);
            } else {
              setmotExpiryDateNotAvailable(true);
            }

            var motExpirycurrentDate = new Date();
            if (data.moT_EXPIRY_DATE != null) {
              var vehiclemotExpiryDate = new Date(data.moT_EXPIRY_DATE);
              if (motExpirycurrentDate > vehiclemotExpiryDate) {
                setshowMotInvalidDIV(true);
                setshowMotValidDiv(false);
              } else {
                setshowMotInvalidDIV(false);
                setshowMotValidDiv(true);
              }
            } else {
              setshowMotInvalidDIV(true);
              setshowMotValidDiv(false);
            }
            var taxCurrentDate = new Date();
            var vehicletaxDueDate = new Date(data.taX_DUE_DATE);
            if (data.taX_STATUS === "SORN") {
              setshowTaxSornValidDiv(true);
              setshowTaxValidDiv(false);
              setshowTaxInvalidDIV(false);
            } else {
              if (taxCurrentDate > vehicletaxDueDate) {
                setshowTaxInvalidDIV(true);
                setshowTaxSornValidDiv(false);
                setshowTaxValidDiv(false);
              } else {
                setshowTaxValidDiv(true);
                setshowTaxInvalidDIV(false);
                setshowTaxSornValidDiv(false);
              }
            }
          }
        }
      });
  };

  const checkuserFrequency = (lastupdateon: Date, mode: any) => {
    var hours = 0;
    if (mode === "Licence") {
      hours = vehicleConfigrationFreqFromvalue;
    } else {
      hours = vehicleConfigrationFreqFromvalue;
    }
    var today = new Date();
    var currentDateTimestamp = today.getTime();
    var lastUpdatedDateTimestamp = lastupdateon.getTime();

    if (
      Math.abs(currentDateTimestamp - lastUpdatedDateTimestamp) >
      60 * 60 * hours * 1000
    ) {
      //Within 24 hours
      return false;
    } else {
      return true;
    }
  };
  return (
    <div>
      <div>
        {createVehicleCard ? (
          <div className="divFlexClass">
            <TagCard
              id={"vehicleCard" + props.obj.registertioN_NUMBER}
              background-image="access"
            >
              <div>
                <div className="cod-header">
                  <div className={"flag-icon vehicalFlage" + props.obj.registertioN_NUMBER}>
                    <TagIcon
                      icon={`${url}DOC_Flags/${flagData}.png`}
                      iconStyle={{ height: "22px", width: "35px" }}
                      style={{ marginBlock: "0.5em", float: "left" }}
                    ></TagIcon>
                    <TagText
                      textStyle={{
                        fontSize: "17px",
                        marginBlock: "0",
                        paddingLeft: "40px",
                        paddingTop: "7px",
                        fontWeight: "600",
                      }}
                      text={
                        showVehicleType
                          ? "Vehicle Details " + vehicleType
                          : "Vehicle Details"
                      }
                    />
                  </div>
                  <TagTooltip
                    tooltipFor={'.vehicalFlage' + props.obj.registertioN_NUMBER}
                    header={flagName}
                    background='light'
                    position='left-end'>
                  </TagTooltip>
                  <div className="refresh-icon">
                    <TagButton
                      size="small"
                      id="refreshLicence"
                      accent="porcelain"
                      icon="Refresh"
                      icon-accent="black"
                      disabled={refreshbuttonDisbled}
                      button-style={{ height: "25px", width: "25px" }}
                      buttonIconStyle={{ height: "20px", width: "20px" }}
                      onButtonClick={onRefreshButtonClick}
                    />
                  </div>
                </div>
              </div>
              <div className="vehiclecardstyle">
                <Row className="row_details_Middle">
                  <Col sm={5} md={5} lg={5} style={{ paddingRight: "0" }}>
                    <div style={{ paddingLeft: "0", paddingTop: "2px" }}>
                      <table>
                        <tr>
                          <td colSpan={2} className="numberPlate-icon">
                            {" "}
                            <a
                              id="vehicalLink"
                              onClick={OnVehicleNoClick}
                              style={{ cursor: "pointer" }}
                            >
                              <span className="numberPlate">
                                {props.obj.registertioN_NUMBER}
                              </span>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ paddingTop: "5px" }}>
                            {showTaxValidDiv ? (
                              <div>
                                <div id={"vehicleTax" + props.obj.registertioN_NUMBER}
                                  className={"mot_Taxed_div vehicleTax" + props.obj.registertioN_NUMBER}>
                                  <div className="valid-icon">
                                    <tag-icon
                                      icon="Tick"
                                      accent="white"
                                      size="default"
                                      iconStyle={{ height: "20px", width: "20px" }}
                                    />
                                  </div>
                                  <div className="vehical_details_text">
                                    <span className="text-style">TAXED</span>
                                  </div>
                                </div>
                                <TagTooltip
                                  tooltipFor={'.vehicleTax' + props.obj.registertioN_NUMBER}
                                  header='Due Date'
                                  content={vehicletaxDueDateString}
                                  background='light'
                                  position='left-end'>
                                </TagTooltip>
                              </div>
                            ) : null}

                            {showTaxInvalidDIV ? (
                              <div>
                                <div
                                  id={"vehicleTax" + props.obj.registertioN_NUMBER}
                                  className={"mot_Taxed_INVALID_div vehicleTax" + props.obj.registertioN_NUMBER}>
                                  <div className="valid-icon">
                                    <tag-icon
                                      icon="Cross"
                                      accent="white"
                                      size="default"
                                      iconStyle={{ height: "20px", width: "20px" }}
                                    />
                                  </div>
                                  <div className="vehical_details_text">
                                    <span className="text-style">TAXED</span>
                                  </div>
                                </div>
                                {props.obj.taX_DUE_DATE != null ? (
                                  <TagTooltip
                                    tooltipFor={'.vehicleTax' + props.obj.registertioN_NUMBER}
                                    header={"Due Date"}
                                    content={vehicletaxDueDateString}
                                    background='light'
                                    position='left-end'>
                                  </TagTooltip>
                                ) : (
                                  <TagTooltip
                                    tooltipFor={'.vehicleTax' + props.obj.registertioN_NUMBER}
                                    header={vehicletaxDueDateString}
                                    background='light'
                                    position='left-end'>
                                  </TagTooltip>
                                )}
                              </div>
                            ) : null}

                            {showTaxSornValidDiv ? (
                              <div>
                                <div id={"vehicleTax" + props.obj.registertioN_NUMBER}
                                  className={"mot_Taxed_INVALID_div vehicleTax" + props.obj.registertioN_NUMBER}>
                                  <div className="valid-icon">
                                    <tag-icon
                                      icon="Cross"
                                      accent="white"
                                      size="default"
                                      iconStyle={{ height: "20px", width: "20px" }}
                                    />
                                  </div>
                                  <div className="vehical_details_text">
                                    <span className="text-style">SORN</span>
                                  </div>
                                </div>
                                {props.obj.taX_DUE_DATE != null ? (
                                  <TagTooltip
                                    tooltipFor={'.vehicleTax' + props.obj.registertioN_NUMBER}
                                    header={"Due Date"}
                                    content={vehicletaxDueDateString}
                                    background='light'
                                    position='left-end'>
                                  </TagTooltip>
                                ) : (
                                  <TagTooltip
                                    tooltipFor={'.vehicleTax' + props.obj.registertioN_NUMBER}
                                    header={vehicletaxDueDateString}
                                    background='light'
                                    position='left-end'>
                                  </TagTooltip>
                                )}
                              </div>
                            ) : null}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ paddingTop: "5px" }}>
                            {showMotValidDiv ? (
                              <div>
                                <div id={"vehicleMOT" + props.obj.registertioN_NUMBER}
                                  className={"mot_Taxed_div vehicleMOT" + props.obj.registertioN_NUMBER}>
                                  <div className="valid-icon">
                                    <tag-icon
                                      icon="Tick"
                                      accent="white"
                                      size="default"
                                      iconStyle={{ height: "20px", width: "20px" }}
                                    />
                                  </div>
                                  <div className="vehical_details_text">
                                    <span className="text-style">MOT</span>
                                  </div>
                                </div>
                                <TagTooltip
                                  tooltipFor={'.vehicleMOT' + props.obj.registertioN_NUMBER}
                                  header='Due Date'
                                  content={vehiclemotExpiryDateString}
                                  background='light'
                                  position='left-end'>
                                </TagTooltip>
                              </div>
                            ) : null}

                            {showMotInvalidDIV ? (
                              <div>
                                <div id={"vehicleMOT" + props.obj.registertioN_NUMBER}
                                  className={"access vehicleMOT" + props.obj.registertioN_NUMBER}>
                                  <div className="valid-icon">
                                    <tag-icon
                                      icon="Cross"
                                      accent="white"
                                      size="default"
                                      iconStyle={{ height: "20px", width: "20px" }}
                                    />
                                  </div>
                                  <div className="vehical_details_text">
                                    <span className="text-style">MOT</span>
                                  </div>
                                </div>
                                {props.obj.moT_EXPIRY_DATE != null ? (
                                  <TagTooltip
                                    tooltipFor={'.vehicleMOT' + props.obj.registertioN_NUMBER}
                                    header='Due Date'
                                    content={vehiclemotExpiryDateString}
                                    background='light'
                                    position='left-end'>
                                  </TagTooltip>
                                ) : (
                                  <TagTooltip
                                    tooltipFor={'.vehicleMOT' + props.obj.registertioN_NUMBER}
                                    header={vehiclemotExpiryDateString}
                                    background='light'
                                    position='left-end'>
                                  </TagTooltip>
                                )}
                              </div>
                            ) : null}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ paddingTop: "5px", paddingBottom: "7px" }}>
                            {showInsuranceValidDiv ? (
                              <div>
                                <div id={"vehicleInsurance" + props.obj.registertioN_NUMBER}
                                  className={"mot_Taxed_div vehicleInsurance" + props.obj.registertioN_NUMBER}>
                                  <div className="valid-icon">
                                    <tag-icon
                                      icon="Tick"
                                      accent="white"
                                      size="default"
                                      iconStyle={{ height: "20px", width: "20px" }}
                                    />
                                  </div>
                                  <div className="vehical_details_text">
                                    <span className="text-style">INSURANCE</span>
                                  </div>
                                </div>
                                <TagTooltip
                                  tooltipFor={'.vehicleInsurance' + props.obj.registertioN_NUMBER}
                                  header='Due Date'
                                  content={vehicleInsuranceEndDateString}
                                  background='light'
                                  position='left-end'>
                                </TagTooltip>
                              </div>
                            ) : null}

                            {showInsuranceInvalidDiv ? (
                              <div>
                                <div id={"vehicleInsurance" + props.obj.registertioN_NUMBER}
                                  className={"access vehicleInsurance" + props.obj.registertioN_NUMBER}>
                                  <div className="valid-icon">
                                    <tag-icon
                                      icon="Cross"
                                      accent="white"
                                      size="default"
                                      iconStyle={{ height: "20px", width: "20px" }}
                                    />
                                  </div>
                                  <div className="vehical_details_text">
                                    <span className="text-style">INSURANCE</span>
                                  </div>
                                </div>
                                {props.obj.insurancE_EXPIRY_DATE != null ? (
                                  <TagTooltip
                                    tooltipFor={'.vehicleInsurance' + props.obj.registertioN_NUMBER}
                                    header='Due Date'
                                    content={vehicleInsuranceEndDateString}
                                    background='light'
                                    position='left-end'>
                                  </TagTooltip>
                                ) : (
                                  <TagTooltip
                                    tooltipFor={'.vehicleInsurance' + props.obj.registertioN_NUMBER}
                                    header={vehicleInsuranceEndDateString}
                                    background='light'
                                    position='left-end'>
                                  </TagTooltip>
                                )}
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </Col>
                  <Col sm={7} md={7} lg={7}>
                    <table>
                      {vehicleDueDateNotAvailable ? (
                        <tr>
                          <td className="w_20">
                            <tag-field
                              name="veh-TaxDueDate"
                              data-type="string"
                              label="Tax Due Date:"
                              value="Not Available"
                              container-style={containerstyle}
                              label-style={labelstyle}
                              value-style={valuestyle}
                            ></tag-field>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className="w_20">
                            <tag-field
                              name="veh-TaxDueDate"
                              data-type="date"
                              label="Tax Due Date:"
                              value={taX_DUE_DATE}
                              container-style={containerstyle}
                              label-style={labelstyle}
                              value-style={valuestyle}
                            ></tag-field>
                          </td>
                        </tr>
                      )}

                      {motExpiryDateNotAvailable ? (
                        <tr>
                          <td className="w_20">
                            <tag-field
                              name="veh-MotExpiryDate"
                              data-type="string"
                              label="Mot Expiry Date:"
                              value="Not Available"
                              container-style={containerstyle}
                              label-style={labelstyle}
                              value-style={valuestyle}
                            ></tag-field>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className="w_20">
                            <tag-field
                              name="veh-MotExpiryDate"
                              data-type="date"
                              label="Mot Expiry Date:"
                              value={moT_EXPIRY_DATE}
                              container-style={containerstyle}
                              label-style={labelstyle}
                              value-style={valuestyle}
                            ></tag-field>
                          </td>
                        </tr>
                      )}

                      {insuranceExpiryDateNotAvailable ? (
                        <tr>
                          <td className="w_20">
                            <tag-field
                              name="veh-InsuranceExpiryDate"
                              data-type="string"
                              label="Insurance End Date:"
                              value="Not Available"
                              container-style={containerstyle}
                              label-style={labelstyle}
                              value-style={valuestyle}
                            ></tag-field>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className="w_20">
                            <tag-field
                              name="veh-InsuranceExpiryDate"
                              data-type="date"
                              label="Insurance End Date:"
                              value={insurancE_EXPIRY_DATE}
                              container-style={containerstyle}
                              label-style={labelstyle}
                              value-style={valuestyle}
                            ></tag-field>
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td className="w_20">
                          <tag-field
                            name="lic-m-lastUpdateOn"
                            data-type="date"
                            label="Last Updated:"
                            value={lasT_UPDATED_ON}
                            container-style={container_style_last}
                            label-style={labelstyle}
                            value-style={valuestyle}
                          ></tag-field>
                        </td>
                      </tr>
                    </table>
                  </Col>
                </Row>
              </div>
            </TagCard>
          </div >
        ) : (
          <div>
            <div className="divFlexClass">
              <TagCard
                id={"vehicleCard" + props.obj.registertioN_NUMBER}
                background-image="access"
              >
                <div>
                  <div className="cod-header">
                    <div className={"flag-icon vehicalFlage" + props.obj.registertioN_NUMBER}>
                      <TagIcon
                        icon={`${url}DOC_Flags/${flagData}.png`}
                        iconStyle={{ height: "24px", width: "35px" }}
                        style={{ marginBlock: "0.5em", float: "left" }}
                      ></TagIcon>
                      <TagText
                        textStyle={{
                          fontSize: "17px",
                          marginBlock: "0",
                          paddingLeft: "40px",
                          paddingTop: "7px",
                          fontWeight: "600",
                        }}
                        text={
                          showVehicleType
                            ? "Vehicle Details " + vehicleType
                            : "Vehicle Details"
                        }
                      />
                    </div>
                    <TagTooltip
                      tooltipFor={'.vehicalFlage' + props.obj.registertioN_NUMBER}
                      header={flagName}
                      background='light'
                      position='left-end'>
                    </TagTooltip>
                    {/* <div className="refresh-icon">
                    <TagButton
                      size="small"
                      id="refreshLicence"
                      accent="porcelain"
                      icon="Refresh"
                      icon-accent="black"
                      disabled={true}
                      button-style={{ height: "25px", width: "25px" }}
                      buttonIconStyle={{ height: "20px", width: "20px" }}
                      onButtonClick={onRefreshButtonClick}
                    />
                  </div> */}
                  </div>
                  <div className="vehiclecardstyle">
                    <Row className="row_details_Middle">
                      <Col sm={5} md={5} lg={5} style={{ paddingRight: "0" }}>
                        <div style={{ paddingLeft: "0", paddingTop: "2px" }}>
                          <table>
                            <tr>
                              <td colSpan={2} className="numberPlate-icon">
                                <a
                                  id="vehicalLink"
                                  onClick={OnVehicleNoClick}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span className="numberPlate">
                                    {props.obj.registertioN_NUMBER}
                                  </span>
                                </a>
                              </td>
                            </tr>

                            <tr>
                              <td style={{ paddingTop: "5px" }}>
                                {showTaxValidDiv ? (
                                  <div>
                                    <div id={"vehicleTAX" + props.obj.registertioN_NUMBER}
                                      className={"mot_Taxed_div vehicleTax" + props.obj.registertioN_NUMBER}>
                                      <div className="valid-icon">
                                        <tag-icon
                                          icon="Tick"
                                          accent="white"
                                          size="default"
                                          iconStyle={{ height: "20px", width: "20px" }}
                                        />
                                      </div>
                                      <div className="vehical_details_text">
                                        <span className="text-style">TAXED</span>
                                      </div>
                                    </div>
                                    <TagTooltip
                                      tooltipFor={'.vehicleTax' + props.obj.registertioN_NUMBER}
                                      header='Due Date'
                                      content={vehicletaxDueDateString}
                                      background='light'
                                      position='left-end'>
                                    </TagTooltip>
                                  </div>
                                ) : null}

                                {showTaxInvalidDIV ? (
                                  <div>
                                    <div id={"vehicleMOT" + props.obj.registertioN_NUMBER}
                                      className={"mot_Taxed_INVALID_div vehicleTax" + props.obj.registertioN_NUMBER}>
                                      <div className="valid-icon">
                                        <tag-icon
                                          icon="Cross"
                                          accent="white"
                                          size="default"
                                          iconStyle={{ height: "20px", width: "20px" }}
                                        />
                                      </div>
                                      <div className="vehical_details_text">
                                        <span className="text-style">TAXED</span>
                                      </div>
                                    </div>
                                    {props.obj.taX_DUE_DATE != null ? (
                                      <TagTooltip
                                        tooltipFor={'.vehicleTax' + props.obj.registertioN_NUMBER}
                                        header={"Due Date"}
                                        content={vehicletaxDueDateString}
                                        background='light'
                                        position='left-end'>
                                      </TagTooltip>
                                    ) : (
                                      <TagTooltip
                                        tooltipFor={'.vehicleTax' + props.obj.registertioN_NUMBER}
                                        header={vehicletaxDueDateString}
                                        background='light'
                                        position='left-end'>
                                      </TagTooltip>
                                    )}
                                  </div>
                                ) : null}

                                {showTaxSornValidDiv ? (
                                  <div>
                                    <div id={"vehicleMOT" + props.obj.registertioN_NUMBER}
                                      className={"mot_Taxed_INVALID_div vehicleTax" + props.obj.registertioN_NUMBER}>
                                      <div className="valid-icon">
                                        <tag-icon
                                          icon="Tick"
                                          accent="white"
                                          size="default"
                                          iconStyle={{ height: "20px", width: "20px" }}
                                        />
                                      </div>
                                      <div className="vehical_details_text">
                                        <span className="text-style">SORN</span>
                                      </div>
                                    </div>
                                    {props.obj.taX_DUE_DATE != null ? (
                                      <TagTooltip
                                        tooltipFor={'.vehicleTax' + props.obj.registertioN_NUMBER}
                                        header={"Due Date"}
                                        content={vehicletaxDueDateString}
                                        background='light'
                                        position='left-end'>
                                      </TagTooltip>
                                    ) : (
                                      <TagTooltip
                                        tooltipFor={'.vehicleTax' + props.obj.registertioN_NUMBER}
                                        header={vehicletaxDueDateString}
                                        background='light'
                                        position='left-end'>
                                      </TagTooltip>
                                    )}
                                  </div>
                                ) : null}
                              </td>
                            </tr>

                            <tr>
                              <td style={{ paddingTop: "5px" }}>
                                {showMotValidDiv ? (
                                  <div>
                                    <div id={"vehicleMOT" + props.obj.registertioN_NUMBER}
                                      className={"mot_Taxed_div vehicleMOT" + props.obj.registertioN_NUMBER}>
                                      <div className="valid-icon">
                                        <tag-icon
                                          icon="Tick"
                                          accent="white"
                                          size="default"
                                          iconStyle={{ height: "20px", width: "20px" }}
                                        />
                                      </div>
                                      <div className="vehical_details_text">
                                        <span className="text-style">MOT</span>
                                      </div>
                                    </div>
                                    <TagTooltip
                                      tooltipFor={'.vehicleMOT' + props.obj.registertioN_NUMBER}
                                      header='Due Date'
                                      content={vehiclemotExpiryDateString}
                                      background='light'
                                      position='left-end'>
                                    </TagTooltip>
                                  </div>
                                ) : null}

                                {showMotInvalidDIV ? (
                                  <div>
                                    <div id={"vehicleMOT" + props.obj.registertioN_NUMBER}
                                      className={"access vehicleMOT" + props.obj.registertioN_NUMBER}>
                                      <div className="valid-icon">
                                        <tag-icon
                                          icon="Cross"
                                          accent="white"
                                          size="default"
                                          iconStyle={{ height: "20px", width: "20px" }}
                                        />
                                      </div>
                                      <div className="vehical_details_text">
                                        <span className="text-style">MOT</span>
                                      </div>
                                    </div>
                                    {props.obj.moT_EXPIRY_DATE != null ? (
                                      <TagTooltip
                                        tooltipFor={'.vehicleMOT' + props.obj.registertioN_NUMBER}
                                        header={"Due Date"}
                                        content={vehiclemotExpiryDateString}
                                        background='light'
                                        position='left-end'>
                                      </TagTooltip>
                                    ) : (
                                      <TagTooltip
                                        tooltipFor={'.vehicleMOT' + props.obj.registertioN_NUMBER}
                                        header={vehiclemotExpiryDateString}
                                        background='light'
                                        position='left-end'>
                                      </TagTooltip>
                                    )}
                                  </div>
                                ) : null}
                              </td>
                            </tr>

                            <tr>
                              <td style={{ paddingTop: "5px", paddingBottom: "7px" }}>
                                {showInsuranceValidDiv ? (
                                  <div>
                                    <div id={"vehicleINSURANCE" + props.obj.registertioN_NUMBER}
                                      className={"mot_Taxed_div vehicleINSURANCE" + props.obj.registertioN_NUMBER}>
                                      <div className="valid-icon">
                                        <tag-icon
                                          icon="Tick"
                                          accent="white"
                                          size="default"
                                          iconStyle={{ height: "20px", width: "20px" }}
                                        />
                                      </div>
                                      <div className="vehical_details_text">
                                        <span className="text-style">INSURANCE</span>
                                      </div>
                                    </div>
                                    <TagTooltip
                                      tooltipFor={'.vehicleINSURANCE' + props.obj.registertioN_NUMBER}
                                      header='Due Date'
                                      content={vehicleInsuranceEndDateString}
                                      background='light'
                                      position='left-end'>
                                    </TagTooltip>
                                  </div>
                                ) : null}

                                {showInsuranceInvalidDiv ? (
                                  <div>
                                    <div id={"vehicleINSURANCE" + props.obj.registertioN_NUMBER}
                                      className={"access vehicleINSURANCE" + props.obj.registertioN_NUMBER}>
                                      <div className="valid-icon">
                                        <tag-icon
                                          icon="Cross"
                                          accent="white"
                                          size="default"
                                          iconStyle={{ height: "20px", width: "20px" }}
                                        />
                                      </div>
                                      <div className="vehical_details_text">
                                        <span className="text-style">INSURANCE</span>
                                      </div>
                                    </div>

                                    {props.obj.insurancE_EXPIRY_DATE != null ? (
                                      <TagTooltip
                                        tooltipFor={'.vehicleINSURANCE' + props.obj.registertioN_NUMBER}
                                        header={"Due Date"}
                                        content={vehicleInsuranceEndDateString}
                                        background='light'
                                        position='left-end'>
                                      </TagTooltip>
                                    ) : (
                                      <TagTooltip
                                        tooltipFor={'.vehicleINSURANCE' + props.obj.registertioN_NUMBER}
                                        header={vehicleInsuranceEndDateString}
                                        background='light'
                                        position='left-end'>
                                      </TagTooltip>
                                    )}
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </Col>

                      <Col sm={7} md={7} lg={7}>
                        <table>
                          {vehicleDueDateNotAvailable ? (
                            <tr>
                              <td className="w_20">
                                <tag-field
                                  name="veh-TaxDueDate"
                                  data-type="string"
                                  label="Tax Due Date:"
                                  value="Not Available"
                                  container-style={containerstyle}
                                  label-style={labelstyle}
                                  value-style={valuestyle}
                                ></tag-field>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td className="w_20">
                                <tag-field
                                  name="veh-TaxDueDate"
                                  data-type="date"
                                  label="Tax Due Date:"
                                  value={taX_DUE_DATE}
                                  container-style={containerstyle}
                                  label-style={labelstyle}
                                  value-style={valuestyle}
                                ></tag-field>
                              </td>
                            </tr>
                          )}

                          {motExpiryDateNotAvailable ? (
                            <tr>
                              <td className="w_20">
                                <tag-field
                                  name="veh-MotExpiryDate"
                                  data-type="string"
                                  label="MOT Expiry Date:"
                                  value="Not Available"
                                  container-style={containerstyle}
                                  label-style={labelstyle}
                                  value-style={valuestyle}
                                ></tag-field>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td className="w_20">
                                <tag-field
                                  name="veh-MotExpiryDate"
                                  data-type="date"
                                  label="MOT Expiry Date:"
                                  value={moT_EXPIRY_DATE}
                                  container-style={containerstyle}
                                  label-style={labelstyle}
                                  value-style={valuestyle}
                                ></tag-field>
                              </td>
                            </tr>
                          )}

                          {insuranceExpiryDateNotAvailable ? (
                            <tr>
                              <td className="w_20">
                                <tag-field
                                  name="veh-InsuranceExpiryDate"
                                  data-type="string"
                                  label="Insurance End Date:"
                                  value="Not Available"
                                  container-style={containerstyle}
                                  label-style={labelstyle}
                                  value-style={valuestyle}
                                ></tag-field>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td className="w_20">
                                <tag-field
                                  name="veh-InsuranceExpiryDate"
                                  data-type="date"
                                  label="Insurance End Date:"
                                  value={insurancE_EXPIRY_DATE}
                                  container-style={containerstyle}
                                  label-style={labelstyle}
                                  value-style={valuestyle}
                                ></tag-field>
                              </td>
                            </tr>
                          )}

                          {createManuallyVehicleCardForUk ? (
                            <tr>
                              <td className="w_20">
                                <tag-field
                                  name="reason"
                                  label="Reason:"
                                  value={manuallyAddVehicleReason}
                                  container-style={containerstyle}
                                  label-style={labelstyle}
                                  value-style={reason_value_style}
                                ></tag-field>
                              </td>
                            </tr>
                          ) :
                            null
                          }

                          <tr>
                            <td className="w_20">
                              <tag-field
                                name="lic-m-lastUpdateOn"
                                data-type="date"
                                label="Last Updated:"
                                value={lasT_UPDATED_ON}
                                container-style={containerstyle}
                                label-style={labelstyle}
                                value-style={valuestyle}
                              ></tag-field>
                            </td>
                          </tr>
                        </table>
                      </Col>
                    </Row>
                  </div>
                </div>
              </TagCard>
            </div>
          </div>
        )}

        {
          <div>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              openVehicleDetailScreen ? (
                <VehicleDetails
                  vehicleDetailsData={vehicleDetailsData}
                  openVehicleDetailScreen={openVehicleDetailScreen}
                  SetopenVehicleDetailScreen={SetopenVehicleDetailScreen}
                  isLoading={isLoading}
                  makeforExport={makeforExport}
                  taxDueDate={taxDueDate}
                  motExpiryDate={motExpiryDate}
                  insStartDate={insStartDate}
                  insTerm={insTerm}
                  insExpiryDate={insExpiryDate}
                  lastV5cIssuedDate={lastV5cIssuedDate}
                />
              ) : null
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default Vehicles;