/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-undef */

import { TagChart, TagH2, TagH5, TagIcon } from "@tag/tag-components-react-v3";
import axios from "axios";
import React from "react";
import { HttpHeaders } from "../shared/HttpHeaders";
import "../assets/css/common.scss";
import "../assets/css/chart.scss";
import ChartTable from "./ChartTable";

import { IChartData } from "../core/view-Model/chart.model";
import User from "../components/User";

function Chart({
  chartName,
  chartAPIName,
  chartDetailAPIName,
  openDashboardScreen,
  SetopenDashboardScreen,
  fromDashBoard
}: any) {
  const Headers = HttpHeaders();
  const [showChart, setshowChart] = React.useState(false);
  const [chartData, setchartData] = React.useState<IChartData[]>([]);
  const [legendvalues, setlegendvalues] = React.useState<string[]>([]);
  const colorvalues = ["#E5173F", "#68B590", "#D5763F"];
  const [openFilterUserScreen, setopenFilterUserScreen] = React.useState(false);

  const onChartClick = (e: any) => {
    const value = e.detail;
    if (value.seriesName == "Driving Licence") {
      SetopenDashboardScreen(false);
      fromDashBoard("Driving Licence", "", value.data["levelHigh"], value.data["levelLow"]);
    } else if (value.seriesName == "Tax Due") {
      SetopenDashboardScreen(false);
      fromDashBoard("Tax Due", "", value.data["levelHigh"], value.data["levelLow"]);
    } else if (value.seriesName == "MOT") {
      SetopenDashboardScreen(false);
      fromDashBoard("Mot", "", value.data["levelHigh"], value.data["levelLow"]);
    } else if (value.seriesName == "Insurance") {
      SetopenDashboardScreen(false);
      fromDashBoard("Insurance", "", value.data["levelHigh"], value.data["levelLow"]);
    }
  };

  React.useEffect(() => {
    bindChartData();
  }, []);

  const bindChartData = () => {
    setchartData([]);
    axios
      .get(`Admin/` + chartAPIName, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setchartData(response.data.data);
                const legendValuesArray: string[] = [];
                for (let i = 0; i < response.data.data.length; i++) {
                  legendValuesArray.push(response.data.data[i]["name"]);
                  if (response.data.data[i]["value"] > 0) {
                    setshowChart(true);
                  }
                }
                setlegendvalues(legendValuesArray);
              }
            }
          }
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind chart data!");
      });
  };

  return (
    <div>
      {openDashboardScreen ?
        <div>
          {showChart ? (
            <>
              <TagChart
                height="250px"
                width="350px"
                onChartClick={onChartClick}
                options={{
                  shadow: true,
                  tooltip: {
                    trigger: "item",
                    formatter: "{b} <br/> {c} ({d}%)",
                    textStyle: {
                      fontSize: 10,
                    },
                  },
                  title: {
                    top: 10,
                    text: chartName,
                    left: "center",
                    textStyle: {
                      fontSize: 15,
                      color: "#666666",
                      fontWeight: 700,
                    },
                  },
                  legend: {
                    bottom: 10,
                    left: "center",
                    data: legendvalues,
                    textStyle: {
                      fontSize: 10,
                    },
                  },
                  color: colorvalues,
                  series: [
                    {
                      name: chartName,
                      type: "pie",
                      radius: ["35%", "65%"],
                      avoidLabelOverlap: false,
                      labelLine: {
                        length: 20,
                      },
                      emphasis: {
                        itemStyle: {
                          borderWidth: 0,
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                      },
                      label: {
                        formatter: "{c}",
                        position: "inside",
                      },
                      data: chartData,
                    },
                  ],
                }}
              />
              <ChartTable apiName={chartDetailAPIName} />
            </>
          ) : (
            <>
              <div className="chartGrid">
                <TagH5 className="chartName">{chartName}</TagH5>
                <div className="chartTable ">
                  <TagIcon icon="NoEntry" size={100} accent="porcelain"></TagIcon>
                  <TagH2>No items available</TagH2>
                </div>
              </div>
            </>
          )}
        </div> : null}
      {openFilterUserScreen ? (< User />) : null}
    </div>
  );
}
export default Chart;
