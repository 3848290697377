/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AlertType,
  TagAlert,
  TagConfirm,
  TagToast,
  ToastPosition,
} from "@tag/tag-components-react-v2";
import React, { useState, useEffect } from "react";

import event from "./eventBus";

export const Notify = (callBackMain: any) => {
  const [content, setContent] = useState(""); // Content display in dialogue box
  const [showModal, setShowModal] = useState(false); // confirmation box show/hide

  const [modelType, setModelType] = useState<AlertType>("information"); //Type like information, danger, warning,  question
  const [messageHeading, setMessageHeading] = useState("test"); // Content display in dialogue box header

  const [cancelButton, setCancelButton] = useState(""); // cancel button show in dialogue box, if this is blank than cancel button will not display
  const [isShowModalType, setIsshowModalType] = useState(false); // confirmation box show/hide

  const [showAlertPositon, setShowAlertPositon] =
    useState<ToastPosition>("bottom-left"); // confirmation box show/hide
  const [showDuration, setShowDuration] = useState<number>(5000); // confirmation box show/hide
  const [uniqueID, setUniqueId] = useState<string>(""); // confirmation box show/hide, If yes than confirmation dialogue appear else alert dialogue appear
  const [primaryButtonText, setPrimaryButtonText] = useState<string>("Confirm");
  const [notifyModelOpenFrom, setnotifyModelOpenFrom] =
    React.useState<string>("timeline");

  const setMessage = (
    message: any,
    dialogueType: any,
    uniqueId: any,
    modelType: AlertType,
    messageHeader: any,
    durationVal: number,
    toastPosition: ToastPosition,
    cancelButtonText: string,
    primaryButtonText: string,
    notifyModelOpenFrom: string
  ) => {
    setContent(message);
    setShowModal(true);
    setIsshowModalType(dialogueType);
    setUniqueId(uniqueId);
    setModelType(modelType);
    setMessageHeading(messageHeader);
    setCancelButton(cancelButtonText);
    if (!dialogueType) {
      setShowModal(false);
      setShowDuration(durationVal);
      setShowAlertPositon(toastPosition);
      setShowModal(true);
    }
    setnotifyModelOpenFrom(notifyModelOpenFrom);
    setPrimaryButtonText(primaryButtonText);
  };
  const clearMessage = () => {
    setContent("");
    setShowModal(false);
    if (notifyModelOpenFrom !== "")
      callBackMain.callBackMain(notifyModelOpenFrom + "Open");
  };
  useEffect(() => {
    event.on("showModal", setMessage).on("clearAllMessage", clearMessage);
  }, [clearMessage]);


  if (showModal) {
  return (
    <>
      <TagToast position={showAlertPositon}>
        <TagAlert
          type={modelType}
          text={content}
          showDuration={showDuration}
          alertType="toast"
          closeable
        ></TagAlert>
      </TagToast>
    </>
  );
};
return null;
}



