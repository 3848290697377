import {
    TagButton,
    TagConfirm,
    TagTable,
    TagModal,
} from "@tag/tag-components-react-v2";
import React from "react";
import { useState } from "react";
import LoadingSpinner from "../LoadingSpinner";

function ManualLicenceVehicleHistory({
    openLicenceHistoryScreen,
    licenceHistoryDetailsData,
    SetopenLicenceHistoryScreen,
    fromLicenceHistoryScreen,
    SetshowManualLicenceHistory,
    LicenceVehicleHistoryPopUp
}: any) {
    const [showPopup, setshowPopup] = useState(false);
    const OnOpenedPopup = (e: any) => { setshowPopup(false); };
    const [isLoading, setIsLoading] = React.useState(false);

    const onCancelButtonClick = (e: any) => {
        SetopenLicenceHistoryScreen(false);
        SetshowManualLicenceHistory(false)
        setshowPopup(true);
    };

    const data = [];
    for (let i = 0; i < licenceHistoryDetailsData.length; i++) {
        let performingDate = new Date(licenceHistoryDetailsData[i].doC_DVLA_MAH_PERFORMING_DATE).getDate() + "-" + (new Date(licenceHistoryDetailsData[i].doC_DVLA_MAH_PERFORMING_DATE).getMonth() + 1) + "-" + new Date(licenceHistoryDetailsData[i].doC_DVLA_MAH_PERFORMING_DATE).getFullYear();
        data.push({
            userName: licenceHistoryDetailsData[i].doC_DVLA_MAH_PERFORMING_USER_NAME == null ? '-' : licenceHistoryDetailsData[i].doC_DVLA_MAH_PERFORMING_USER_NAME,
            rejectReason: licenceHistoryDetailsData[i].doC_DVLA_MAH_REJECTION_REASON == "" ? '-' : licenceHistoryDetailsData[i].doC_DVLA_MAH_REJECTION_REASON,
            status: licenceHistoryDetailsData[i].doC_DVLA_MAH_MODE == 0 ? "Reject" : "Verified",
            performingDate: performingDate
        });
    }

    return (
        <>
            <div>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <TagModal
                        style={{ minWidth: "500px" }}
                        visible={openLicenceHistoryScreen}
                        heading={fromLicenceHistoryScreen == true ? "Licence History Details" : "Vehicle History Details"}
                        borderAccent="viridiangreen"
                        onOpened={OnOpenedPopup}
                        onClosed={() => {
                            SetopenLicenceHistoryScreen(false);
                            setshowPopup(false);
                        }}
                    >
                        <TagTable
                            height='300px'
                            tableStyle={{ minWidth: "500px" }}
                            tableContainerStyle={{ width: "755px" }}
                            columns={[
                                { name: 'userName', caption: 'Performing User' },
                                { name: 'status', caption: 'Status', cellStyle: { minWidth: '80px' } },
                                { name: 'rejectReason', caption: 'Reject Reason' },
                                { name: 'performingDate', caption: 'Performing Date', cellStyle: { minWidth: '80px' } },
                            ]}
                            data={data} />
                        <div className="footer">
                            <div className="bLeft">
                                <TagButton
                                    size="large"
                                    text="Cancel"
                                    className="cancel"
                                    style={{ marginTop: "10px" }}
                                    onButtonClick={onCancelButtonClick} />
                            </div>
                        </div>
                    </TagModal>
                )}
            </div>
            <div>
                {LicenceVehicleHistoryPopUp == "true" ? (
                    <TagConfirm
                        visible
                        width="small"
                        heading="Information"
                        cancelButton="Cancel"
                        primaryButton=""
                        type="information"
                        onCancelButtonClick={onCancelButtonClick}
                        text={fromLicenceHistoryScreen == true ? "There is no history for this licence." : "There is no history for this vehicle."} />
                ) : null}
            </div>
        </>
    );
}
export default ManualLicenceVehicleHistory;