import event from "./eventBus";
import { ToastPosition } from "@tag/tag-components-react-v2";

export const NotifyPublisher = {
  message: (content:any, dialogueType:any, uniqueId:any, modelType:any, messageHeader: any, cancelButtonText: string = '', primaryButtonText: string = 'Confirm', notifyModelOpenFrom = '', durationVal: number = 5000, toastPosition: ToastPosition = 'bottom-right' ) => {
    event.emit("showModal", content, dialogueType, uniqueId, modelType, messageHeader, durationVal, toastPosition, cancelButtonText, primaryButtonText, notifyModelOpenFrom);
  },
  clearAllMessage: () => {
    event.emit("clearAllMessage");
  }
};
