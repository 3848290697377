/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  TagIconSvg,
  TagSideNav,
  TagSideNavBody,
  TagSideNavHeader,
  TagSideNavItem,
} from "@tag/tag-components-react-v3";
import React, { useState } from "react";
import User from "./User";
import Approval from "./Approval";
import Configuration from "./Configuration";
import Dashboard from "./Dashboard";
import Notification from "./Notification";
import "../assets/css/menu.scss";
import "../assets/css/common.scss";
import ArchivedUsers from "./ArchivedUsers";
import axios from "axios";
import { useIdentity } from "@workspace/identity-react";
import { PidgetSettings } from "../PidgetSettings";
import { Notify } from "./Notify/Notify";
import { useIframePidgetSettings } from "@workspace/utils-react/dist/iframePidget/useIframePidgetSettings";
import { useIframePidgetInstance } from '@workspace/utils-react';

const Menu = () => {
  const [openUserScreen, SetopenUserScreen] = useState(false);
  const [openApprovalScreen, SetopenApprovalScreen] = useState(false);
  const [openConfigurationScreen, SetopenConfigurationScreen] = useState(false);
  const [openDashboardScreen, SetopenDashboardScreen] = useState(true);
  const [openNotificationScreen, SetopenNotificationScreen] = useState(false);
  const [openArchivedUsersScreen, SetopenArchivedUsersScreen] = useState(false);
  const [chartName, SetchartName] = useState("");
  const [chartValue, SetchartValue] = useState("");
  const [chartlevelHigh, SetchartlevelHigh] = useState("");
  const [chartlevelLow, SetchartlevelLow] = useState("");
  const [selectedItem, setSelectedItem] = useState("Dashboard");
  const [userStatus, setuserStatus] = useState("");
  const collapsedValue: boolean = true;

  // get the API url and instanceId so we can globally setup axios
  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  const { instanceId: instanceId } = useIframePidgetInstance();
  const { getAccessToken } = useIdentity();
  axios.defaults.baseURL = url;
  axios.interceptors.request.use(
    async (req) => {
      if (!req) {
        req = {};
      }
      if (!req.headers) {
        req.headers = {};
      }
      const accessToken = await getAccessToken();
      req.headers['Authorization'] = 'Bearer ' + accessToken;
      req.headers['X-Instance-Id'] = instanceId;
      req.baseURL = url;
      return req;

      // request.headers = {
      //   Authorization: "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IkQ5QTg4QzhCRkY4OTk0NEI0MjNDMzIxQjE3MTU5QzkwIiwidHlwIjoiSldUIn0.eyJpc3MiOiJodHRwczovL3ByZXByb2RpZGVudGl0eS5hY2Nlc3NhY2xvdWQuY29tIiwibmJmIjoxNjY4NTc0NDQ0LCJpYXQiOjE2Njg1NzQ0NDQsImV4cCI6MTY2ODU3ODA0NCwiYXVkIjpbImFjY2Vzcy53aWRnZXQuZGlzY292ZXJ5IiwiYWNjZXNzLndpZGdldC5hcGkiLCJodHRwczovL3ByZXByb2RpZGVudGl0eS5hY2Nlc3NhY2xvdWQuY29tL3Jlc291cmNlcyJdLCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJwb2xpY3kiLCJhY2Nlc3Mud2lkZ2V0LmRpc2NvdmVyeSIsImFjY2Vzcy53aWRnZXQuYXBpIl0sImFtciI6WyJwd2QiXSwiY2xpZW50X2lkIjoiYWNjZXNzLndkay5kYXNoYm9hcmQiLCJhY3RwIjoiYWNsIiwic3ViIjoiMDFjODRhYmQtMTdkOC00NGMzLTlkMWEtNWEzMTk3Y2Y4NDg0IiwiYXV0aF90aW1lIjoxNjY4NTczNzE5LCJpZHAiOiJsb2NhbCIsImVtYWlsIjoiYWRtaW5ARlBJbmRpYS5jb20iLCJzZSI6dHJ1ZSwic2wiOjI4ODAwLCJzcCI6IjVmOGZmNDgyLTgxMTctNDJjYS05MjMyLTZiOTMzZWE5NmFkNSIsInN0IjoiREVlYm90dzdKMkpNZ2N5aVR2anp1U2FHc0RacjhicU0zbGw0TFZYYmlRayIsIjJmYV9yZXEiOmZhbHNlLCJhY2lkIjoiNWYxNTg4ZTktMzIyZS00MTgyLWJjNWEtYzhmYTVmMWE0NDAxIiwib3JnX2lkIjoiYTZlODY2NzUtZDQ0Ny00YzgzLTk0ZDAtYWVlODBjNDRhMDNlIiwibmFtZSI6ImFkbWluQEZQSW5kaWEuY29tIiwic2lkIjoiVVN0RkJkVENURFlyZXJFT0VjcnZoZyJ9.duwg9gGFZlMyKB3TTkNB9Bngb_BBp5BgnjVc_D5kCZI8jhSUawCSEnXF_dT2mj53cx0knOS1fZBOKhKEMecpLrvQcnSQ0NcCEDHD4WmEU4JOitpDRYLjVN4Y1saswMhX9Svvrb7xX25awa-z599j7NIBBxPIPOSDAX3zJ_KJw4UFWWNPWvH6jgmfE0NC3mhRX4Ng2s3oOb0hEZXIZaZlgjelDnElDdfutEnHDjGPCg5HPRfjmo0ZWX4DZKg_dAYYxV44F6qgM2fykMfe3hadqWS_dLGINbxi1YKsd81iXJEZ8WmiWlB4AAuM4UgHohpqpbOkURU5PLZHUOi9kPz9Lg",
      //   "X-Instance-Id": instanceId,
      //   baseURL: url,
      // };
      // request.headers = {
      //   Authorization: "Bearer " + accessToken,
      //   "X-Instance-Id": instanceId,
      //   baseURL: url,
      // };    
      // return request;
    });

  const onNavClick = (e: any) => {
    if (e.detail.name === "Dashboard") {
      SetopenDashboardScreen(true);
      SetopenUserScreen(false);
      SetopenConfigurationScreen(false);
      SetopenNotificationScreen(false);
      SetopenApprovalScreen(false);
      SetopenArchivedUsersScreen(false);
      setSelectedItem("Dashboard");
      setuserStatus("");
    } else if (e.detail.name === "Users") {
      fromDashBoard("", "", "", "");
      fromuser("");
      SetopenUserScreen(true);
      SetopenDashboardScreen(false);
      SetopenConfigurationScreen(false);
      SetopenNotificationScreen(false);
      SetopenApprovalScreen(false);
      SetopenArchivedUsersScreen(false);
      setSelectedItem("Users");
      setuserStatus("");
    } else if (e.detail.name === "Configuration") {
      SetopenConfigurationScreen(true);
      SetopenUserScreen(false);
      SetopenDashboardScreen(false);
      SetopenNotificationScreen(false);
      SetopenApprovalScreen(false);
      SetopenArchivedUsersScreen(false);
      setSelectedItem("Configuration");
      setuserStatus("");
    } else if (e.detail.name === "Notification") {
      SetopenNotificationScreen(true);
      SetopenUserScreen(false);
      SetopenDashboardScreen(false);
      SetopenConfigurationScreen(false);
      SetopenApprovalScreen(false);
      SetopenArchivedUsersScreen(false);
      setSelectedItem("Notification");
      setuserStatus("");
    } else if (e.detail.name === "Approval") {
      SetopenApprovalScreen(true);
      SetopenUserScreen(false);
      SetopenDashboardScreen(false);
      SetopenConfigurationScreen(false);
      SetopenNotificationScreen(false);
      SetopenArchivedUsersScreen(false);
      SetopenArchivedUsersScreen(false);
      setSelectedItem("Approval");
      setuserStatus("");
    } else if (e.detail.name === "ArchivedUsers") {
      SetopenApprovalScreen(false);
      SetopenUserScreen(false);
      SetopenDashboardScreen(false);
      SetopenConfigurationScreen(false);
      SetopenNotificationScreen(false);
      SetopenArchivedUsersScreen(true);
      setSelectedItem("ArchivedUsers");
      setuserStatus("");
    }
  };

  const fromDashBoard = (
    chartName: any,
    chartValue: any,
    levelHigh: any,
    levelLow: any
  ) => {
    // selectedItem

    SetchartName(chartName);
    SetchartValue(chartValue);
    SetchartlevelHigh(levelHigh);
    SetchartlevelLow(levelLow);
    setSelectedItem("Users");
    SetopenUserScreen(true);
    SetopenDashboardScreen(false);
    SetopenConfigurationScreen(false);
    SetopenNotificationScreen(false);
    SetopenApprovalScreen(false);
    SetopenArchivedUsersScreen(false);
  };


  const fromuser = (status: any) => {
    SetopenUserScreen(true);
    fromDashBoard("", "", "", "");
    setSelectedItem("Users");
    SetopenDashboardScreen(false);
    SetopenConfigurationScreen(false);
    SetopenNotificationScreen(false);
    SetopenApprovalScreen(false);
    SetopenArchivedUsersScreen(false);
    setuserStatus(status);
  }

  return (
    <div className="menu common">
      <div className="menu-container">
        <TagSideNav
          collapsable
          accent="white"
          onSideNavItemSelected={onNavClick}
          selectedItem={selectedItem}
          collapsed={collapsedValue}
        >
          <TagSideNavHeader className="menu-header">
            <TagIconSvg icon="Menu"></TagIconSvg>
            <span style={{ marginLeft: "5px" }}>Menu</span>
          </TagSideNavHeader>
          <TagSideNavBody className="menu-body">
            <TagSideNavItem
              name="Dashboard"
              icon="Piechart2"
              text="Dashboard"
            ></TagSideNavItem>
            <TagSideNavItem
              name="Users"
              icon="Users"
              text="Users"
            ></TagSideNavItem>
            <TagSideNavItem
              name="ArchivedUsers"
              icon="FolderArchive2"
              text="Archived Users"
            ></TagSideNavItem>
            <TagSideNavItem
              name="Configuration"
              icon="Settings"
              text="Configuration"
            ></TagSideNavItem>
            {/* <TagSideNavItem
              name="Notification"
              icon="Bell"
              text="Notification"
            ></TagSideNavItem> */}
            <TagSideNavItem name="Approval" icon="Checkbox2" text="Approval" />
          </TagSideNavBody>
        </TagSideNav>
      </div>
      <div className="doc_container w-100">
        {openUserScreen ? (
          <User
            openUserScreen={openUserScreen}
            SetopenUserScreen={SetopenUserScreen}
            chartName={chartName}
            chartValue={chartValue}
            levelHigh={chartlevelHigh}
            levelLow={chartlevelLow}
            userStatus={userStatus}
          />
        ) : null}
        {openArchivedUsersScreen ? (
          <ArchivedUsers
            openArchivedUsersScreen={openArchivedUsersScreen}
            SetopenArchivedUsersScreen={SetopenArchivedUsersScreen}
          />
        ) : null}
        {openApprovalScreen ? (
          <Approval
            openApprovalScreen={openApprovalScreen}
            SetopenApprovalScreen={SetopenApprovalScreen}
          />
        ) : null}
        {openConfigurationScreen ? (
          <Configuration
            openConfigurationScreen={openConfigurationScreen}
            SetopenConfigurationScreen={SetopenConfigurationScreen}
          />
        ) : null}
        {openDashboardScreen ? (
          <Dashboard
            openDashboardScreen={openDashboardScreen}
            SetopenDashboardScreen={SetopenDashboardScreen}
            fromDashBoard={fromDashBoard}
            fromuser={fromuser}
          />
        ) : null}
        {openNotificationScreen ? (
          <Notification
            openNotificationScreen={openNotificationScreen}
            SetopenNotificationScreen={SetopenNotificationScreen}
          />
        ) : null}
      </div>
      <div>

      </div>
    </div>
  );
};
export default Menu;
