/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-computed-key */
import {
  TagButton,
  TagTabHeader,
  TagTabHeaders,
  TagDropDown,
} from "@tag/tag-components-react-v3";
import axios from "axios";
import React, { useState } from "react";
import UserDetails from "../components/UserDetails/UserDetails";
import {
  IUsersFilter,
  IUsers,
  IUsersDetails,
  ITagsValue,
} from "../core/view-Model/users.model";
import { HttpHeaders } from "../shared/HttpHeaders";
import "../../src/assets/css/userDetail.scss";
import { TagEditField, TagForm, TagModal, TagTable } from "@tag/tag-components-react-v2";
import LoadingSpinner from "./LoadingSpinner";
import { NotifyPublisher } from "./Notify/NotifyPublisher";
import { useIframePidgetSettings } from "@workspace/utils-react/dist/iframePidget/useIframePidgetSettings";
import { PidgetSettings } from "../PidgetSettings";

function User({
  openUserScreen,
  SetopenUserScreen,
  chartName,
  chartValue,
  levelHigh,
  levelLow,
  userStatus,
}: any) {
  const Headers = HttpHeaders();
  const pageSize = 10;
  const [openDetailScreen, SetopenopenDetailScreen] = useState(false);
  const [openuserScreen, SetopenopenuserScreen] = useState(false);
  const [openUserTabScreen, SetopenUserTabScreen] = useState(true);
  const [openAlluserTabScreen, SetopenAlluserTabScreen] = useState(false);
  const [selectedTab, setselectedTab] = useState("DOCUser");
  const approvedColor =
    '{"margin":"0 auto", "background-color": "#68B590","color": "black","padding": "3px","border-radius": "15px", "display": "block", "width": "80px","text-align":"center"}';
  const pendingColor =
    '{"margin":"0 auto", "background-color": "#FF9430","color": "black","padding": "3px","border-radius": "15px", "display": "block", "width": "80px","text-align":"center"}';
  const rejectcolor =
    '{"margin":"0 auto", "background-color": "#EEF0F2","color": "black","padding": "3px","border-radius": "15px", "display": "block", "width": "80px","text-align":"center"}';
  // const adminRoleColor =
  //   '{"margin":"0 auto", "background-color": "#EF4061","color": "White","padding": "3px","border-radius": "15px", "display": "block", "width": "100px","text-align":"center"}';
  // const userRoleColor =
  //   '{"margin":"0 auto", "background-color": "#42BBA2","color": "White","padding": "3px","border-radius": "15px", "display": "block", "width": "100px","text-align":"center"}';
  // const noAccessRolecolor =
  //   '{"margin":"0 auto", "background-color": "#DBDBDB","color": "black","padding": "3px","border-radius": "15px", "display": "block", "width": "100px","text-align":"center"}';
  const organizationUserRolecolor = '{"margin":"0 auto"}';
  const [allUsersData, setAllUsersData] = React.useState<IUsers[]>([]); //Workspace User data
  const [usersDetailsData, setUsersDetailsData] = React.useState<
    IUsersDetails[]
  >([]); // DOC user data
  const [isLoading, setIsLoading] = useState(false);
  const [userCheckedData, setuserCheckedData] = React.useState<IUsersDetails[]>(
    []
  );
  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  const userAvtar = url + "Content/Images/useravtar.png";
  const [tag1Header, settag1Header] = useState('');
  const [tag1Values, settag1Values] = React.useState<ITagsValue[]>([]);
  const [tag2Header, settag2Header] = useState('');
  const [tag2Values, settag2Values] = React.useState<ITagsValue[]>([]);
  const [tag3Header, settag3Header] = useState('');
  const [tag3Values, settag3Values] = React.useState<ITagsValue[]>([]);

  const [localUserFilterData, setlocalUserFilterData] =
    React.useState<IUsersFilter>({
      Name: "",
      VehicleRegistrationNumber: "",
      LicenceNo: "",
      VehicleLicenceExpiryDate: null,
      VehicleTaxDueDate: null,
      VehicleMotExpiryDate: null,
      VehicleInsuranceExpiryDate: null,
      JobTitle: "",
      Office: "",
      Department: "",
      tag1Value: "",
      tag2Value: "",
      tag3Value: "",
      Status: "",
      ChartFilter: false,
      ChartName: "",
      ChartValue: "",
      ChartLevelHigh: 0,
      ChartLevelLow: 0,
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    }); // DOC user filter data

  const [organizationUserFilterData, setorganizationUserFilterData] =
    React.useState<IUsersFilter>({
      Name: "",
      VehicleRegistrationNumber: "",
      LicenceNo: "",
      VehicleLicenceExpiryDate: null,
      VehicleTaxDueDate: null,
      VehicleMotExpiryDate: null,
      VehicleInsuranceExpiryDate: null,
      JobTitle: "",
      Office: "",
      Department: "",
      tag1Value: "",
      tag2Value: "",
      tag3Value: "",
      Status: "",
      ChartFilter: false,
      ChartName: "",
      ChartValue: "",
      ChartLevelHigh: 0,
      ChartLevelLow: 0,
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    }); // DOC user filter data

  const [openDOCUsersFilterScreen, SetOpenDOCUsersFilterScreen] =
    useState(false);
  const OnDOCUsersFilterOpened = (e: any) => { };

  const OnDOCUsersFilterClick = (e: any) => {
    SetOpenDOCUsersFilterScreen(true);
  };

  const [
    openOrganizationUsersFilterScreen,
    SetOpenOrganizationUsersFilterScreen,
  ] = useState(false);
  const OnOrganizationUsersFilterOpened = (e: any) => { };

  const OnOrganizationUsersFilterClick = (e: any) => {
    SetOpenOrganizationUsersFilterScreen(true);
  };

  const [docUsersFilterCount, setdocUsersFilterCount] = React.useState('0');
  const [organizationUsersFilterCount, setorganizationUsersFilterCount] = React.useState('0');

  React.useEffect(() => {
    if (chartName) {
      localUserFilterData.ChartFilter = true;
      localUserFilterData.ChartName = chartName;
      localUserFilterData.ChartValue = chartValue;
      localUserFilterData.ChartLevelHigh = levelHigh;
      localUserFilterData.ChartLevelLow = levelLow;
      setselectedTab("DOCUser");
      LoadUsersDetails(1);
    } else if (userStatus) {
      organizationUserFilterData.Status = userStatus;
      onOrganizationUserTab();
      setselectedTab("OrganisationUser");
    } else {
      setselectedTab("DOCUser");
      GetTagsDetails();
    }
  }, [chartName, userStatus, localUserFilterData, chartValue, levelHigh, levelLow, organizationUserFilterData]);

  const GetTagsDetails = () => {
    setIsLoading(true);
    axios.get(`User/GetAllTagsAndValues`, Headers).then((response) => {
      if (response !== null && response !== undefined) {
        if (response.data !== null && response.data !== undefined) {
          if (response.data.data !== null && response.data.data !== undefined) {
            for (var i = 0; i < response.data.data.length && i < 3; i++) {
              if ((i + 1) == 1) {
                settag1Header(response.data.data[i].name);
              } else if ((i + 1) == 2) {
                settag2Header(response.data.data[i].name);
              } else {
                settag3Header(response.data.data[i].name);
              }

              if (response.data.data[i].tagItems !== null && response.data.data[i].tagItems !== undefined && response.data.data[i].tagItems.length > 0) {
                let tagValues = [];
                tagValues.push({ label: "", value: "" });
                for (var j = 0; j < response.data.data[i].tagItems.length; j++) {
                  tagValues.push({
                    label: response.data.data[i].tagItems[j].name,
                    value: response.data.data[i].tagItems[j].id
                  });
                }
                if (tagValues.length > 0) {
                  if ((i + 1) == 1) {
                    settag1Values(tagValues);
                  } else if ((i + 1) == 2) {
                    settag2Values(tagValues);
                  } else {
                    settag3Values(tagValues);
                  }
                }
              }
            }
            LoadUsersDetails(1);
          }
          else {
            LoadUsersDetails(1);
          }
        } else {
          LoadUsersDetails(1);
        }
      } else {
        LoadUsersDetails(1);
      }
    }).catch((ex) => {
      console.log("Getting some API error when getting tag values!");
      LoadUsersDetails(1);
      setIsLoading(false);
    });
  };

  function onRowClick(newValue: any) {
    if (!newValue) {
      LoadUsersDetails(1);
      SetopenopenuserScreen(newValue);
    } else {
      // if (newValue.detail.row.licenceManuallyAdd) {
      //   NotifyPublisher.message(('You dont have permission to view this screen as you have added your details manually'), false, 'successfullyAutoCheckAdd', 'information', 'information', '', '');
      // }
      // else {
      SetopenopenuserScreen(newValue);
      //}
    }
  }
  const Rowchecked = (e: any) => {
    setuserCheckedData(e.detail.checkedRows);
  };

  const OnTabChange = (e: any) => {
    if (e.detail.name === "DOCUser") {
      LoadUsersDetails(1);
      SetopenUserTabScreen(true);
      SetopenAlluserTabScreen(false);
    } else if (e.detail.name === "OrganisationUser") {
      onOrganizationUserTab();
    }
  };

  const onOrganizationUserTab = () => {
    LoadAllUsers(1);
    SetopenAlluserTabScreen(true);
    SetopenUserTabScreen(false);
  };

  const OnStartAutoCheckedClick = (e: any) => {
    SetStartAutocheckData(userCheckedData);
  };

  const OnStopAutoCheckedClick = (e: any) => {
    SetStopAutocheckData(userCheckedData);
  };

  const SetStopAutocheckData = (userCheckedData: IUsersDetails[]) => {
    setIsLoading(true);
    axios
      .post(
        `User/SetStopAutocheckData`,
        userCheckedData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              NotifyPublisher.message(('AutoCheck successfully saved'), false, 'successfullyAutoCheckAdd', 'information', 'information', '', '');
              SetopenUserTabScreen(false);
              LoadUsersDetails(1);
              SetopenUserTabScreen(true);
              setUsersDetailsData(response.data.data);
              UpdateUserAvtar();
              setIsLoading(false);
            }
            else {
              setIsLoading(false);
            }
          }
        }
      });
  };

  const SetStartAutocheckData = (userCheckedData: IUsersDetails[]) => {
    setIsLoading(true);
    axios
      .post(
        `User/SetStartAutocheckData`,
        userCheckedData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              NotifyPublisher.message(('AutoCheck successfully saved'), false, 'successfullyAutoCheckAdd', 'information', 'information', '', '');
              setUsersDetailsData(response.data.data);
              UpdateUserAvtar();
              SetopenUserTabScreen(false);
              LoadUsersDetails(1);
              SetopenUserTabScreen(true);
              setIsLoading(false);
            }
            else {
              setIsLoading(false);
            }
          }
        }
      });
  };

  const LoadAllUsers = (currentPage: number) => {
    setIsLoading(true);
    setAllUsersData([]);
    organizationUserFilterData.GetAll = false;
    organizationUserFilterData.CurrentPageNumber = currentPage;
    axios
      .post(`User/GetAllAccessUsers/`, organizationUserFilterData, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                settag1Header(response.data.data[0].tag1Header);
                settag2Header(response.data.data[0].tag2Header);
                settag3Header(response.data.data[0].tag3Header);
                setAllUsersData(response.data.data);
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                UpdateAllUsers();
                SetOpenOrganizationUsersFilterScreen(false);
                setIsLoading(false);
              }
              else {
                setIsLoading(false);
                SetOpenOrganizationUsersFilterScreen(false);
              }
            }
            else {
              setIsLoading(false);
              SetOpenOrganizationUsersFilterScreen(false);
            }
          }
        }
      })
      .catch((ex) => {
        setIsLoading(false);
        SetOpenOrganizationUsersFilterScreen(false);
        console.log("Getting some API error when bind users details!");
      });
  };

  const ExportAllUsers = () => {
    setIsLoading(true);
    organizationUserFilterData.GetAll = true;
    organizationUserFilterData.CurrentPageNumber = 1;
    axios
      .post(`User/ExportAllUsersList/`,
        organizationUserFilterData,
        { responseType: 'blob' })
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            var fileName = "AllUsers";
            var fileType = response.headers["content-type"];
            var file = new Blob([response.data], { type: fileType });
            var archiveUsersURL = URL.createObjectURL(file);
            const link = document.createElement("a");

            link.href = archiveUsersURL;
            link.download = fileName;
            link.click();

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        setIsLoading(false);
        console.log("Getting some API error when bind users details!");
      });
  };

  const UpdateUserAvtar = () => {
    setUsersDetailsData((x) =>
      [...x].map((i) =>
        i.avatarURL320 == null
          ? { ...i, avatarURL320: userAvtar }
          : i
      )
    );
  };

  const UpdateAllUsers = () => {
    setAllUsersData((x) =>
      [...x].map((i) =>
        i.appStatus === "Access"
          ? { ...i, highlightText: approvedColor }
          : i.appStatus === "No Access"
            ? { ...i, highlightText: rejectcolor }
            : i.appStatus === "Not Used"
              ? { ...i, highlightText: pendingColor }
              : i
      )
    );
    UpdateAllUsersRole();
  };

  const UpdateAllUsersRole = () => {
    // setAllUsersData((x) =>
    //   [...x].map((i) =>
    //     i.applicationRole === "Administrator"
    //       ? { ...i, highlightRoleText: adminRoleColor }
    //       : i.applicationRole === "User"
    //         ? { ...i, highlightRoleText: userRoleColor }
    //         : i.applicationRole === "No Access"
    //           ? { ...i, highlightRoleText: noAccessRolecolor }
    //           : i
    //   )
    // );

    setAllUsersData((x) =>
      [...x].map((i) =>
        i.applicationRole === "Administrator"
          ? { ...i, highlightRoleText: organizationUserRolecolor }
          : i.applicationRole === "User"
            ? { ...i, highlightRoleText: organizationUserRolecolor }
            : i.applicationRole === "No Access"
              ? { ...i, highlightRoleText: organizationUserRolecolor }
              : i
      )
    );
    UpdateAllUserAvtar();
  };

  const UpdateAllUserAvtar = () => {
    setAllUsersData((x) =>
      [...x].map((i) =>
        i.avatarURL320 == null
          ? { ...i, avatarURL320: userAvtar }
          : i
      )
    );
  };

  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);

  const LoadUsersDetails = (currentPage: number) => {
    setIsLoading(true);
    setUsersDetailsData([]);
    localUserFilterData.GetAll = false;
    localUserFilterData.CurrentPageNumber = currentPage;
    axios
      .post(`User/GetAllLocalUsersDetailsList`, localUserFilterData, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setUsersDetailsData(response.data.data);
                UpdateUserAvtar();
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                SetOpenDOCUsersFilterScreen(false);
                setIsLoading(false);
              }
              else {
                setIsLoading(false);
                SetOpenDOCUsersFilterScreen(false);
              }
            }
            else {
              setIsLoading(false);
              SetOpenDOCUsersFilterScreen(false);
            }
          }
        }
      })
      .catch((ex) => {
        setIsLoading(false);
        SetOpenDOCUsersFilterScreen(false);
        console.log("Getting some API error when bind chart details data!");
      });
  };

  const ExportUsersDetails = () => {
    setIsLoading(true);
    localUserFilterData.GetAll = true;
    localUserFilterData.CurrentPageNumber = 1;
    axios
      .post(`User/ExportAllLocalUsersDetailsList`,
        localUserFilterData,
        { responseType: 'blob' })
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            var fileName = "AllDOCUsers";
            var fileType = response.data.type;
            var file = new Blob([response.data], { type: fileType });
            var archiveUsersURL = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = archiveUsersURL;
            link.download = fileName;
            link.click();
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        setIsLoading(false);
        SetOpenDOCUsersFilterScreen(false);
        console.log("Getting some API error when bind chart details data!");
      });
  };

  const handlePageChangeRequest = (e: any) => {
    LoadUsersDetails(e.detail.page);
  };

  const handleuserPageChangeRequest = (e: any) => {
    LoadAllUsers(e.detail.page);
  };

  const OnUsersDetailsFilterApplyClick = (e: any) => {
    let docCountOfFilter = 0;

    if (localUserFilterData.Name != "" && localUserFilterData.Name != null && localUserFilterData.Name != undefined) {
      docCountOfFilter++;
    }

    if (localUserFilterData.VehicleRegistrationNumber != "" && localUserFilterData.VehicleRegistrationNumber != null && localUserFilterData.VehicleRegistrationNumber != undefined) {
      docCountOfFilter++;
    }

    if (localUserFilterData.LicenceNo != "" && localUserFilterData.LicenceNo != null && localUserFilterData.LicenceNo != undefined) {
      docCountOfFilter++;
    }

    if (localUserFilterData.VehicleLicenceExpiryDate != null && localUserFilterData.VehicleLicenceExpiryDate != undefined) {
      docCountOfFilter++;
    }

    if (localUserFilterData.VehicleTaxDueDate != null && localUserFilterData.VehicleTaxDueDate != undefined) {
      docCountOfFilter++;
    }

    if (localUserFilterData.VehicleMotExpiryDate != null && localUserFilterData.VehicleMotExpiryDate != undefined) {
      docCountOfFilter++;
    }

    if (localUserFilterData.VehicleInsuranceExpiryDate != null && localUserFilterData.VehicleInsuranceExpiryDate != undefined) {
      docCountOfFilter++;
    }

    if (localUserFilterData.tag1Value != "" && localUserFilterData.tag1Value != null && localUserFilterData.tag1Value != undefined) {
      docCountOfFilter++;
    }

    if (localUserFilterData.tag2Value != "" && localUserFilterData.tag2Value != null && localUserFilterData.tag2Value != undefined) {
      docCountOfFilter++;
    }

    if (localUserFilterData.tag3Value != "" && localUserFilterData.tag3Value != null && localUserFilterData.tag3Value != undefined) {
      docCountOfFilter++;
    }

    if (localUserFilterData.Status != "" && localUserFilterData.Status != null && localUserFilterData.Status != undefined) {
      docCountOfFilter++;
    }

    setdocUsersFilterCount(String(docCountOfFilter));
    LoadUsersDetails(1);
  };

  const OnOrganizationUsersDetailsFilterApplyClick = (e: any) => {
    let organizationCountOfFilter = 0;

    if (organizationUserFilterData.Name != "" && organizationUserFilterData.Name != null && organizationUserFilterData.Name != undefined) {
      organizationCountOfFilter++;
    }

    if (organizationUserFilterData.VehicleRegistrationNumber != "" && organizationUserFilterData.VehicleRegistrationNumber != null && organizationUserFilterData.VehicleRegistrationNumber != undefined) {
      organizationCountOfFilter++;
    }

    if (organizationUserFilterData.LicenceNo != "" && organizationUserFilterData.LicenceNo != null && organizationUserFilterData.LicenceNo != undefined) {
      organizationCountOfFilter++;
    }

    if (organizationUserFilterData.VehicleLicenceExpiryDate != null && organizationUserFilterData.VehicleLicenceExpiryDate != undefined) {
      organizationCountOfFilter++;
    }

    if (organizationUserFilterData.VehicleTaxDueDate != null && organizationUserFilterData.VehicleTaxDueDate != undefined) {
      organizationCountOfFilter++;
    }

    if (organizationUserFilterData.VehicleMotExpiryDate != null && organizationUserFilterData.VehicleMotExpiryDate != undefined) {
      organizationCountOfFilter++;
    }

    if (organizationUserFilterData.VehicleInsuranceExpiryDate != null && organizationUserFilterData.VehicleInsuranceExpiryDate != undefined) {
      organizationCountOfFilter++;
    }

    if (organizationUserFilterData.tag1Value != "" && organizationUserFilterData.tag1Value != null && organizationUserFilterData.tag1Value != undefined) {
      organizationCountOfFilter++;
    }

    if (organizationUserFilterData.tag2Value != "" && organizationUserFilterData.tag2Value != null && organizationUserFilterData.tag2Value != undefined) {
      organizationCountOfFilter++;
    }

    if (organizationUserFilterData.tag3Value != "" && organizationUserFilterData.tag3Value != null && organizationUserFilterData.tag3Value != undefined) {
      organizationCountOfFilter++;
    }

    if (organizationUserFilterData.Status != "" && organizationUserFilterData.Status != null && organizationUserFilterData.Status != undefined) {
      organizationCountOfFilter++;
    }

    setorganizationUsersFilterCount(String(organizationCountOfFilter));
    LoadAllUsers(1);
  };

  let handleChange = (evt: any) => {
    evt.stopPropagation();
    let value;
    if (evt.target.name === "veh-MOT" || evt.target.name === "veh-Tax-Due") {
      if (evt.target.value !== undefined && evt.target.value != null) {
        value = new Date(
          Date.UTC(
            evt.target.value.getFullYear(),
            evt.target.value.getMonth(),
            evt.target.value.getDate(),
            0,
            0,
            0,
            0
          )
        );
      }
    } else {
      value = evt.target.value;
    }
    if (evt.target.name === "veh-MOT") {
      localUserFilterData.VehicleMotExpiryDate = value;
      // setlocalUserFilterData({
      //   ...localUserFilterData,
      //   ["VehicleMotExpiryDate"]: value,
      // });
    } if (evt.target.name === "veh-Insurance-Due") {
      localUserFilterData.VehicleInsuranceExpiryDate = value;
      // setlocalUserFilterData({
      //   ...localUserFilterData,
      //   ["VehicleInsuranceExpiryDate"]: value,
      // });
    } if (evt.target.name === "veh-Licence-Due") {
      localUserFilterData.VehicleLicenceExpiryDate = value;
      // setlocalUserFilterData({
      //   ...localUserFilterData,
      //   ["VehicleLicenceExpiryDate"]: value,
      // });
    } if (evt.target.name === "veh-Tax-Due") {
      localUserFilterData.VehicleTaxDueDate = value;
      // setlocalUserFilterData({
      //   ...localUserFilterData,
      //   ["VehicleTaxDueDate"]: value,
      // });
    }
  };

  return (
    <>
      <div>
        {!openuserScreen ? (
          <>
            <div>
              <TagTabHeaders
                type="block"
                onTabHeaderChange={OnTabChange}
                selectedTab={selectedTab}
              >
                <TagTabHeader name="DOCUser">Duty Of Care</TagTabHeader>
                <TagTabHeader name="OrganisationUser">
                  Organisation
                </TagTabHeader>
              </TagTabHeaders>
            </div>

            <div>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div>
                  {openUserTabScreen ? (
                    <div>
                      {/*filter_Btn" */}
                      <div className="fr pt_5 pb_5 mt_15 mb_15">
                        {" "}
                        <div>
                          <TagButton
                            icon='DocExcel'
                            iconAccent='keppel'
                            squared id="1"
                            onButtonClick={ExportUsersDetails}
                            buttonStyle={{ "height": "36px", "width": "36px", "marginRight": "5px" }} />
                          <TagButton
                            text="Filter"
                            size="large"
                            icon="Switches"
                            onButtonClick={OnDOCUsersFilterClick}
                            minWidth="100px"
                            badge={docUsersFilterCount}
                            badgeAccent='access'
                          />
                        </div>
                      </div>
                      <div>
                        <TagTable
                          pagerShowFirstLast
                          multiCheck
                          multiCheckAlign="left"
                          buttonIcon='View'
                          buttonStyle={{ "height": "30px", "width": "30px" }}
                          buttonRounded
                          page={page}
                          pageSize={pageSize}
                          pageCount={pageCount}
                          onPageRequest={handlePageChangeRequest}
                          onRowButtonClick={onRowClick}
                          onRowsChecked={Rowchecked}
                          columns={[
                            {
                              name: "avatarURL320",
                              caption: "User",
                              dataType: "image",
                              cellInnerStyle: {
                                height: "25px",
                                borderRadius: "12px",
                              },
                            },
                            { name: "fullName", caption: "Name" },
                            {
                              name: "vehicleRegistrationNumber",
                              caption: "Registration No.",
                            },
                            { name: "licenceNo", caption: "Driving Licence" },
                            {
                              name: "licenceExpiryDate",
                              caption: "Driving Licence Due",
                              dataType: "date",
                            },
                            {
                              name: "vehicleTaxDueDate",
                              caption: "Tax Due",
                              dataType: "date",
                            },
                            {
                              name: "vehicleMotExpiryDate",
                              caption: "MOT Due",
                              dataType: "date",
                            },
                            {
                              name: "vehicleInsuranceExpiryDate",
                              caption: "Insurance Due",
                              dataType: "date",
                            },
                            {
                              name: "allowAutoCheck",
                              caption: "AutoCheck",
                              dataType: "boolean",
                            },
                          ]}
                          data={usersDetailsData}
                        />
                      </div>
                      <div>
                        <div className="fr startstopAutoCheck">
                          {" "}
                          <TagButton
                            text="Stop Auto Check"
                            size="large"
                            onButtonClick={OnStopAutoCheckedClick}
                            minWidth="100px"
                          />
                        </div>
                        <div className="fr startstopAutoCheck">
                          {" "}
                          <TagButton
                            text="Start Auto Check"
                            size="large"
                            minWidth="100px"
                            onButtonClick={OnStartAutoCheckedClick}
                          />
                        </div>
                      </div>
                      {/* Filter Model for DOC User */}
                      <div>
                        <TagModal
                          visible={openDOCUsersFilterScreen}
                          heading="DOC Users Filter"
                          width="small"
                          borderAccent="viridiangreen"
                          onOpened={OnDOCUsersFilterOpened}
                          onClosed={() => {
                            SetOpenDOCUsersFilterScreen(false);
                          }}
                          primaryButton="Apply"
                          primaryButtonAccent="viridiangreen"
                          onPrimaryButtonClick={OnUsersDetailsFilterApplyClick}
                          cancelButton="Clear"
                          cancelButtonIconAccent="title"
                          onCancelButtonClick={() => {
                            localUserFilterData.Name = "";
                            localUserFilterData.VehicleRegistrationNumber = "";
                            localUserFilterData.LicenceNo = "";
                            localUserFilterData.VehicleLicenceExpiryDate = null;
                            localUserFilterData.VehicleTaxDueDate = null;
                            localUserFilterData.VehicleMotExpiryDate = null;
                            localUserFilterData.VehicleInsuranceExpiryDate = null;
                            localUserFilterData.tag1Value = "";
                            localUserFilterData.tag3Value = "";
                            localUserFilterData.tag3Value = "";
                            localUserFilterData.Status = "";
                            setdocUsersFilterCount('0');
                            LoadUsersDetails(1);
                          }}
                        >
                          <TagForm submitButtonHidden={true}>
                            <div className="lic-Content">
                              <table className="lic-Form-Table">
                                <tbody>
                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>User Name</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagEditField
                                          name="usr-name"
                                          editor="textbox"
                                          data-type="string"
                                          value={localUserFilterData.Name}
                                          onValueChange={(e) => {
                                            localUserFilterData.Name =
                                              e.detail.value;
                                          }}
                                        ></TagEditField>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>Registration No.</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagEditField
                                          name="veh-registrationNo"
                                          editor="textbox"
                                          data-type="string"
                                          value={
                                            localUserFilterData.VehicleRegistrationNumber
                                          }
                                          onValueChange={(e) => {
                                            localUserFilterData.VehicleRegistrationNumber =
                                              e.detail.value;
                                          }}
                                        ></TagEditField>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>Driving Licence</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagEditField
                                          name="lic-drivingLicence"
                                          editor="textbox"
                                          data-type="string"
                                          value={localUserFilterData.LicenceNo}
                                          onValueChange={(e) => {
                                            localUserFilterData.LicenceNo =
                                              e.detail.value;
                                          }}
                                        ></TagEditField>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>Licence Due</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagEditField
                                          name="veh-Licence-Due"
                                          editor="datepick"
                                          dataType="date"
                                          value={
                                            localUserFilterData.VehicleLicenceExpiryDate
                                          }
                                          onValueChange={handleChange}
                                        />
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text> Tax Due</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagEditField
                                          name="veh-Tax-Due"
                                          editor="datepick"
                                          dataType="date"
                                          value={
                                            localUserFilterData.VehicleTaxDueDate
                                          }
                                          onValueChange={handleChange}
                                        />
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text> MOT</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagEditField
                                          name="veh-MOT"
                                          editor="datepick"
                                          dataType="date"
                                          value={
                                            localUserFilterData.VehicleMotExpiryDate
                                          }
                                          onValueChange={handleChange}
                                        />
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text> Insurance Due</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagEditField
                                          name="veh-Insurance-Due"
                                          editor="datepick"
                                          dataType="date"
                                          value={
                                            localUserFilterData.VehicleInsuranceExpiryDate
                                          }
                                          onValueChange={handleChange}
                                        />
                                      </div>
                                    </td>
                                  </tr>

                                </tbody>
                              </table>
                            </div>
                          </TagForm>
                        </TagModal>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>

            <div>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div>
                  {openAlluserTabScreen ? (
                    <div>
                      {" "}
                      {/*filter_Btn" */}
                      <div className="fr pt_5 pb_5 mt_15 mb_15">
                        {" "}
                        <div>
                          <TagButton
                            icon='DocExcel'
                            iconAccent='keppel'
                            squared id="1"
                            onButtonClick={ExportAllUsers}
                            buttonStyle={{ "height": "36px", "width": "36px", "marginRight": "5px" }} />
                          <TagButton
                            text="Filter"
                            size="large"
                            icon="Switches"
                            onButtonClick={OnOrganizationUsersFilterClick}
                            minWidth="100px"
                            badge={organizationUsersFilterCount}
                            badgeAccent='access'
                          />
                        </div>
                      </div>
                      <div>
                        <TagTable
                          pagerShowFirstLast
                          page={page}
                          pageSize={pageSize}
                          pageCount={pageCount}
                          onPageRequest={handleuserPageChangeRequest}
                          // multiCheck
                          // multiCheckAlign="left"
                          // onRowButtonClick={onRowClick}
                          columns={[
                            {
                              name: "avatarURL320",
                              caption: "User",
                              dataType: "image",
                              cellInnerStyle: {
                                height: "25px",
                                borderRadius: "12px",
                              },
                            },
                            { name: "fullName", caption: "Name" },
                            { name: "tag1Value", caption: tag1Header },
                            { name: "tag2Value", caption: tag2Header },
                            { name: "tag3Value", caption: tag3Header },
                            {
                              name: "appStatus",
                              caption: "Status",
                              cellInnerStyleField: "highlightText",
                              alignment: 'center',
                              headerColumnAlignment: 'center'
                            },
                            {
                              name: "applicationRole",
                              caption: "User Role",
                              cellInnerStyleField: "highlightRoleText"
                            },
                          ]}
                          data={allUsersData}
                        />
                      </div>
                      {/* Filter Model for Organization User */}
                      <div>
                        <TagModal
                          visible={openOrganizationUsersFilterScreen}
                          heading="Organization Users Filter"
                          width="small"
                          borderAccent="viridiangreen"
                          onOpened={OnOrganizationUsersFilterOpened}
                          onClosed={() => {
                            SetOpenOrganizationUsersFilterScreen(false);
                          }}
                          primaryButton="Apply"
                          primaryButtonAccent="viridiangreen"
                          onPrimaryButtonClick={
                            OnOrganizationUsersDetailsFilterApplyClick
                          }
                          cancelButton="Clear"
                          cancelButtonIconAccent="title"
                          onCancelButtonClick={() => {
                            organizationUserFilterData.Name = "";
                            organizationUserFilterData.VehicleRegistrationNumber = "";
                            organizationUserFilterData.LicenceNo = "";
                            organizationUserFilterData.VehicleLicenceExpiryDate = null;
                            organizationUserFilterData.VehicleTaxDueDate = null;
                            organizationUserFilterData.VehicleMotExpiryDate = null;
                            organizationUserFilterData.VehicleInsuranceExpiryDate = null;
                            organizationUserFilterData.tag1Value = "";
                            organizationUserFilterData.tag2Value = "";
                            organizationUserFilterData.tag3Value = "";
                            organizationUserFilterData.Status = "";
                            setorganizationUsersFilterCount('0');
                            LoadAllUsers(1);
                          }}
                        >
                          <TagForm submitButtonHidden={true}>
                            <div className="lic-Content">
                              <table className="lic-Form-Table">
                                <tbody>
                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>User Name</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagEditField
                                          name="usr-name"
                                          editor="textbox"
                                          data-type="string"
                                          value={organizationUserFilterData.Name}
                                          onValueChange={(e) => {
                                            organizationUserFilterData.Name =
                                              e.detail.value;
                                          }}
                                        ></TagEditField>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>{tag1Header}</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagDropDown
                                          data={tag1Values}
                                          value={organizationUserFilterData.tag1Value}
                                          dropDownStyle={{ "width": "100%" }}
                                          onDropDownValueChange={(e) => {
                                            organizationUserFilterData.tag1Value = e.detail.value;
                                          }}
                                        ></TagDropDown>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>{tag2Header}</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagDropDown
                                          data={tag2Values}
                                          value={organizationUserFilterData.tag2Value}
                                          dropDownStyle={{ "width": "100%" }}
                                          onDropDownValueChange={(e) => {
                                            organizationUserFilterData.tag2Value = e.detail.value;
                                          }}
                                        ></TagDropDown>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>{tag3Header}</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagDropDown
                                          data={tag3Values}
                                          value={organizationUserFilterData.tag3Value}
                                          dropDownStyle={{ "width": "100%" }}
                                          onDropDownValueChange={(e) => {
                                            organizationUserFilterData.tag3Value = e.detail.value;
                                          }}
                                        ></TagDropDown>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>Status</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagEditField
                                          name="un-usr-Status"
                                          editor="dropdown"
                                          data-type="string"
                                          options={[
                                            { label: "", value: "" },
                                            { label: "Access", value: "Access" },
                                            { label: "Not Used", value: "Not Used" },
                                            {
                                              label: "No Access",
                                              value: "No Access",
                                            },
                                          ]}
                                          value={organizationUserFilterData.Status}
                                          onValueChange={(e) => {
                                            organizationUserFilterData.Status =
                                              e.detail.value;
                                          }}
                                        ></TagEditField>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TagForm>
                        </TagModal>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </>
        ) : null}

        {openuserScreen ? (
          <div>
            {
              <UserDetails
                openDetailScreen={openDetailScreen}
                SetopenUserScreen={SetopenUserScreen}
                SetopenopenDetailScreen={SetopenopenDetailScreen}
                openuserScreen={openuserScreen}
                onParentRowClick={onRowClick}
                disabledSave={false}
                disabledStartAutoCheck={false}
                disabledStopAutoCheck={false}
              />
            }
          </div>
        ) : null}
      </div>
    </>
  );
}
export default User;
