/* eslint-disable react-hooks/exhaustive-deps */
import { TagButton, TagForm, TagModal, TagText } from "@tag/tag-components-react-v2";
import React, { useState } from "react";
import "../../../assets/css/userDetail.scss";
import LoadingSpinner from "./../../../components/LoadingSpinner";

function VehicleDetails({
  vehicleDetailsData,
  openVehicleDetailScreen,
  SetopenVehicleDetailScreen,
  isLoading,
  makeforExport,
  taxDueDate,
  motExpiryDate,
  lastV5cIssuedDate,
  insStartDate,
  insTerm,
  insExpiryDate
}: any) {
  const onCancelButtonClick = (e: any) => {
    SetopenVehicleDetailScreen(false);
  };

  const [taxDocumentValue, SettaxDocumentValue] = useState("");
  const [motDocumentValue, SetmotDocumentValue] = useState("");
  const [insuranceDocumentValue, SetinsuranceDocumentValue] = useState("");
  const [showInsuranceImageModel, setshowInsuranceImageModel] = useState(false);
  const [showMOTImageModel, setshowMOTImageModel] = useState(false);
  const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
  const [insuranceImageSrc, setinsuranceImageSrc] = useState('');
  const [motImageSrc, setmotImageSrc] = useState('');

  React.useEffect(() => {
    VehicleValidation();
  }, []);

  const VehicleValidation = () => {

    if (vehicleDetailsData.taxdocument === null) {
      SettaxDocumentValue("");
    } else {
      SettaxDocumentValue(vehicleDetailsData.taxdocument.doC_D_FILE_NAME)
    }
    if (vehicleDetailsData.motdocument === null) {
      SetmotDocumentValue("");
    } else {
      SetmotDocumentValue(vehicleDetailsData.motdocument.doC_D_FILE_NAME);
    }
    if (vehicleDetailsData.insurancedocument === null) {
      SetinsuranceDocumentValue("")
    } else {
      SetinsuranceDocumentValue(vehicleDetailsData.insurancedocument.doC_D_FILE_NAME)
    }
  }

  const downloadFile = (e: any) => {
    var fileName = "";
    var fileType = "";
    var fileData = "";

    if (e.target.id === "insuranceDocument" && vehicleDetailsData.insurancedocument !== null) {
      fileName = vehicleDetailsData.insurancedocument.doC_D_FILE_NAME;
      fileType = vehicleDetailsData.insurancedocument.doC_D_FILE_CONTENT_TYPE;
      fileData = vehicleDetailsData.insurancedocument.doC_D_FILE_DATA;

      var fileBinaryString = atob(fileData);
      var dowloadFileBytes = new Uint8Array(fileBinaryString.length);
      for (var i = 0; i < fileBinaryString.length; i++) {
        var ascii = fileBinaryString.charCodeAt(i);
        dowloadFileBytes[i] = ascii;
      }

      var file = new Blob([dowloadFileBytes], {
        type: fileType,
      });

      var insuranceFileObjectURL = URL.createObjectURL(file);

      if (allowedFileTypes.includes(fileType)) {
        setinsuranceImageSrc(insuranceFileObjectURL);
        setshowInsuranceImageModel(true);
      } else {
        const link = document.createElement("a");
        link.href = insuranceFileObjectURL;
        link.download = fileName;
        link.click();
      }
    }

    if (e.target.id === "motDocument" && vehicleDetailsData.motdocument !== null) {
      fileName = vehicleDetailsData.motdocument.doC_D_FILE_NAME;
      fileType = vehicleDetailsData.motdocument.doC_D_FILE_CONTENT_TYPE;
      fileData = vehicleDetailsData.motdocument.doC_D_FILE_DATA;

      var fileBinaryString = atob(fileData);
      var dowloadFileBytes = new Uint8Array(fileBinaryString.length);
      for (var i = 0; i < fileBinaryString.length; i++) {
        var ascii = fileBinaryString.charCodeAt(i);
        dowloadFileBytes[i] = ascii;
      }

      var file = new Blob([dowloadFileBytes], {
        type: fileType,
      });
      var motFileObjectURL = URL.createObjectURL(file);

      if (allowedFileTypes.includes(fileType)) {
        setmotImageSrc(motFileObjectURL);
        setshowMOTImageModel(true);
      } else {
        const link = document.createElement("a");
        link.href = motFileObjectURL;
        link.download = fileName;
        link.click();
      }
    }
    if (e.target.id === "TaxDocument" && vehicleDetailsData.taxdocument !== null) {
      fileName = vehicleDetailsData.taxdocument.doC_D_FILE_NAME;
      fileType = vehicleDetailsData.taxdocument.doC_D_FILE_CONTENT_TYPE;
      fileData = vehicleDetailsData.taxdocument.doC_D_FILE_DATA;

      var fileBinaryString = atob(fileData);
      var dowloadFileBytes = new Uint8Array(fileBinaryString.length);
      for (var i = 0; i < fileBinaryString.length; i++) {
        var ascii = fileBinaryString.charCodeAt(i);
        dowloadFileBytes[i] = ascii;
      }

      var file = new Blob([dowloadFileBytes], {
        type: fileType,
      });
      var taxFileObjectURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = taxFileObjectURL;
      link.download = fileName;
      link.click();
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <TagModal
          visible={openVehicleDetailScreen}
          heading="Vehicle Details"
          width="medium"
          borderAccent="viridiangreen"
          onClosed={() => {
            SetopenVehicleDetailScreen(false);
          }}
        >
          <TagForm submitButtonHidden={true}>
            <div className="veh-Content">
              <table className="veh-Form-Table">
                <tr>
                  <td colSpan={2}>
                    <div>
                      <TagText
                        textStyle={{
                          fontSize: "16px",
                          marginBlock: "0",
                          fontWeight: "600",
                          textAlign: "center"
                        }}
                        text="Vehicle"
                      />
                    </div>
                  </td>
                  <td className="w_6"></td>
                  <td colSpan={2}>
                    <div>
                      <TagText
                        textStyle={{
                          fontSize: "16px",
                          marginBlock: "0",
                          fontWeight: "600",
                          textAlign: "center"
                        }}
                        text="Insurance"
                      />
                    </div>
                  </td>
                  <td className="w_6"></td>
                  <td colSpan={2}>
                    <div>
                      <TagText
                        textStyle={{
                          fontSize: "16px",
                          marginBlock: "0",
                          fontWeight: "600",
                          textAlign: "center"
                        }}
                        text="Tax & MOT"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="w_15">
                    <div>
                      <tag-text>Registration Number</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-Vehicle-Number"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.registertioN_NUMBER}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textmotexpirydate">
                        Insurance Term
                      </tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-ins-start-date"
                        id="veh-ins-start-date"
                        editor="Textbox"
                        value={insTerm}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="texttaxduedate">Tax Due Date</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-tax-due-date"
                        id="veh-tax-due-date"
                        editor="Textbox"
                        value={taxDueDate}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="w_15">
                    <div>
                      <tag-text>Registered</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-Registration-Month"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.montH_OF_REGISTRATION}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text>Insurance Status</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-ins-status"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.insurancE_STATUS}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="texttaxstatus">Tax Status</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-Status"
                        id="veh-Status"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.taX_STATUS}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="w_15">
                    <div>
                      <tag-text>Fuel Type</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-Fuel-Type"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.fueL_TYPE}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textmotstatus">Insurance End Date</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-ins-expiry"
                        id="veh-ins-expiry"
                        editor="Textbox"
                        data-type="string"
                        value={insExpiryDate}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textmotexpirydate">
                        MOT Expiry Date
                      </tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-mot-expiry-date"
                        id="veh-mot-expiry-date"
                        editor="Textbox"
                        value={motExpiryDate}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="w_15">
                    <div>
                      <tag-text>CO2 Emissions</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-emisson"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.cO2EMISSIONS}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textinsurancepolicynumber">
                        Insurance Policy No.
                      </tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-ins-start-date"
                        id="veh-ins-start-date"
                        editor="Textbox"
                        value={vehicleDetailsData.insurancE_POLICYNUMBER}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textmotstatus">MOT Status</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-mot-status"
                        id="veh-mot-Status"
                        editor="Textbox"
                        data-type="string"
                        value={vehicleDetailsData.moT_STATUS}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="w_15">
                    <div>
                      <tag-text>Engine Capacity</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <tag-edit-field
                        name="veh-Engine-Capacity"
                        editor="Textbox"
                        value={vehicleDetailsData.enginE_CAPACITY}
                        disabled
                      ></tag-edit-field>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="textinsureneceFiles">
                        Insurance Document
                      </tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <div>
                      <a onClick={downloadFile} id="insuranceDocument">
                        {insuranceDocumentValue}
                      </a>
                    </div>
                  </td>

                  <td className="w_6"></td>

                  <td className="w_15">
                    <div>
                      <tag-text name="motdocument">MOT Document</tag-text>
                    </div>
                  </td>
                  <td className="w_15">
                    <a onClick={downloadFile} id="motDocument">
                      {motDocumentValue}
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </TagForm>

          <div className="footer">
            <div>
              <TagButton
                size="large"
                text="Cancel"
                className="cancel"
                onButtonClick={onCancelButtonClick}
              />
            </div>
          </div>
        </TagModal>
      )}
      <div>
        {showInsuranceImageModel ? (
          <>
            <div>
              <TagModal
                visible
                heading="Insurance Document"
                borderAccent='viridiangreen'
                width='small'
                containerStyle={{ backgroundColor: "rgb(249,249,249)" }}
                contentStyle={{ backgroundColor: "white", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}
                headingContainerStyle={{ backgroundColor: "rgb(249,249,249)" }}
                onClosed={() => {
                  setshowInsuranceImageModel(false);
                }}>
                <div style={{ textAlign: "center", maxHeight: "500px", overflowY: "scroll" }}>
                  <img id="insuranceDocumentFileImage" src={insuranceImageSrc} />
                </div>
              </TagModal>
            </div>
          </>
        ) : null}

        {showMOTImageModel ? (
          <>
            <div>
              <TagModal
                visible
                heading="MOT Document"
                borderAccent='viridiangreen'
                width='small'
                containerStyle={{ backgroundColor: "rgb(249,249,249)" }}
                contentStyle={{ backgroundColor: "white", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}
                headingContainerStyle={{ backgroundColor: "rgb(249,249,249)" }}
                onClosed={() => {
                  setshowMOTImageModel(false);
                }}>
                <div style={{ textAlign: "center", maxHeight: "500px", overflowY: "scroll" }}>
                  <img id="motDocumentFileImage" src={motImageSrc} />
                </div>
              </TagModal>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
export default VehicleDetails;
