/* eslint-disable no-useless-concat */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable for-direction */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-empty-function */

import {
  TagH4,
  TagIcon,
  TagProgressIndeterminate,
  TagTable,
} from "@tag/tag-components-react-v3";
import React from "react";
import axios from "axios";
import { HttpHeaders } from "../shared/HttpHeaders";
import LoadingSpinner from "./../components/LoadingSpinner";
interface IChartDetailsData {
  type: string;
  primary: number;
  name: string;
  date: Date;
  expiryin: number;
}

function ExpiredChartTable({ apiName }: any) {
  const Headers = HttpHeaders();
  const [loadingData, setLoadingData] = React.useState(true);
  const [chartDetailsData, setchartDetailsData] = React.useState<
    IChartDetailsData[]
  >([]);
  const returnNoItemsContent = () => (
    <div style={{ textAlign: "center" }}>
      <TagIcon icon="NoEntrySign" size={100} accent="porcelain"></TagIcon>
      <TagH4>No items available</TagH4>
    </div>
  );
  const returnLoadingDataSpinner = () => (
    <TagProgressIndeterminate text="Loading" />
  );
  const pageSize = 5;
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setLoadingData(false);
    bindChartDetailsData(page);
  }, []);

  const bindChartDetailsData = (currentPage: number) => {
    setIsLoading(true);
    setchartDetailsData([]);
    axios
      .get(
        `Admin/` + apiName + "?Pagesize=" + pageSize + "&Page=" + currentPage,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setchartDetailsData(response.data.data);
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                setIsLoading(false);
              }
            }
          }
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind expired chart details data!");
      });
  };

  const handlePageChangeRequest = (e: any) => {
    bindChartDetailsData(e.detail.page);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <TagTable
          pagerShowFirstLast
          name="tblDynamic"
          height="auto"
          columns={[
            //{ name: 'primary', caption: 'Primary' },
            { name: "name", caption: "Name" },
            { name: "type", caption: "Type" },
            { name: "date", caption: "Issue Date", dataType: "date" },
            { name: "expiryin", caption: "Days" },
          ]}
          onPageRequest={handlePageChangeRequest}
          tableContainerStyle={{ minHeight: "230px" }}
          renderNoItems={
            loadingData ? returnLoadingDataSpinner : returnNoItemsContent
          }
          data={chartDetailsData}
          page={page}
          pageSize={pageSize}
          pageCount={pageCount}
        />
      )}
    </div>
  );
}
export default ExpiredChartTable;
