/* eslint-disable react-hooks/exhaustive-deps */
import { TagField } from "@tag/tag-components-react-v2";
import React, { useState } from "react";
import "../assets/css/userDetail.scss";
import { ILicenceData } from "../core/view-Model/userdetails.model";

const Offences: React.FC<{ obj: ILicenceData }> = (props) => {
  const [offecesStartDate, SetoffecesStartDate] = useState(Date);
  const [offecesExpirytDate, SetoffecesExpirytDate] = useState(Date);
  const LicenceValidation = () => {
    if (props.obj.doC_DVLA_L_O_STARTDATE != null) {
      var offencesStartDate = new Date(props.obj.doC_DVLA_L_O_STARTDATE);
      var ddmmyyyOffenceStartDate =
        offencesStartDate.getDate() +
        "/" +
        (offencesStartDate.getMonth() + 1) +
        "/" +
        offencesStartDate.getFullYear();
      SetoffecesStartDate(ddmmyyyOffenceStartDate);
    } else {
      SetoffecesStartDate("-");
    }

    if (props.obj.doC_DVLA_L_O_EXPIRYDATE != null) {
      var offencesExpiryDate = new Date(props.obj.doC_DVLA_L_O_EXPIRYDATE);
      var ddmmyyymotoffencesExpiryDate =
        offencesExpiryDate.getDate() +
        "/" +
        (offencesExpiryDate.getMonth() + 1) +
        "/" +
        offencesExpiryDate.getFullYear();
      SetoffecesExpirytDate(ddmmyyymotoffencesExpiryDate);
    } else {
      SetoffecesExpirytDate("-");
    }
  };
  React.useEffect(() => {
    LicenceValidation();
  }, [props]);

  return (
    <tr>
      <td className="w_20">
        <TagField
          value={props.obj.doC_DVLA_L_O_NAME}
          labelStyle={{
            fontSize: "12px",
            width: "95px",
          }}
          valueStyle={{
            fontSize: "12px",
            //fontWeight: "600",
          }}
        />
      </td>
      <td className="w_20">
        <TagField
          value={props.obj.doC_DVLA_L_O_POINTS}
          labelStyle={{
            fontSize: "12px",
            width: "95px",
          }}
          valueStyle={{
            fontSize: "12px",
            //fontWeight: "600",
          }}
        />
      </td>
      <td className="w_20">
        <TagField
          value={offecesStartDate}
          labelStyle={{
            fontSize: "12px",
            width: "95px",
          }}
          valueStyle={{
            fontSize: "12px",
            //fontWeight: "600",
          }}
        />
      </td>
      <td className="w_20">
        <TagField
          // label="Conviction Date:"
          value={offecesExpirytDate}
          labelStyle={{
            fontSize: "12px",
            width: "95px",
          }}
          valueStyle={{
            fontSize: "12px",
            //fontWeight: "600",
          }}
        />
      </td>
      <td className="w_20">
        <TagField
          //label="Offence Legal Literal:"
          value={props.obj.doC_DVLA_L_O_LEGALLITERAL}
          labelStyle={{
            fontSize: "12px",
            width: "95px",
          }}
          valueStyle={{
            fontSize: "12px",
            //fontWeight: "600",
          }}
        />
      </td>
    </tr>
  );
};

export default Offences;
