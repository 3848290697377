/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TagEditField, TagForm, TagModal, TagTable } from "@tag/tag-components-react-v2";
import {
  TagButton,
  TagTabHeader,
  TagTabHeaders,
} from "@tag/tag-components-react-v3";
import { useIframePidgetSettings } from "@workspace/utils-react";
import axios from "axios";
import React, { useState } from "react";
import { IArchiveUsersFilter, IUsers, IUsersDetails } from "../core/view-Model/users.model";
import { PidgetSettings } from "../PidgetSettings";
import { HttpHeaders } from "../shared/HttpHeaders";
import LoadingSpinner from "./LoadingSpinner";
import { NotifyPublisher } from "./Notify/NotifyPublisher";
import UserDetails from "./UserDetails/UserDetails";

function ArchivedUsers({
  openArchivedUsersScreen,
  SetopenArchivedUsersScreen,
}: any) {
  const Headers = HttpHeaders();
  const pageSize = 10;
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [archivedUsersScreen, SetArchivedUsersScreen] = useState(false);
  const [allArchivedUsersData, setAllArchivedUsersData] = useState<IUsers[]>([]);
  const [archivedUserCheckedData, setArchivedUserCheckedData] = useState<IUsers[]>([]);
  const [allUnarchivedUsersData, setAllUnarchivedUsersData] = useState<IUsers[]>([]);
  const [unArchivedUserCheckedData, setUnArchivedUserCheckedData] = useState<IUsers[]>([]);
  const [openArchiveduserTabScreen, SetOpenArchiveduserTabScreen] = useState(true);
  const [openUnarchiveduserTabScreen, SetOpenUnarchiveduserTabScreen] = useState(false);
  const [openDetailScreen, SetopenopenDetailScreen] = useState(false);
  const [openArchiveFilterScreen, SetOpenArchiveFilterScreen] = useState(false);
  const [openUnarchiveFilterScreen, SetOpenUnarchiveFilterScreen] = useState(false);
  const OnArchiveFilterOpened = (e: any) => { };
  const OnUnarchiveFilterOpened = (e: any) => { };
  const [archivedUserFilterData, setArchivedUserFilterData] =
    React.useState<IArchiveUsersFilter>({
      Name: "",
      JobTitle: "",
      Office: "",
      Department: "",
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    });
  const [unarchivedUserFilterData, setUnarchivedUserFilterData] =
    React.useState<IArchiveUsersFilter>({
      Name: "",
      JobTitle: "",
      Office: "",
      Department: "",
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    });
  const [isLoading, setIsLoading] = React.useState(false);
  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  const userAvtar = url + "Content/Images/useravtar.png";

  React.useEffect(() => {
    LoadAllArchiveUsers(1);
  }, []);

  const [archiveUsersFilterCount, setarchiveUsersFilterCount] = React.useState('0');
  const [unarchiveUsersFilterCount, setunarchiveUsersFilterCount] = React.useState('0');
  const handleArchiveUserPageChangeRequest = (e: any) => { LoadAllArchiveUsers(e.detail.page); };
  const handleUnArchiveUserPageChangeRequest = (e: any) => { LoadAllUnarchiveUsers(e.detail.page); };
  const ArchivedRowchecked = (e: any) => { setArchivedUserCheckedData(e.detail.checkedRows); };
  const UnArchivedRowchecked = (e: any) => { setUnArchivedUserCheckedData(e.detail.checkedRows); };
  const OnArchiveFilterClick = (e: any) => { SetOpenArchiveFilterScreen(true); };
  const OnUnarchiveFilterClick = (e: any) => { SetOpenUnarchiveFilterScreen(true); };

  const LoadAllArchiveUsers = (currentPage: number) => {
    setIsLoading(true);
    setAllArchivedUsersData([]);
    archivedUserFilterData.GetAll = false;
    archivedUserFilterData.CurrentPageNumber = currentPage;
    axios
      .post(
        `User/GetAllLocalArchivedUsersDetailsList`,
        archivedUserFilterData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setAllArchivedUsersData(response.data.data);
                UpdateUserAvtar();
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                SetOpenArchiveFilterScreen(false);
                setIsLoading(false);
              }
              else {
                setIsLoading(false);
                SetOpenArchiveFilterScreen(false);
              }
            } else {
              setIsLoading(false);
              SetOpenArchiveFilterScreen(false);
            }
          } else {
            setIsLoading(false);
            SetOpenArchiveFilterScreen(false);
          }
        } else {
          setIsLoading(false);
          SetOpenArchiveFilterScreen(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind archived users details!");
        SetOpenArchiveFilterScreen(false);
      });
  };

  const ExportAllArchiveUsers = () => {
    setIsLoading(true);
    archivedUserFilterData.GetAll = true;
    archivedUserFilterData.CurrentPageNumber = 1;
    axios
      .post(
        `User/GetAllLocalArchivedUsersDetailsExport`,
        archivedUserFilterData,
        { responseType: 'blob' }
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            var fileName = "ArchivedUsers";
            var fileType = response.data.type;
            var file = new Blob([response.data], { type: fileType });
            var archiveUsersURL = URL.createObjectURL(file);
            const link = document.createElement("a");

            link.href = archiveUsersURL;
            link.download = fileName;
            link.click();

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when export archived users details!");
      });
  };

  const LoadAllUnarchiveUsers = (currentPage: number) => {
    setIsLoading(true);
    setAllUnarchivedUsersData([]);
    unarchivedUserFilterData.GetAll = false;
    unarchivedUserFilterData.CurrentPageNumber = currentPage;
    axios
      .post(
        `User/GetAllLocalUnarchivedUsersDetailsList`,
        unarchivedUserFilterData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setAllUnarchivedUsersData(response.data.data);
                UpdateUserAvtar();
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                SetOpenUnarchiveFilterScreen(false);
                setIsLoading(false);
              }
              else {
                setIsLoading(false);
                SetOpenUnarchiveFilterScreen(false);
              }
            }
          } else {
            setIsLoading(false);
            SetOpenUnarchiveFilterScreen(false);
          }
        } else {
          setIsLoading(false);
          SetOpenUnarchiveFilterScreen(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind archived users details!");
        SetOpenUnarchiveFilterScreen(false);
      });
  };

  const ExportAllUnarchiveUsers = () => {
    setIsLoading(true);
    unarchivedUserFilterData.GetAll = true;
    unarchivedUserFilterData.CurrentPageNumber = 1;
    axios
      .post(
        `User/GetAllLocalUnarchivedUsersDetailsExport`,
        unarchivedUserFilterData,
        { responseType: 'blob' }
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            var fileName = "UnArchivedUsers";
            var fileType = response.data.type;
            var file = new Blob([response.data], { type: fileType });
            var archiveUsersURL = URL.createObjectURL(file);
            const link = document.createElement("a");

            link.href = archiveUsersURL;
            link.download = fileName;
            link.click();

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        setIsLoading(false);
        console.log("Getting some API error when export archived users details!");
      });
  };

  const OnArchiveFilterApplyClick = (e: any) => {
    let archiveCountOfFilter = 0;

    if (archivedUserFilterData.Name != "" && archivedUserFilterData.Name != null && archivedUserFilterData.Name != undefined) {
      archiveCountOfFilter++;
    }

    if (archivedUserFilterData.JobTitle != "" && archivedUserFilterData.JobTitle != null && archivedUserFilterData.JobTitle != undefined) {
      archiveCountOfFilter++;
    }

    if (archivedUserFilterData.Office != "" && archivedUserFilterData.Office != null && archivedUserFilterData.Office != undefined) {
      archiveCountOfFilter++;
    }

    if (archivedUserFilterData.Department != "" && archivedUserFilterData.Department != null && archivedUserFilterData.Department != undefined) {
      archiveCountOfFilter++;
    }

    setarchiveUsersFilterCount(String(archiveCountOfFilter));
    LoadAllArchiveUsers(1);
  };

  const OnUnarchiveFilterApplyClick = (e: any) => {
    let unArchiveCountOfFilter = 0;

    if (unarchivedUserFilterData.Name != "" && unarchivedUserFilterData.Name != null && unarchivedUserFilterData.Name != undefined) {
      unArchiveCountOfFilter++;
    }

    if (unarchivedUserFilterData.JobTitle != "" && unarchivedUserFilterData.JobTitle != null && unarchivedUserFilterData.JobTitle != undefined) {
      unArchiveCountOfFilter++;
    }

    if (unarchivedUserFilterData.Office != "" && unarchivedUserFilterData.Office != null && unarchivedUserFilterData.Office != undefined) {
      unArchiveCountOfFilter++;
    }

    if (unarchivedUserFilterData.Department != "" && unarchivedUserFilterData.Department != null && unarchivedUserFilterData.Department != undefined) {
      unArchiveCountOfFilter++;
    }

    setunarchiveUsersFilterCount(String(unArchiveCountOfFilter));
    LoadAllUnarchiveUsers(1);
  };

  const OnUnarchiveClick = (e: any) => {
    SetUnarchiveData(archivedUserCheckedData);
    SetArchivedUsersScreen(false);
  };

  const OnArchiveClick = (e: any) => {
    SetArchivedUsersScreen(false);
    SetArchiveData(unArchivedUserCheckedData);
  };

  const SetUnarchiveData = (archivedUserCheckedData: IUsers[]) => {
    setIsLoading(true);
    axios
      .post(
        `User/SetUsersUnarchive`,
        archivedUserCheckedData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              setAllArchivedUsersData([]);
              setArchivedUserCheckedData([]);
              LoadAllArchiveUsers(1);
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        }
      });
  };

  const SetArchiveData = (unArchivedUserCheckedData: IUsers[]) => {
    setIsLoading(true);
    axios
      .post(
        `User/SetUsersArchive`,
        unArchivedUserCheckedData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              setAllUnarchivedUsersData([]);
              setUnArchivedUserCheckedData([]);
              LoadAllUnarchiveUsers(1);
            } else {
              setIsLoading(false);
            }
          }
        } else {
          setIsLoading(false);
        }
      });
  };

  const UpdateUserAvtar = () => {
    setAllUnarchivedUsersData((x) =>
      [...x].map((i) =>
        i.avatarURL320 == null
          ? { ...i, avatarURL320: userAvtar }
          : i
      )
    );
    setAllArchivedUsersData((x) =>
      [...x].map((i) =>
        i.avatarURL320 == null
          ? { ...i, avatarURL320: userAvtar }
          : i
      )
    );
  };

  const OnTabChange = (e: any) => {
    if (e.detail.name === "ArchivedUsers") {
      LoadAllArchiveUsers(1);
      SetOpenArchiveduserTabScreen(true);
      SetOpenUnarchiveduserTabScreen(false);
    } else if (e.detail.name === "UnarchivedUsers") {
      LoadAllUnarchiveUsers(1);
      SetOpenUnarchiveduserTabScreen(true);
      SetOpenArchiveduserTabScreen(false);
    }
  };

  //UnComment when allow to view manually added 
  // function onArchiveRowClick(newValue: any) {
  //   if (!newValue) {
  //     OnTabChange({ detail: { name: "ArchivedUsers" } });
  //   }
  //   SetArchivedUsersScreen(newValue);
  // }

  //UnComment when allow to view manually added 
  // function onUnArchiveRowClick(newValue: any) {
  //   if (!newValue) {
  //     OnTabChange({ detail: { name: "ArchivedUsers" } });
  //   }
  //   SetArchivedUsersScreen(newValue);
  // }

  function onArchiveRowClick(newValue: any) {
    if (!newValue) {
      OnTabChange({ detail: { name: "ArchivedUsers" } });
      SetArchivedUsersScreen(newValue);
    }
    else {
      SetArchivedUsersScreen(newValue);
    }
  }

  function onUnArchiveRowClick(newValue: any) {
    if (!newValue) {
      OnTabChange({ detail: { name: "ArchivedUsers" } });
      SetArchivedUsersScreen(newValue);
    }
    else {
      SetArchivedUsersScreen(newValue);
    }
  }

  return (
    <>
      <div>
        {!archivedUsersScreen ? (
          <>
            <div>
              <TagTabHeaders
                type="block"
                onTabHeaderChange={OnTabChange}
                selectedTab="ArchivedUsers"
              >
                <TagTabHeader name="ArchivedUsers">Archived Users</TagTabHeader>
                <TagTabHeader name="UnarchivedUsers">
                  Unarchived Users
                </TagTabHeader>
              </TagTabHeaders>
            </div>

            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div>
                {openArchiveduserTabScreen ? (
                  <>
                    {/*filter_Btn" */}
                    <div className="fr pt_5 pb_5 mt_15 mb_15">
                      <TagButton
                        icon='DocExcel'
                        iconAccent='keppel'
                        squared id="1"
                        onButtonClick={ExportAllArchiveUsers}
                        buttonStyle={{ "height": "36px", "width": "36px", "marginRight": "5px" }} />
                      <TagButton
                        text="Filter"
                        size="large"
                        icon="Switches"
                        onButtonClick={OnArchiveFilterClick}
                        minWidth="100px"
                        badge={archiveUsersFilterCount}
                        badgeAccent='access'
                      />
                    </div>
                    <div>
                      <TagTable
                        pagerShowFirstLast
                        page={page}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        onPageRequest={handleArchiveUserPageChangeRequest}
                        onRowsChecked={ArchivedRowchecked}
                        multiCheck
                        multiCheckAlign="left"
                        // rowKeyField="userId"
                        // multiCheckDefaultRowKeysChecked={
                        //   archivedUserCheckedData
                        // }
                        buttonIcon="View"
                        buttonRounded
                        buttonStyle={{ "height": "30px", "width": "30px" }}
                        onRowButtonClick={onArchiveRowClick}
                        columns={[
                          {
                            name: "avatarURL320",
                            caption: "User",
                            dataType: "image",
                            cellInnerStyle: {
                              height: "25px",
                              borderRadius: "12px",
                            },
                          },
                          { name: "fullName", caption: "Name" },
                          { name: "jobTitle", caption: "Job Title" },
                          { name: "office", caption: "Office" },
                          { name: "department", caption: "Department" },
                        ]}
                        data={allArchivedUsersData}
                      />
                    </div>
                    <div className="fr pt_5 mt_15 mb_15">
                      <TagButton
                        text="Unarchive"
                        size="large"
                        minWidth="100px"
                        onButtonClick={OnUnarchiveClick}
                      />
                    </div>
                    {/* Filter Model for Archive User*/}
                    <div>
                      <TagModal
                        visible={openArchiveFilterScreen}
                        heading="Archive Users Filter"
                        width="small"
                        borderAccent="viridiangreen"
                        onOpened={OnArchiveFilterOpened}
                        onClosed={() => {
                          SetOpenArchiveFilterScreen(false);
                        }}
                        primaryButton="Apply"
                        primaryButtonAccent="viridiangreen"
                        onPrimaryButtonClick={OnArchiveFilterApplyClick}
                        cancelButton="Clear"
                        cancelButtonIconAccent="title"
                        onCancelButtonClick={() => {
                          archivedUserFilterData.Name = "";
                          archivedUserFilterData.JobTitle = "";
                          archivedUserFilterData.Office = "";
                          archivedUserFilterData.Department = "";
                          setarchiveUsersFilterCount('0');
                          LoadAllArchiveUsers(1);
                        }}
                      >
                        <TagForm submitButtonHidden={true}>
                          <div className="lic-Content">
                            <table className="lic-Form-Table">
                              <tr>
                                <td className="w_22">
                                  <div>
                                    <tag-text>User Name</tag-text>
                                  </div>
                                </td>
                                <td className="w_27">
                                  <div>
                                    <TagEditField
                                      name="usr-Name"
                                      editor="textbox"
                                      data-type="string"
                                      value={archivedUserFilterData.Name}
                                      onValueChange={(e) => {
                                        archivedUserFilterData.Name =
                                          e.detail.value;
                                      }}
                                    ></TagEditField>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="w_22">
                                  <div>
                                    <tag-text>Job Title</tag-text>
                                  </div>
                                </td>
                                <td className="w_27">
                                  <div>
                                    <TagEditField
                                      name="usr-JobTitle"
                                      editor="textbox"
                                      data-type="string"
                                      value={archivedUserFilterData.JobTitle}
                                      onValueChange={(e) => {
                                        archivedUserFilterData.JobTitle =
                                          e.detail.value;
                                      }}
                                    ></TagEditField>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="w_22">
                                  <div>
                                    <tag-text>Office</tag-text>
                                  </div>
                                </td>
                                <td className="w_27">
                                  <div>
                                    <TagEditField
                                      name="usr-Office"
                                      editor="textbox"
                                      data-type="string"
                                      value={archivedUserFilterData.Office}
                                      onValueChange={(e) => {
                                        archivedUserFilterData.Office =
                                          e.detail.value;
                                      }}
                                    ></TagEditField>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="w_22">
                                  <div>
                                    <tag-text>Department</tag-text>
                                  </div>
                                </td>
                                <td className="w_27">
                                  <div>
                                    <TagEditField
                                      name="usr-Department"
                                      editor="textbox"
                                      data-type="string"
                                      value={archivedUserFilterData.Department}
                                      onValueChange={(e) => {
                                        archivedUserFilterData.Department =
                                          e.detail.value;
                                      }}
                                    ></TagEditField>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </TagForm>
                      </TagModal>
                    </div>
                  </>
                ) : null}
              </div>
            )}
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div>
                {openUnarchiveduserTabScreen ? (
                  <>
                    {/*filter_Btn" */}
                    <div className="fr pt_5 pb_5 mt_15 mb_15">
                      <TagButton
                        icon='DocExcel'
                        iconAccent='keppel'
                        squared id="1"
                        onButtonClick={ExportAllUnarchiveUsers}
                        buttonStyle={{ "height": "36px", "width": "36px", "marginRight": "5px" }} />
                      <TagButton
                        text="Filter"
                        size="large"
                        icon="Switches"
                        onButtonClick={OnUnarchiveFilterClick}
                        minWidth="100px"
                        badge={unarchiveUsersFilterCount}
                        badgeAccent='access'
                      />
                    </div>
                    <div>
                      <TagTable
                        pagerShowFirstLast
                        page={page}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        onPageRequest={handleUnArchiveUserPageChangeRequest}
                        onRowsChecked={UnArchivedRowchecked}
                        multiCheck
                        multiCheckAlign="left"
                        buttonIcon="View"
                        buttonRounded
                        buttonStyle={{ "height": "30px", "width": "30px" }}
                        onRowButtonClick={onUnArchiveRowClick}
                        columns={[
                          {
                            name: "avatarURL320",
                            caption: "User",
                            dataType: "image",
                            cellInnerStyle: {
                              height: "25px",
                              borderRadius: "12px",
                            },
                          },
                          { name: "fullName", caption: "Name" },
                          { name: "jobTitle", caption: "Job Title" },
                          { name: "office", caption: "Office" },
                          { name: "department", caption: "Department" },
                        ]}
                        data={allUnarchivedUsersData}
                      />
                    </div>
                    <div className="fr pt_5">
                      <TagButton
                        text="Archive"
                        size="large"
                        minWidth="100px"
                        onButtonClick={OnArchiveClick}
                      />
                    </div>
                    {/* Filter Model for Unarchive User */}
                    <div>
                      <TagModal
                        visible={openUnarchiveFilterScreen}
                        heading="Unarchive Users Filter"
                        width="small"
                        borderAccent="viridiangreen"
                        onOpened={OnUnarchiveFilterOpened}
                        onClosed={() => {
                          SetOpenUnarchiveFilterScreen(false);
                        }}
                        primaryButton="Apply"
                        primaryButtonAccent="viridiangreen"
                        onPrimaryButtonClick={OnUnarchiveFilterApplyClick}
                        cancelButton="Clear"
                        cancelButtonIconAccent="title"
                        onCancelButtonClick={() => {
                          unarchivedUserFilterData.Name = "";
                          unarchivedUserFilterData.JobTitle = "";
                          unarchivedUserFilterData.Office = "";
                          unarchivedUserFilterData.Department = "";
                          setunarchiveUsersFilterCount('0');
                          LoadAllUnarchiveUsers(1);
                        }}
                      >
                        <TagForm submitButtonHidden={true}>
                          <div className="lic-Content">
                            <table className="lic-Form-Table">
                              <tr>
                                <td className="w_22">
                                  <div>
                                    <tag-text>User Name</tag-text>
                                  </div>
                                </td>
                                <td className="w_27">
                                  <div>
                                    <TagEditField
                                      name="un-usr-Name"
                                      editor="textbox"
                                      data-type="string"
                                      value={unarchivedUserFilterData.Name}
                                      onValueChange={(e) => {
                                        unarchivedUserFilterData.Name =
                                          e.detail.value;
                                      }}
                                    ></TagEditField>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="w_22">
                                  <div>
                                    <tag-text>Job Title</tag-text>
                                  </div>
                                </td>
                                <td className="w_27">
                                  <div>
                                    <TagEditField
                                      name="un-usr-JobTitle"
                                      editor="textbox"
                                      data-type="string"
                                      value={unarchivedUserFilterData.JobTitle}
                                      onValueChange={(e) => {
                                        unarchivedUserFilterData.JobTitle =
                                          e.detail.value;
                                      }}
                                    ></TagEditField>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="w_22">
                                  <div>
                                    <tag-text>Office</tag-text>
                                  </div>
                                </td>
                                <td className="w_27">
                                  <div>
                                    <TagEditField
                                      name="un-usr-Office"
                                      editor="textbox"
                                      data-type="string"
                                      value={unarchivedUserFilterData.Office}
                                      onValueChange={(e) => {
                                        unarchivedUserFilterData.Office =
                                          e.detail.value;
                                      }}
                                    ></TagEditField>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td className="w_22">
                                  <div>
                                    <tag-text>Department</tag-text>
                                  </div>
                                </td>
                                <td className="w_27">
                                  <div>
                                    <TagEditField
                                      name="un-usr-Department"
                                      editor="textbox"
                                      data-type="string"
                                      value={
                                        unarchivedUserFilterData.Department
                                      }
                                      onValueChange={(e) => {
                                        unarchivedUserFilterData.Department =
                                          e.detail.value;
                                      }}
                                    ></TagEditField>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </TagForm>
                      </TagModal>
                    </div>
                  </>
                ) : null}
              </div>
            )}
          </>
        ) : null}
        {archivedUsersScreen ? (
          <>
            <div>
              {openArchiveduserTabScreen ? (
                <UserDetails
                  openDetailScreen={openDetailScreen}
                  SetopenUserScreen={SetopenArchivedUsersScreen}
                  SetopenopenDetailScreen={SetopenopenDetailScreen}
                  openuserScreen={archivedUsersScreen}
                  onParentRowClick={onArchiveRowClick}
                  disabledSave={true}
                  disabledStartAutoCheck={true}
                  disabledStopAutoCheck={true}
                />
              ) : null}
            </div>
            <div>
              {openUnarchiveduserTabScreen ? (
                <UserDetails
                  openDetailScreen={openDetailScreen}
                  SetopenUserScreen={SetopenArchivedUsersScreen}
                  SetopenopenDetailScreen={SetopenopenDetailScreen}
                  openuserScreen={archivedUsersScreen}
                  onParentRowClick={onUnArchiveRowClick}
                  disabledSave={false}
                  disabledStartAutoCheck={false}
                  disabledStopAutoCheck={false}
                />
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default ArchivedUsers;
