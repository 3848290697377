import { TagField } from "@tag/tag-components-react-v2";
import { TagTooltip } from "@tag/tag-components-react-v3";
import "../../src/assets/css/userDetail.scss";
import { useIframePidgetSettings } from "@workspace/utils-react/dist/iframePidget/useIframePidgetSettings";
import { PidgetSettings } from "../PidgetSettings";

function VehiclesDriveorNot({
  data,
  key
}: any) {
  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  const categoryName = data.doC_DVLA_L_C_Code + '.png'
  var src = url + 'Content/category/' + categoryName;
  var startDate;
  var startDateData;
  var endDate = new Date(data.doC_DVLA_L_C_To);
  var endDateData = endDate.getDate() + "/" + (endDate.getMonth() + 1) + "/" + endDate.getFullYear();

  if (data.doC_DVLA_L_C_From != null) {
    startDate = new Date(data.doC_DVLA_L_C_From);
    startDateData = startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear();
  } else {
    startDateData = " -"
  }

  return (
    <div>
      {data.doC_DVLA_L_C_Type == 'Full' ?
        <div className="vehicledrivecard">
          <div className="vehicles-header b_1_s_border" style={{ height: "40px" }}>
            <div className="fl pl_5 pt_5 pr_5 pb_5">
              <img src={src} alt={data.doC_DVLA_L_C_Code} height='28' style={{ opacity: 0.6 }} />
            </div>
            <div className="vehicle-header-details pr_5">
              <TagField
                value={data.doC_DVLA_L_C_Code}
                className={data.doC_DVLA_L_C_Code + '_' + data.doC_DVLA_L_C_PRIMARY}
                containerStyle={{ padding: "10px 0" }}
                labelStyle={{
                  fontSize: "12px",
                  width: "58px",
                }}
                valueStyle={{
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              />
              <TagTooltip tooltipFor={'.' + data.doC_DVLA_L_C_Code + '_' + data.doC_DVLA_L_C_PRIMARY} >
                <div style={{ width: "150px", display: "flex" }}>
                  <div>
                    <TagField
                      label="Type: "
                      labelStyle={{
                        width: "70px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                      value={data.doC_DVLA_L_C_Type}
                    />
                    <TagField
                      label="Start Date: "
                      labelStyle={{
                        width: "70px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                      value={startDateData}
                    />
                    <TagField
                      label="End Date: "
                      labelStyle={{
                        width: "70px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                      value={endDateData}
                    />
                  </div>
                </div>
              </TagTooltip>
            </div>
          </div>
        </div>
        :
        <div className="vehicledrivecard">
          <div className="vehicles-categoryheader b_1_s_border" style={{ height: "40px" }}>
            <div className="fl pl_5 pt_5 pr_5 pb_5">
              <img src={src} alt={data.doC_DVLA_L_C_Code} height='28' style={{ opacity: 0.6 }} />
            </div>
            <div className="vehicle-header-details pr_5">
              <TagField
                value={data.doC_DVLA_L_C_Code}
                className={data.doC_DVLA_L_C_Code + '_' + data.doC_DVLA_L_C_PRIMARY}
                containerStyle={{ padding: "10px 0" }}
                labelStyle={{
                  fontSize: "12px",
                  width: "58px",
                }}
                valueStyle={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "white",
                }}
              />
              <TagTooltip tooltipFor={'.' + data.doC_DVLA_L_C_Code + '_' + data.doC_DVLA_L_C_PRIMARY} >
                <div style={{ width: "150px", display: "flex" }}>
                  <div>
                    <TagField
                      label="Type: "
                      labelStyle={{
                        width: "70px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                      value={data.doC_DVLA_L_C_Type}
                    />
                    <TagField
                      label="Start Date: "
                      labelStyle={{
                        width: "70px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                      value={startDateData}
                    />
                    <TagField
                      label="End Date: "
                      labelStyle={{
                        width: "70px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                      value={endDateData}
                    />
                  </div>
                </div>
              </TagTooltip>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default VehiclesDriveorNot;
