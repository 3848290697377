/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-undef */
import { TagEditField } from "@tag/tag-components-react-v2";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/css/config.scss";
import "../assets/css/common.scss";
import React from "react";
import { IFrquency, IFrquencyData } from "../core/view-Model/frequency.model";

var globalFrquencyData: IFrquencyData[] = [
  { Config_Name: "Driving Licence", Level: "High", Freq_From: 0, Freq_To: 5 },
  { Config_Name: "Driving Licence", Level: "Medium", Freq_From: 6, Freq_To: 10 },
  { Config_Name: "Driving Licence", Level: "Low", Freq_From: 11, Freq_To: 15 },
  { Config_Name: "TAX", Level: "High", Freq_From: 16, Freq_To: 20 },
  { Config_Name: "TAX", Level: "Medium", Freq_From: 21, Freq_To: 25 },
  { Config_Name: "TAX", Level: "Low", Freq_From: 26, Freq_To: 30 },
  { Config_Name: "MOT", Level: "High", Freq_From: 31, Freq_To: 35 },
  { Config_Name: "MOT", Level: "Medium", Freq_From: 36, Freq_To: 40 },
  { Config_Name: "MOT", Level: "Low", Freq_From: 41, Freq_To: 45 },
  { Config_Name: "Photocard", Level: "High", Freq_From: 46, Freq_To: 50 },
  { Config_Name: "Photocard", Level: "Medium", Freq_From: 51, Freq_To: 55 },
  { Config_Name: "Photocard", Level: "Low", Freq_From: 56, Freq_To: 60 },
  { Config_Name: "Insurance", Level: "High", Freq_From: 61, Freq_To: 65 },
  { Config_Name: "Insurance", Level: "Medium", Freq_From: 66, Freq_To: 70 },
  { Config_Name: "Insurance", Level: "Low", Freq_From: 71, Freq_To: 75 },
];

const Frquency: React.FC<{ obj: IFrquency }> = (props) => {
  const [frquencyData, setFrquencyData] = useState<IFrquencyData[]>([
    { Config_Name: "Driving Licence", Level: "High", Freq_From: 0, Freq_To: 5 },
    { Config_Name: "Driving Licence", Level: "Medium", Freq_From: 6, Freq_To: 10 },
    { Config_Name: "Driving Licence", Level: "Low", Freq_From: 11, Freq_To: 15 },
    { Config_Name: "TAX", Level: "High", Freq_From: 16, Freq_To: 20 },
    { Config_Name: "TAX", Level: "Medium", Freq_From: 21, Freq_To: 25 },
    { Config_Name: "TAX", Level: "Low", Freq_From: 26, Freq_To: 30 },
    { Config_Name: "MOT", Level: "High", Freq_From: 31, Freq_To: 35 },
    { Config_Name: "MOT", Level: "Medium", Freq_From: 36, Freq_To: 40 },
    { Config_Name: "MOT", Level: "Low", Freq_From: 41, Freq_To: 45 },
    { Config_Name: "Photocard", Level: "High", Freq_From: 46, Freq_To: 50 },
    { Config_Name: "Photocard", Level: "Medium", Freq_From: 51, Freq_To: 55 },
    { Config_Name: "Photocard", Level: "Low", Freq_From: 56, Freq_To: 60 },
    { Config_Name: "Insurance", Level: "High", Freq_From: 61, Freq_To: 65 },
    { Config_Name: "Insurance", Level: "Medium", Freq_From: 66, Freq_To: 70 },
    { Config_Name: "Insurance", Level: "Low", Freq_From: 71, Freq_To: 75 },
  ]);

  React.useEffect(() => {
    updateFrquencyData();
  }, [props]);

  const valueChange = (e: any) => {
    var name = e.srcElement.attributes.id.nodeValue.split(";")[0];
    var level = e.srcElement.attributes.id.nodeValue.split(";")[1];
    var type = e.srcElement.attributes.id.nodeValue.split(";")[2];

    updateFrquency(name, parseInt(e.detail.value), level, type);
  };

  const updateFrquency = (
    name: string,
    val: number,
    level: string,
    type: string
  ) => {
    const newFrquencyData = [...frquencyData];
    let frquencyIndex = newFrquencyData.findIndex(
      (x) => x.Config_Name === name && x.Level === level
    );
    if (type === "Min") {
      newFrquencyData[frquencyIndex]["Freq_From"] = val;
    } else {
      newFrquencyData[frquencyIndex]["Freq_To"] = val;
      if (frquencyIndex + 1 < newFrquencyData.length && level !== "Low") {
        newFrquencyData[frquencyIndex + 1]["Freq_From"] = val + 1;
      }
    }
    setFrquencyData(newFrquencyData);
  };

  const updateFrquencyData = () => {
    for (let i = props.obj.index; i < props.obj.index + 3; i++) {
      if (globalFrquencyData[i]["Config_Name"] === props.obj.frequencyName) {
        if (globalFrquencyData[i]["Level"] === "High") {
          globalFrquencyData[i]["Freq_From"] = props.obj.highMinVal;
          globalFrquencyData[i]["Freq_To"] = props.obj.highMaxVal;
        } else if (globalFrquencyData[i]["Level"] === "Medium") {
          globalFrquencyData[i]["Freq_From"] = props.obj.mediumMinVal;
          globalFrquencyData[i]["Freq_To"] = props.obj.mediumMaxVal;
        } else {
          globalFrquencyData[i]["Freq_From"] = props.obj.lowMinVal;
          globalFrquencyData[i]["Freq_To"] = props.obj.lowMaxVal;
        }
      }
    }
    setFrquencyData(globalFrquencyData);
  };

  return (
    <div>
      <Container className="border rounded frequencyContainer">
        <Row>
          <Col xs={12} md={12} lg={12} className="frequencycard rounded-top">
            <span>{props.obj.frequencyTitle}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={3} md={3} lg={3} className="pl_25 p_15 pt_30 high leftCenterAlign">
            High
          </Col>
          <Col xs={3} md={3} lg={3} className="p_15 pt_30 text-center centerAlign">
            <TagEditField
              editor="number"
              dataType="integer"
              value={frquencyData[props.obj.index]["Freq_From"]}
              id={`${props.obj.frequencyName};High;Min`}
              name="High"
              disabled={props.obj.isDisabled}
              editorStyle={{ width: "100px" }}
              onValueChange={valueChange}
            />
          </Col>
          <Col xs={1} md={1} lg={1} className="p_15 pt_30 text-center centerAlign">
            {" "}
            to{" "}
          </Col>
          <Col xs={3} md={3} lg={3} className="p_15 pt_30 text-center centerAlign">
            <TagEditField
              editor="number"
              dataType="integer"
              value={frquencyData[props.obj.index]["Freq_To"]}
              id={`${props.obj.frequencyName};High;Max`}
              name="High"
              disabled={props.obj.isDisabled}
              editorStyle={{ width: "100px" }}
              onValueChange={valueChange}
            />
          </Col>
          <Col
            xs={2}
            md={2}
            lg={2}
            className="p_15 pt_30 text-center leftCenterAlign"
          >
            {" "}
            Days
          </Col>
        </Row>
        <Row>
          <Col
            xs={3}
            md={3}
            lg={3}
            className="pl_25 p_15 medium leftCenterAlign"
          >
            Medium
          </Col>
          <Col xs={3} md={3} lg={3} className="p_15 text-center centerAlign">
            <TagEditField
              editor="number"
              dataType="integer"
              value={frquencyData[props.obj.index + 1]["Freq_From"]}
              id={`${props.obj.frequencyName};Medium;Min`}
              name="Medium"
              disabled
              editorStyle={{ width: "100px" }}
            />
          </Col>
          <Col xs={1} md={1} lg={1} className="p_15 text-center centerAlign">
            {" "}
            to{" "}
          </Col>
          <Col xs={3} md={3} lg={3} className="p_15 text-center centerAlign">
            <TagEditField
              editor="number"
              dataType="integer"
              value={frquencyData[props.obj.index + 1]["Freq_To"]}
              id={`${props.obj.frequencyName};Medium;Max`}
              name="Medium"
              disabled={props.obj.isDisabled}
              editorStyle={{ width: "100px" }}
              onValueChange={valueChange}
            />
          </Col>
          <Col
            xs={2}
            md={2}
            lg={2}
            className="p_15 text-center leftCenterAlign"
          >
            {" "}
            Days
          </Col>
        </Row>
        <Row>
          <Col xs={3} md={3} lg={3} className="pl_25 p_15 pb_30 low leftCenterAlign">
            Low
          </Col>
          <Col xs={3} md={3} lg={3} className="p_15 pb_30 text-center centerAlign">
            <TagEditField
              editor="number"
              dataType="integer"
              value={frquencyData[props.obj.index + 2]["Freq_From"]}
              id={`${props.obj.frequencyName};Low;Min`}
              name="Low"
              disabled
              editorStyle={{ width: "100px" }}
            />
          </Col>
          <Col xs={1} md={1} lg={1} className="p_15 pb_30 text-center centerAlign">
            {" "}
            to{" "}
          </Col>
          <Col xs={3} md={3} lg={3} className="p_15 pb_30 text-center centerAlign">
            <TagEditField
              editor="number"
              dataType="integer"
              value={frquencyData[props.obj.index + 2]["Freq_To"]}
              id={`${props.obj.frequencyName};Low;Max`}
              name="Low"
              disabled={props.obj.isDisabled}
              editorStyle={{ width: "100px" }}
              onValueChange={valueChange}
            />
          </Col>
          <Col
            xs={2}
            md={2}
            lg={2}
            className="p_15 pb_30 text-center leftCenterAlign"
          >
            {" "}
            Days
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export { Frquency, globalFrquencyData };
