import {
  TagButton,
  TagForm,
  TagModal,
} from "@tag/tag-components-react-v2";

import "../../../assets/css/userDetail.scss";
import { useState } from "react";

function LicenceDetails({
  openLicenceScreen,
  licencevehicleData,
  SetopenLicenceScreen,
  licencedob,
  issuedate,
  expirydate,
  datecometoUK,
  ismanuallyaddlicence,
  fileName,
}: any) {
  const OnOpened = (e: any) => { };
  const [imageSrc, setImageSrc] = useState('');
  const [showLicenceImageModel, setshowLicenceImageModel] = useState(false);
  const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];

  const onCancelButtonClick = (e: any) => {
    SetopenLicenceScreen(false);
  };

  const downloadFile = (e: any) => {
    var fileName = "";
    var fileType = "";
    var fileData = "";

    fileName = licencevehicleData.licencedocument.doC_D_FILE_NAME;
    fileType = licencevehicleData.licencedocument.doC_D_FILE_CONTENT_TYPE;
    fileData = licencevehicleData.licencedocument.doC_D_FILE_DATA;

    var fileBinaryString = atob(fileData);
    var dowloadFileBytes = new Uint8Array(fileBinaryString.length);
    for (var i = 0; i < fileBinaryString.length; i++) {
      var ascii = fileBinaryString.charCodeAt(i);
      dowloadFileBytes[i] = ascii;
    }

    var file = new Blob([dowloadFileBytes], {
      type: fileType,
    });

    if (allowedFileTypes.includes(fileType)) {
      var motFileObjectURL = URL.createObjectURL(file);
      setImageSrc(motFileObjectURL);
      setshowLicenceImageModel(true);
    } else {
      var motFileObjectURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = motFileObjectURL;
      link.download = fileName;
      link.click();
    }
  }

  return (
    <div>
      {ismanuallyaddlicence ? (
        <>
          <div>
            <TagModal
              visible={openLicenceScreen}
              heading="Licence Details"
              width='small'
              borderAccent="viridiangreen"
              onOpened={OnOpened}
              onClosed={() => {
                SetopenLicenceScreen(false);
              }}
            >
              <TagForm submitButtonHidden={true}>
                <div className="lic-Content">
                  <table className="lic-Form-Table">
                    <tr>
                      <td className="w_22">
                        <div>
                          <tag-text>Country</tag-text>
                        </div>
                      </td>
                      <td className="w_27">
                        <div>
                          <tag-edit-field
                            name="lic-no"
                            editor="Textbox"
                            data-type="string"
                            value={licencevehicleData.countrY_NAME}
                            disabled
                          ></tag-edit-field>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="w_22">
                        <div>
                          <tag-text>Date when you come to UK</tag-text>
                        </div>
                      </td>
                      <td className="w_27">
                        <div>
                          <tag-edit-field
                            name="lic-IssueDate"
                            editor="Textbox"
                            data-type="date"
                            value={datecometoUK}
                            disabled
                          ></tag-edit-field>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="w_22">
                        <div>
                          <tag-text>Licence No.</tag-text>
                        </div>
                      </td>
                      <td className="w_27">
                        <div>
                          <tag-edit-field
                            name="lic-no"
                            editor="Textbox"
                            data-type="string"
                            value={licencevehicleData.licenceno}
                            disabled
                          ></tag-edit-field>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="w_22">
                        <div>
                          <tag-text>Issue Date</tag-text>
                        </div>
                      </td>
                      <td className="w_27">
                        <div>
                          <tag-edit-field
                            name="lic-IssueDate"
                            editor="Textbox"
                            data-type="date"
                            value={issuedate}
                            disabled
                          ></tag-edit-field>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="w_22">
                        <div>
                          <tag-text>Expiry Date</tag-text>
                        </div>
                      </td>
                      <td className="w_27">
                        <div>
                          <tag-edit-field
                            name="lic-ExpiryDate"
                            editor="Textbox"
                            data-type="date"
                            value={expirydate}
                            disabled
                          ></tag-edit-field>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="w_22">
                        <div>
                          <tag-text>Driving Licence Copy</tag-text>
                        </div>
                      </td>
                      <td className="w_15">
                        <div>
                          <a onClick={downloadFile} id="insuranceDocument">
                            {fileName}
                          </a>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </TagForm>

              <div className="footer">
                <div>
                  <TagButton
                    size="large"
                    text="Cancel"
                    className="cancel"
                    onButtonClick={onCancelButtonClick}
                  />
                </div>
              </div>
            </TagModal>
          </div>
        </>
      ) : <>
        <div>
          <TagModal
            visible={openLicenceScreen}
            heading="Licence Details"
            width='medium'
            borderAccent="viridiangreen"
            onOpened={OnOpened}
            onClosed={() => {
              SetopenLicenceScreen(false);
            }}
          >
            <TagForm submitButtonHidden={true}>
              <div className="lic-Content">
                <table className="lic-Form-Table">
                  <tr>
                    <td className="w_22">
                      <div>
                        <tag-text>Licence No</tag-text>
                      </div>
                    </td>
                    <td className="w_27">
                      <div>
                        <tag-edit-field
                          name="lic-no"
                          editor="Textbox"
                          data-type="string"
                          value={licencevehicleData.licenceno}
                          disabled
                        ></tag-edit-field>
                      </div>
                    </td>
                    <td className="w_6"></td>
                    <td className="w_22">
                      {/* <div>
                    <tag-text>Authority</tag-text>
                  </div> */}
                      <div>
                        <tag-text>Date Of Birth</tag-text>
                      </div>
                    </td>
                    <td className="w_27">
                      {/* <div>
                    <tag-edit-field
                      name="lic-Authority"
                      editor="Textbox"
                      data-type="string"
                      value={licencevehicleData.authority}
                      disabled
                    ></tag-edit-field>
                  </div> */}
                      <div>
                        <tag-edit-field
                          name="lic-DOB"
                          editor="Textbox"
                          data-type="string"
                          value={licencedob}
                          disabled
                        ></tag-edit-field>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="w_22">
                      <div>
                        <tag-text>Name</tag-text>
                      </div>
                    </td>
                    <td className="w_27">
                      <div>
                        <tag-edit-field
                          name="lic-Name"
                          editor="Textbox"
                          data-type="string"
                          value={licencevehicleData.name}
                          disabled
                        ></tag-edit-field>
                      </div>
                    </td>
                    <td className="w_6"></td>
                    <td className="w_22">
                      <div>
                        <tag-text> Surname </tag-text>
                      </div>
                    </td>
                    <td className="w_27">
                      <div>
                        <tag-edit-field
                          name="lic-Surname"
                          editor="Textbox"
                          data-type="string"
                          value={licencevehicleData.surname}
                          disabled
                        ></tag-edit-field>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td className="w_22">
                      <div>
                        <tag-text>Issue Date</tag-text>
                      </div>
                    </td>
                    <td className="w_27">
                      <div>
                        <tag-edit-field
                          name="lic-IssueDate"
                          editor="Textbox"
                          data-type="string"
                          value={issuedate}
                          disabled
                        ></tag-edit-field>
                      </div>
                    </td>
                    <td className="w_6"></td>
                    <td className="w_22">
                      <div>
                        <tag-text>Expiry Date</tag-text>
                      </div>
                    </td>
                    <td className="w_27">
                      <div>
                        <tag-edit-field
                          name="lic-ExpiryDate"
                          editor="Textbox"
                          data-type="string"
                          value={expirydate}
                          disabled
                        ></tag-edit-field>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w_22">
                      <div>
                        <tag-text>Categories</tag-text>
                      </div>
                    </td>
                    <td className="w_80" colSpan={4}>
                      <div className="pt_4">
                        <tag-edit-field
                          name="lic-Categories"
                          className="lic-Categories"
                          editor="Textbox"
                          data-type="string"
                          value={licencevehicleData.categories}
                          disabled
                        ></tag-edit-field>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w_22">
                      <div>
                        <tag-text>Address</tag-text>
                      </div>
                    </td>
                    <td className="w_80" colSpan={4}>
                      <div className="pt_4">
                        <tag-edit-field
                          name="lic-Address"
                          editor="MultilineTextbox"
                          rows="2"
                          data-type="string"
                          max-length="300"
                          value={licencevehicleData.address}
                          container-style={'{"height": "60px"}'}
                          disabled
                        ></tag-edit-field>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </TagForm>

            <div className="footer">
              <div>
                <TagButton
                  size="large"
                  text="Cancel"
                  className="cancel"
                  onButtonClick={onCancelButtonClick}
                />
              </div>
            </div>
          </TagModal>
        </div>
      </>}

      {showLicenceImageModel ? (
        <>
          <div>
            <TagModal
              visible
              heading="Licence Document"
              borderAccent='viridiangreen'
              width='small'
              containerStyle={{ backgroundColor: "rgb(249,249,249)" }}
              contentStyle={{ backgroundColor: "white", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px" }}
              headingContainerStyle={{ backgroundColor: "rgb(249,249,249)" }}
              onClosed={() => {
                setshowLicenceImageModel(false);
              }}>
              <div style={{ textAlign: "center", maxHeight: "500px", overflowY: "scroll" }}>
                <img id="licenceDocumentFileImage" src={imageSrc} />
              </div>
            </TagModal>
          </div>
        </>
      ) : null
      }
    </div>
  );
}
export default LicenceDetails;
